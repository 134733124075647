<template>
  <v-menu
    v-model="legendMenu"
    :close-on-content-click="false"
    offset-y
    :nudge-top="7"
    top
    :nudge-left="60"
    style="z-index: 999;"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!legends.length"
        :outlined="$attrs['outlined']"
        :style="{ border: $attrs['outlined'] ? '1px solid #5B606A' : '' }"
        :width="width ? width : ''"
        :x-small="size === 'x-small'"
        :small="size === 'small'"
        :large="size === 'large'"
        class="elevation-0"
        color="transparent"
        height="40"
        style="justify-content: start; font-size: 11px;"
        v-bind="attrs"
        v-on="on"
      >
        {{ type.name ? type.name : 'Select Index' }}
        <div >
          <v-icon v-if="legendMenu">mdi-menu-up</v-icon>
          <v-icon v-else>mdi-menu-down</v-icon>
        </div>
      </v-btn>
    </template>
    <v-layout column style="max-height: 385px; overflow-y: auto;">
      <v-list dense width="100%" style="background: #1e1e48;">
        <v-tooltip
          v-for="(item, index) in legends"
          :key="index"
          :left="$attrs['left']"
          :right="$attrs['right']"
          color="#000000e8"
          max-width="350"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item class="pl-3" v-bind="attrs" @click="changeIndex(item)" v-on="on">
              <table>
                <tr>
                  <td style="font-size:11px ;width:55px">{{ item.name }}</td>
                  <td style="font-size:11px">{{ item.full_name }}</td>
                </tr>
              </table>
            </v-list-item>
          </template>
          <div style="text-align: justify;">{{ item.description ? item.description : item.name }}</div>
        </v-tooltip>
      </v-list>
    </v-layout>
  </v-menu>
</template>

<script>
import legend from '@/store/modules/legend';

export default {
  name: 'SelectIndex',
  data() {
    return {
      legendMenu: false,
    }
  },
  mounted() {
    this.legends = [...this.mapLegend]
  },
  watch: {
    mapLegend(val) {
      this.legends = [...this.mapLegend]
    },
  },
  props: {
    size: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    type: {
      type: Object,
      default: {},
    },
    customLegend: {
      type: Array,
      default: () => [],
    },
    mapLegend: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    legends: {
      get() {
        return this.customLegend
      },
      set(val) {
        this.$emit('update:customLegend', val)
      },
    },
  },
  methods: {
    changeIndex(index) {
      this.$emit('changeIndex', index)
      this.legendMenu = false
    },
  },
}
</script>

<style scoped>
::v-deep .v-menu__content {
  top: 679px !important;
  left: 586px !important;
}
</style>
