import api from '@/api/api'
import config from '@/config'

export async function getIndices(data) {
  return await api.get(`${config.backend}/indices`, data.payload)
}

export async function getCluster(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/index/map/aoi-cluster`, data.payload, {
    timeout: 1000 * 10 * 60,
  })
}
export async function getAoisTile(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/index/map/aois-tile`, data.payload, {
    timeout: 10 * 1000 * 60,
  })
}
export async function getGeometryOfBound(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/index/map/aois`, data.payload, {
    timeout: 1000 * 10 * 60,
  })
}

export async function getImages(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/index/images`, data.payload, {
    timeout: 10 * 1000 * 60,
  })
}

export async function getDataOfBound(data) {
  return await api.get(`${config.upload_url}/public/search-image`, data.payload, {
    timeout: 10 * 60 * 1000,
  })
}
export async function updateCustomizeIndices(data) {
  return await api.post(`${config.backend}/indices/customize`, data)
}