<template>
  <v-dialog v-if="dialog" v-model="dialog" fullscreen persistent>
    <v-card :loading="loading" class="custom-card-bg-gradient">
      <DialogHeader :is-close="true" :title="'Weather'" @close="dialog = false" />
      <v-card-text style="height: calc(99vh - 70px); overflow-y: auto">
        <v-layout class="pa-3" fill-height>
          <v-row class="ma-0 h-full">
            <v-col cols="12" md="12" order="2" order-lg="1" xl="6">
              <div style="flex: none; width: 100%">
                <v-layout align-center class="mb-4">
                  <v-icon class="mr-2">mdi-thermometer-alert</v-icon>
                  <h4 class="text-uppercase">Temperature</h4>
                </v-layout>
              </div>
              <div style="flex: none; width: 100%; height: 300px">
                <Chart ref="tempChart" />
              </div>
            </v-col>
            <v-col cols="12" md="12" order="2" order-lg="1" xl="6">
              <div style="flex: none; width: 100%">
                <v-layout align-center class="mb-4">
                  <v-icon class="mr-2">mdi-weather-windy</v-icon>
                  <h4 class="text-uppercase">Windy</h4>
                </v-layout>
              </div>
              <div style="flex: none; width: 100%; height: 300px">
                <Chart ref="windyChart" />
              </div>
            </v-col>
            <v-col cols="12" md="12" order="2" order-lg="1" xl="12">
              <div style="flex: none; width: 100%">
                <v-layout align-center class="mb-4">
                  <v-icon class="mr-2">mdi-weather-pouring</v-icon>
                  <h4 class="text-uppercase">Precipitation</h4>
                </v-layout>
              </div>
              <div style="flex: none; width: 100%; height: 300px">
                <Chart ref="precipitationChart" />
              </div>
            </v-col>
            <v-col cols="12" md="12" order="2" order-lg="1" xl="6">
              <div style="flex: none; width: 100%">
                <v-layout align-center class="mb-4">
                  <v-icon class="mr-2">mdi-earth-box</v-icon>
                  <h4 class="text-uppercase">Soil temperature</h4>
                  <v-spacer />
                  <div style="max-width: 300px">
                    <v-select
                      v-model="depth"
                      :items="depths"
                      :loading="loading"
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      label="Select Depth"
                      outlined
                      placeholder="Depth"
                      @change="changeDisplaySoil"
                    />
                  </div>
                </v-layout>
              </div>
              <div style="flex: none; width: 100%; height: 300px">
                <Chart ref="soilTemperatureChart" />
              </div>
            </v-col>
            <v-col cols="12" md="12" order="2" order-lg="1" xl="6">
              <div style="flex: none; width: 100%">
                <v-layout align-center class="mb-4">
                  <v-icon class="mr-2">mdi-earth-box</v-icon>
                  <h4 class="text-uppercase">Soil moisture</h4>
                  <v-spacer />
                  <div style="max-width: 300px">
                    <v-select
                      v-model="depth"
                      :items="depths"
                      :loading="loading"
                      dense
                      hide-details
                      item-text="name"
                      item-value="code"
                      label="Select Depth"
                      outlined
                      placeholder="Depth"
                      @change="changeDisplaySoil"
                    />
                  </div>
                </v-layout>
              </div>
              <div style="flex: none; width: 100%; height: 300px">
                <Chart ref="soilMoistureChart" />
              </div>
            </v-col>
          </v-row>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import Chart from '@/components/Chart.vue'
import sleep from '@/utils/sleep'

export default {
  components: { DialogHeader, Chart },
  data() {
    return {
      dialog: false,
      loading: false,
      data: undefined,
      depth: '01',
      depths: [
        {
          name: '0-0.1m',
          code: '01',
        },
        {
          name: '0.1-0.4m',
          code: '04',
        },
        {
          name: '0.4-1.0m',
          code: '10',
        },
        {
          name: '1.0-2.0m',
          code: '20',
        },
      ],
      labels: [],
    }
  },
  methods: {
    openDialog(data) {
      this.labels = []
      this.data = data
      this.dialog = true
      this.displayChart()
    },
    async displayChart() {
      await sleep(100)
      this.labels = this.data.map(val => val.date)
      this.displayTempChart()
      this.displayWindyChart()
      this.displayPrecipitationChart()
      this.displaySoilTemperatureChart()
      this.displaySoilMoistureChart()
    },
    changeDisplaySoil() {
      this.displaySoilTemperatureChart()
      this.displaySoilMoistureChart()
    },
    displaySoilMoistureChart() {
      let data = [
        {
          type: 'bar',
          color: '#116DF2',
          label: 'Soil moisture',
          data: this.data.map(val => val.weather[this.depth].soilmoisture),
          legend: '- Soil moisture (mm) -',
          position: 'left',
          yAxisID: 'y',
        },
        {
          type: 'line',
          color: '#42f211',
          label: 'Index Average',
          data: this.data.map(val => val.average),
          tension: 0.6,
          radius: 2.5,
          legend: '- Index Average -',
          position: 'right',
          yAxisID: 'y1',
          grid: false,
        },
      ]
      if (this.$refs.soilMoistureChart.created) this.$refs.soilMoistureChart.updateChart(this.labels, data)
      else this.$refs.soilMoistureChart.createChart(this.labels, data)
    },
    displaySoilTemperatureChart() {
      let data = [
        {
          type: 'bar',
          color: '#116DF2',
          label: 'et0',
          data: this.data.map(val => val.weather.et0),
          legend: '- et0 (mm) -',
          position: 'right',
          yAxisID: 'y2',
        },
        {
          type: 'line',
          color: '#ff3636',
          label: 'Soil temp',
          data: this.data.map(val => val.weather[this.depth].soiltemp),
          tension: 0.6,
          radius: 2.5,
          legend: '- Soil temp (°C) -',
          position: 'left',
          yAxisID: 'y',
          grid: false,
        },
        {
          type: 'line',
          color: '#42f211',
          label: 'Index Average',
          data: this.data.map(val => val.average),
          tension: 0.6,
          radius: 2.5,
          legend: '- Index Average -',
          position: 'right',
          yAxisID: 'y1',
          grid: false,
        },
      ]
      if (this.$refs.soilTemperatureChart.created) this.$refs.soilTemperatureChart.updateChart(this.labels, data)
      else this.$refs.soilTemperatureChart.createChart(this.labels, data)
    },
    displayPrecipitationChart() {
      let data = [
        {
          type: 'bar',
          color: '#116DF2',
          label: 'Precipitation',
          data: this.data.map(val => val.weather.precip),
          legend: '- Precipitation (mm) -',
          position: 'right',
          yAxisID: 'y2',
        },
        {
          type: 'line',
          color: '#1affdd',
          label: 'Humidity',
          data: this.data.map(val => val.weather.humidity),
          tension: 0.6,
          radius: 2.5,
          legend: '- Relative Humidity (%) -',
          position: 'left',
          yAxisID: 'y',
        },
        {
          type: 'line',
          color: '#42f211',
          label: 'Index Average',
          data: this.data.map(val => val.average),
          tension: 0.6,
          radius: 2.5,
          legend: '- Index Average -',
          position: 'right',
          yAxisID: 'y1',
          grid: false,
        },
      ]
      if (this.$refs.precipitationChart.created) this.$refs.precipitationChart.updateChart(this.labels, data)
      else this.$refs.precipitationChart.createChart(this.labels, data)
    },
    displayTempChart() {
      let data = [
        {
          type: 'line',
          color: '#efff1a',
          label: 'Temp min',
          data: this.data.map(val => val.weather.tempmin),
          tension: 0.6,
          radius: 2.5,
          legend: '- Temp ( °C ) -',
          position: 'left',
          yAxisID: 'y',
        },
        {
          type: 'line',
          color: '#D32F2F',
          label: 'Temp max',
          data: this.data.map(val => val.weather.tempmax),
          tension: 0.6,
          radius: 2.5,
          legend: '- Temp ( °C ) -',
          position: 'left',
          yAxisID: 'y',
        },
        {
          type: 'line',
          color: '#42f211',
          label: 'Index Average',
          data: this.data.map(val => val.average),
          tension: 0.6,
          radius: 2.5,
          legend: '- Index Average -',
          position: 'right',
          yAxisID: 'y1',
          grid: false,
        },
      ]
      if (this.$refs.tempChart.created) this.$refs.tempChart.updateChart(this.labels, data)
      else this.$refs.tempChart.createChart(this.labels, data)
    },
    displayWindyChart() {
      let data = [
        {
          type: 'line',
          color: 'rgb(68 234 255)',
          label: 'Wind gust',
          data: this.data.map(val => val.weather.windgust),
          tension: 0.6,
          radius: 2.5,
          legend: '- Windy (km/h) -',
          position: 'left',
          yAxisID: 'y',
        },
        {
          type: 'line',
          color: '#E040FB',
          label: 'Wind speed',
          data: this.data.map(val => val.weather.windspeed),
          tension: 0.6,
          radius: 2.5,
          legend: '- Windy (km/h) -',
          position: 'left',
          yAxisID: 'y',
        },
        {
          type: 'line',
          color: '#116DF2',
          label: 'Pressure',
          data: this.data.map(val => val.weather.pressure),
          tension: 0.6,
          radius: 2.5,
          legend: 'Sea Level Pressure (Milibars)',
          position: 'right',
          yAxisID: 'y1',
          grid: false,
        },
        {
          type: 'line',
          color: '#42f211',
          label: 'Index Average',
          data: this.data.map(val => val.average),
          tension: 0.6,
          radius: 2.5,
          legend: '- Index Average -',
          position: 'right',
          yAxisID: 'y2',
          grid: false,
        },
      ]
      if (this.$refs.windyChart.created) this.$refs.windyChart.updateChart(this.labels, data)
      else this.$refs.windyChart.createChart(this.labels, data)
    },
  },
}
</script>

<style scoped></style>
