<template>
  <v-layout class="overflow-y-hidden" style="height: 100%; background: transparent; border-radius: 0">
    <AOIManagement
      :canCollapse="false"
      :canOrder="false"
      :AOIMenu.sync="AOIMenu"
      :isIndexMonitoring="true"
      :isCreating.sync="isCreating"
      @startDraw="startDraw"
      @removeAOI="removeAOI"
      @makeOrder="AOI => $refs.makeOrderDialog.openDialog(AOI)"
      @zoomTo="bbox => $refs.map.submitZoom(bbox)"
      ref="fieldControl"
      :loading.sync="loading"
      :sync.sync="sync"
      :customLegend.sync="customLegend"
      :dates.sync="dates"
      :drawing.sync="drawing"
      :selectFirstAoi="selectFirstAoi"
      :field.sync="currentField"
      :index.sync="type.code"
      :legendMenu.sync="legendMenu"
      :listField.sync="listField"
      :mapLegend.sync="mapLegend"
      :typeIndex.sync="type"
      :cloudPercent.sync="cloudPercent"
      @openDetect="() => $refs.autoDetect.openDialog()"
      @areaUnit="unit => (areaUnit = unit)"
      @fetchData="fetchData"
      @addAOIToMap="addToMap"
      @changeIndex="changeIndex"
      @changeStatusMap="changeMapView"
      @startGuide="guide => startGuide(guide)"
      @closeColorPicker="() => $refs.map.closeColorPicker()"
      @destroyChart="destroyChart"
      @displayChartData="displayChartData"
      @download="download"
      @mapSize="() => $refs.map.reSize()"
      @openAddForm="openAddForm"
      @openDrawTool="openDrawTool"
      @refreshMap="() => $refs.map.reloadMap()"
      @removeAll="removeAllMap"
      @removeLayer="layerId => $refs.map.removeLayer(layerId)"
      @resetDraw="() => $refs.map.resetDraw"
      @selectMonth="selectMonth"
      @showLayerControl="isShowlayerControl = !isShowlayerControl"
      @updateDate="changeDate"
      @zoomToField="bbox => $refs.map.submitZoom(bbox)"
    >
    </AOIManagement>
    <v-layout class="pl-4" column style="height: 100%;">
      <v-card :disabled="loading" style="width: 100%; height: 100%">
        <v-tooltip v-if="currentField" color="#000000c4" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-1"
              color="bgContainer"
              height="38"
              min-height="0"
              min-width="0"
              style="position: absolute; top: 145px; right: 5px; z-index: 2"
              v-bind="attrs"
              width="38"
              @click="changeMapView"
              v-on="on"
              small
            >
              <v-icon :color="sync ? '#fff' : 'secondary'" size="22">{{
                !sync ? 'mdi-compare' : 'mdi-compare-remove'
              }}</v-icon>
            </v-btn>
          </template>
          <span>Sync</span>
        </v-tooltip>
        <div class="compare-tool-color" v-show="sync && !this.selectFirstAoi">
          <v-btn-toggle dense style="border: 1px solid #35374b;">
            <v-btn
              :class="['first-btn', { 'selected-btn': isLeftNature }]"
              @click="isLeftNature = !isLeftNature"
              small
              color="#1e1e48"
              style="text-transform: capitalize; font-size: 11px; border-radius: 4px; width: 100px;"
            >
              Nature Color
            </v-btn>
            <v-btn
              :class="['middle-btn', { 'selected-btn': isLeftFalseColor }]"
              @click="isLeftFalseColor = !isLeftFalseColor"
              small
              color="#1e1e48"
              style="text-transform: capitalize; font-size: 11px; border-radius: 4px; width: 100px;"
            >
              False Color
            </v-btn>
            <v-btn
              v-show="!currentField"
              :class="['last-btn', { 'selected-btn': isLeftIndexColor }]"
              @click="isLeftIndexColor = !isLeftIndexColor"
              small
              color="#1e1e48"
              style="text-transform: capitalize; font-size: 11px; border-radius: 4px; width: 100px;"
            >
              Index Layer
            </v-btn>
          </v-btn-toggle>
        </div>

        <div
          class="tool-color"
          v-show="(zoomLevel >= 11 || currentField) && !this.selectFirstAoi && !drawing"
          :style="{ left: sync ? '51%' : '' }"
        >
          <v-btn-toggle dense style="border: 1px solid #35374b;">
            <v-btn
              :class="['first-btn', { 'selected-btn': isNature }]"
              @click="isNature = !isNature"
              small
              color="#1e1e48"
              style="text-transform: capitalize; font-size: 11px; width: 100px;"
            >
              Nature Color
            </v-btn>
            <v-btn
              :class="[{ 'middle-btn': !currentField, 'last-btn': currentField }, { 'selected-btn': isFalseColor }]"
              @click="isFalseColor = !isFalseColor"
              small
              color="#1e1e48"
              style="text-transform: capitalize; font-size: 11px; width: 100px;"
            >
              False Color
            </v-btn>
            <v-btn
              v-show="!currentField"
              :class="['last-btn', { 'selected-btn': isIndexColor }]"
              @click="isIndexColor = !isIndexColor"
              small
              color="#1e1e48"
              style="text-transform: capitalize; font-size: 11px; width: 100px;"
            >
              Index Layer
            </v-btn>
          </v-btn-toggle>
        </div>
        <Map
          ref="map"
          :current-layers.sync="layers"
          :isDraw.sync="drawing"
          :isLoading.sync="mapLoading"
          :type.sync="type"
          :isIndexColor.sync="isIndexColor"
          :isNature.sync="isNature"
          :isFalseColor.sync="isFalseColor"
          :dataSyncMap="dataSyncMap"
          @changeArea="getDataOfBound"
          @featureInfo="onClickField"
          @formData="data => (screenData = data)"
          @getWithinLayer="getWithinLayer"
          @zoomLevel="zoomLv => (zoomLevel = zoomLv)"
        />
        <TourGuide
          style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
          ref="tourGuide"
          :color="'#e3e3e3'"
          :iconColor="'#66808d'"
          v-if="elements"
          :elements="elements"
        />
        <Popup ref="Popup" />

        <div v-if="showLegend && type.data && !drawing" class="detail-legend pb-3">
          <div style="display:flex;flex-direction:row;align-items:center" class="mb-1 ml-1">
            <h3>Legend</h3>

            <v-tooltip top color="#000000e8" max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" size="15" class="ml-2" style="cursor:pointer">
                  mdi-information-outline
                </v-icon>
              </template>
              <div style="text-align: justify;">{{ type.description }}</div>
            </v-tooltip>
            <v-spacer />
            <v-btn
              style="align-self:end;"
              x-small
              v-if="currentUser.permissions.includes('index.customize') && !selectFirstAoi"
              @click="$refs.updateLegend.openDialog()"
            >
              <v-icon size="16" class="mr-2">mdi-pencil-box-outline</v-icon>
              <span>Edit</span>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <table class="custom-table mt-2 ml-1 ">
            <tbody>
              <tr v-for="(item, i) in type.data" :key="i">
                <td>
                  <div :style="{ 'background-color': item.color }" class="color-box mr-3"></div>
                </td>
                <td>
                  <span class="mr-3"> {{ item.value }}</span>
                </td>
                <td>
                  <span class="mr-3"> {{ item.name }}</span>
                </td>
                <td v-if="item.area">
                  <span class="mr-3" v-html="item.area"> </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="type.data && group.id === 'dashboard' && !drawing"
          class=" legend d-flex"
          style="background: #1e1e48;border:1px solid  #35374b "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                x-small
                style="border-radius: 4px 0 0 4px;"
                @click="showLegend = !showLegend"
              >
                <v-icon size="16">mdi-map-legend</v-icon>
                <v-icon v-if="!showLegend" size="22">mdi-menu-down</v-icon>
                <v-icon v-else size="22">mdi-menu-up</v-icon>
              </v-btn>
            </template>
            <span v-if="!showLegend">Show Legend</span>
            <span v-else>Hide Legend</span>
          </v-tooltip>
          <updateLegend
            ref="updateLegend"
            :type="type"
            @updateLegend="updateLegend()"
            @updateChartData="updateChartData()"
          />
          <div
            class="d-flex justify-center align-center"
            style=" width: 100%; border-left:1px solid  #35374b ;border-radius: 0 4px 4px 0; "
            v-if="mapLegend.length > 1"
          >
            <SelectIndex
              :customLegend.sync="customLegend"
              :legendMenu.sync="legendMenu"
              :mapLegend.sync="mapLegend"
              :type.sync="type"
              :width="'100%'"
              right
              :size="`x-small`"
              text
              @changeIndex="changeIndex"
            />
          </div>
        </div>
      </v-card>
      <v-card
        :style="{
          height: currentField && !sync ? '248px' : sync ? '55px' : '40px',
          'min-height': currentField && !sync ? '248px' : sync ? '55px' : '40px',
          flex: sync ? 'none' : currentField ? 2 : 'none',
          display: (currentField && !sync) || sync || months.length > 0 ? 'block' : 'none',
        }"
        class="mt-2 custom-card-bg-1"
        style="height: 250px;position:relative"
      >
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div class=" d-flex" style="height: 250px;">
          <div
            v-if="currentField && !sync"
            class=" pa-2 d-flex flex-column "
            style="border-bottom-left-radius:inderit!important;width:230px;min-width:230px;justify-content: space-evenly"
            width="200"
          >
            <legend>Filter Data:</legend>
            <div class="mb-1 mt-2">
              <v-autocomplete
                v-model="source"
                :items="sources"
                dense
                hide-details
                label="Source"
                outlined
                placeholder="Source"
                @change="changeSource()"
              ></v-autocomplete>
            </div>
            <div
              row
              align-center
              justify-center
              v-if="!selectFirstAoi && source == 'Sentinel2 L2A'"
              class="d-flex align-center mb-1 pa-2 "
              style="height: 40px;width:98%; border: 2px solid #474c6e; border-radius: 10px; margin-left:2px"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-1" v-bind="attrs" v-on="on">mdi-cloud-outline</v-icon>
                </template>
                <span>Cloud percent</span>
              </v-tooltip>
              <v-range-slider
                :min="0"
                :max="100"
                color="#b0abc6"
                hide-details
                thumb-label
                v-model="cloudPercent"
                @change="handleRangeChange"
              />
              <!-- <span class="ml-1" style="width:30%;">{{ filter.cloud[0] }}% - {{ filter.cloud[1] }}%</span> -->
            </div>
            <div class="mb-1">
              <DatePicker v-model="dates[0]" :max="dates[1]" label="From" left outlined append-icon="mdi-calendar" />
            </div>
            <div>
              <DatePicker v-model="dates[1]" :min="dates[0]" label="To" left outlined append-icon="mdi-calendar" />
            </div>
          </div>
          <div class="d-flex flex-column overflow-y-hidden" style="flex:1; position: relative;">
            <div class="custom-card-bg-0 d-flex align-center mb-2">
              <v-tooltip top v-if="!currentField">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    height="50"
                    min-height="0"
                    min-width="0"
                    width="50"
                    :loading="chartLoading"
                    :disabled="!canLoadMoreDate"
                    @click="loadMoreDate"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="secondary" size="22">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>More date</span>
              </v-tooltip>
              <SlideGroup style="flex: 1;width:90%" :items="months" :currentItem="currentMonth" multiple>
                <template v-slot:content="{ item, index }">
                  <div
                    class="item-date custom-card-bg-1 d-flex  align-center justify-center"
                    :class="{ selected: isSelectedDate(item) }"
                    :key="index"
                    :id="index === 0 ? 'itemFirst' : index === months.length - 1 ? 'itemLast' : ''"
                    @click="selectMonth(item)"
                    style="min-height:35px"
                  >
                    <div
                      class="d-flex flex-column justify-center align-center  my-1"
                      style="font-size: 12px; text-align: center;"
                    >
                      <span> {{ item.customDate }}</span>
                      <div v-if="currentField" class="d-flex align-center justify-center mt-1">
                        <div
                          v-if="item.cloud_cover !== null && item.cloud_cover >= 0"
                          class="d-flex flex-row justify-center align-center"
                          style="flex: 1; height: 100%;"
                        >
                          <v-icon :color="isSelectedDate(item) ? 'var(--v-accent-base)' : ''" size="16">
                            mdi-weather-cloudy
                          </v-icon>
                          <span
                            :style="{ color: isSelectedDate(item) ? 'var(--v-accent-base)' : '' }"
                            class="ml-2"
                            style="font-size: 10px"
                          >
                            {{ item.cloud_cover.toFixed(2) }} %
                          </span>
                        </div>
                        <v-btn-toggle v-if="sync" class="ml-2" dense mandatory v-model="item.sideDisplay">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                x-small
                                @click.stop="selectCompareMonth(item)"
                                :value="'left'"
                                v-bind="attrs"
                                v-on="on"
                                :style="{
                                  'background-color': isDisplayMap(item, 'left') ? 'var(--v-info-base)' : '',
                                }"
                              >
                                L
                              </v-btn>
                            </template>
                            <span>Left map</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                text
                                x-small
                                @click.stop="selectMonth(item)"
                                :value="'right'"
                                v-bind="attrs"
                                v-on="on"
                                :style="{
                                  'background-color': isDisplayMap(item, 'right') ? 'var(--v-info-base)' : '',
                                }"
                              >
                                R
                              </v-btn>
                            </template>
                            <span>Right map</span>
                          </v-tooltip>
                        </v-btn-toggle>
                      </div>
                    </div>
                  </div>
                </template>
              </SlideGroup>
              <div v-if="!sync && currentField" style="flex: none; width: 50px;">
                <v-layout align-center justify-center>
                  <v-tooltip v-if="!autoPlay" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="!months.length || drawing" icon @click="startPlay" v-bind="attrs" v-on="on">
                        <v-icon size="30">mdi-play-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Play</span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="!months.length || drawing" icon @click="stopPlay" v-bind="attrs" v-on="on">
                        <v-icon size="30">mdi-pause-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Stop</span>
                  </v-tooltip>
                </v-layout>
              </div>
            </div>
            <v-card class="custom-card-bg-1 mr-2" v-show="!sync && currentField" style="position:relative;height:75%">
              <div v-if="isNoData" class="no-chart">
                <v-layout align-center class="fill-height custom-card-bg-0" justify-center>
                  no data for analysis
                </v-layout>
              </div>
              <canvas
                id="myChart"
                style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"
              ></canvas>
              <v-btn
                v-if="!selectFirstAoi"
                style="position:absolute;right:0px;top:-4px;"
                :disabled="!months.length"
                small
                text
                @click="() => $refs.monitoringChart.openDialog(dataImage)"
              >
                Weather
                <v-icon>mdi-menu-right</v-icon>
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-card>
    </v-layout>
    <AutoDetection ref="autoDetect" @fetchAOI="fetchData" />
    <CreateAOI ref="createAOI" @fetchAOI="fetchData" />
    <MonitoringChart ref="monitoringChart" />
    <MakeOrder ref="makeOrder" isOnScreenDialog />
  </v-layout>
</template>

<script>
import Chart from 'chart.js/auto'
import FieldControl from './field-control/FieldControl.vue'
import Map from '@/components/Map'
import { mapState } from '@/store/ults'
import STATUS from '@/constants/record-status-constants'
import MonthPicker from '@/components/MonthPicker'
import DatePicker from '@/components/DatePicker'
import { getCluster, getDataOfBound, getIndices, getImages, getAoisTile } from '@/api/monitoring-api'
import AreaTransform from '@/utils/convertArea'
import SelectIndex from '@/components/SelectIndex'
import FieldInfor from './field-control/FieldInfor'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import utils from '@/utils/genUUID'
import booleanIntersects from '@turf/boolean-intersects'
import point from 'turf-point'
import colorFormat from '@/utils/ColorFormat'
import Popup from '@/components/Popup.vue'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { getDetailAOI } from '@/api/aoi-api'
import turf from 'turf'
import booleanWithin from '@turf/boolean-within'
import CreateAOI from '@/views/aoi/CreateAOI.vue'
import ConvertDate from '@/utils/convertDate'
import sleep from '@/utils/sleep'
import AutoDetection from '@/views/monitoring/AutoDetection.vue'
import bbox from '@turf/bbox'
import config from '@/config'
import MonitoringChart from '@/views/monitoring/chart/MonitoringChart.vue'
import updateLegend from '@/components/updateLegend.vue'
import AOIManagement from '@/views/aoi/AOIManagement.vue'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
import TourGuide from '@/components/GuideTour/index.vue'
import SlideGroup from '@/views/object-detection/components/SlideGroup.vue'
import api from '@/api/api'
export default {
  name: 'Monitoring',
  components: {
    MonitoringChart,
    AutoDetection,
    CreateAOI,
    LayerControl,
    Popup,
    DateRangerPicker,
    FieldInfor,
    SelectIndex,
    DatePicker,
    MonthPicker,
    Map,
    FieldControl,
    updateLegend,
    AOIManagement,
    MakeOrder,
    TourGuide,
    SlideGroup,
  },
  data() {
    return {
      isCreating: false,
      currentData: {},
      dataImage: undefined,
      zoomLevel: 0,
      currentUid: undefined,
      minGeometry: undefined,
      layers: [],
      addType: 'file',
      listField: [],
      loaded: false,
      indexMenu: false,
      legendMenu: false,
      chartData: [],
      myChart: undefined,
      labels: [],
      lineData: [],
      drawing: false,
      compare: {
        months: undefined,
        month: undefined,
      },
      isSmall: false,
      sync: false,
      showLegend: true,
      type: {},
      dates: [ConvertDate.getBeforeDate(), ConvertDate.getCurrentDate()],
      currentMonth: undefined,
      areaUnit: '',
      isNoData: true,
      months: [],
      autoPlay: undefined,
      currentField: undefined,
      listStatus: STATUS,
      loading: false,
      mapLoading: false,
      mapLegend: [],
      customLegend: [],
      compareStatistics: {},
      displayLayerControl: false,
      isShowlayerControl: false,
      cloudPercent: [0, 100],
      isNature: false,

      isLeftNature: false,
      isFalseColor: false,
      isLeftFalseColor: false,
      isIndexColor: false,
      isLeftIndexColor: false,
      chartLoading: false,
      newColorBbox: [],
      cacheImages: { dateTime: [] },
      timeInterval: 3 * 30 * 24 * 60 * 60 * 1000,
      elements: undefined,
    }
  },
  props: { selectFirstAoi: { type: Boolean, default: false } },
  created() {
    if (this.sources.length > 0) this.source = this.sources[0]
  },
  async mounted() {
    this.getIndex()
    const query = this.$route.query
    if (query.notification && query.noti) {
      const noti = await this.$store.dispatch('notification/getNotification', query.noti)
      this.currentData = {
        ...noti.reference_data?.order,
        aoi: noti.reference_data?.aoi,
        service: noti.reference_data?.service,
      }
      this.isNotification = true
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('legend', ['legend']),
    ...mapState('menu', ['menu', 'group']),
    ...mapState('AOI', ['listAOI', 'groups', 'filters', 'queryData', 'AOIs']),
    ...mapState('service', ['services', 'firstLoad', 'sources', 'source']),

    isTile() {
      this.$refs.map?.reSize()
      return this.isIndexColor || this.isNature || this.isFalseColor
    },
    dataSyncMap() {
      return { left: this.compare.month, right: this.currentMonth }
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 6
      }
      if (this.$vuetify.breakpoint.lg) {
        return 4
      }
      if (this.$vuetify.breakpoint.md) {
        return 3
      }
      return 1
    },
    AOIMenu() {
      return !this.sync
    },
    canLoadMoreDate() {
      return !this.$refs?.slideGroup?.$children[0].disabled
    },
  },
  watch: {
    queryData() {
      this.$nextTick(() => {
        if (this.zoomLevel < 11) this.getFieldSmallZoom()
        else this.getAoisTile()
      })
    },
    isNature(val) {
      this.handleLayerUpdate(val, this.changeDisplayNature)
    },

    isLeftNature(val) {
      this.handleLayerUpdate(val, this.changeDisplayLeftNature)
    },

    isFalseColor(val) {
      this.handleLayerUpdate(val, this.changeDisplayFalseColor)
    },

    isLeftFalseColor(val) {
      this.handleLayerUpdate(val, this.changeDisplayLeftFalseColor)
    },

    isIndexColor(val) {
      this.handleLayerUpdate(val, this.changeDisplayIndex)
    },

    isLeftIndexColor(val) {
      this.handleLayerUpdate(val, this.changeDisplayLeftIndex)
    },
    drawing(val) {
      if (!val) {
        this.$refs.map.resetDraw()
        this.$refs.map.deleteAllDraw()
      }
    },
    currentField() {
      if (!this.mapLegend.length && this.loaded) this.getIndex()
    },
    sync(val) {
      if (val) {
        this.stopPlay()
        this.selectCompareMonth(this.compare.months[this.compare.months.length - 1])
      } else this.compare.month = undefined
    },
    months(val) {
      this.compare.months = val
    },
    dates() {
      if (!this.currentField) {
        let bound = this.$refs.map && this.$refs.map.map ? this.$refs.map.map.getBounds() : undefined
        if (bound) {
          let bbox = [bound._sw.lng, bound._sw.lat, bound._ne.lng, bound._ne.lat]
          if (this.zoomLevel >= 11 && this.isTile)
            if (this.months.lengh > 0) this.getImage(bbox, true)
            else this.getImage(bbox)
        }
      } else {
        this.$refs.fieldControl.$refs.fieldControl.getChartData(this.currentField, true)
      }
    },
    source() {
      this.getIndex()
    },
  },
  methods: {
    isSelectedDate(item) {
      return this.currentMonth.id === item.id || this.compare.month?.id === item.id
    },
    isDisplayMap(item, side) {
      return side == 'left' ? this.compare.month?.id == item.id : this.currentMonth?.id == item.id
    },
    startGuide(guide) {
      this.elements = [
        {
          ref: guide.aoiRef,
          id: 'AOI',
          title: 'Getting Started',
          text:
            this.AOIs.length > 0
              ? 'Select an AOI to get results'
              : "You don't have any AOIs with results. Please submit an order.",
          position: 'right',
          isHideContinueBtn: this.AOIs.length > 0 ? undefined : true,
          buttons:
            this.AOIs.length > 0
              ? undefined
              : [
                  {
                    text: 'Submit Order',
                    action: () => {
                      this.$refs.tourGuide.completeTour()
                      this.$refs.makeOrder.openDialog(null, this.$route.meta.service)
                    },
                  },
                ],
        },
        {
          ref: guide.frequencyRef,
          id: 'frequency',
          title: 'Getting Started',
          text:
            guide.frequencies.length > 0 ? 'Select a frequency to show' : 'No result found, please check your order',
          position: 'bottom',
          buttons:
            guide.frequencies.length > 0
              ? undefined
              : [
                  {
                    text: 'Check order',
                    action: () => {
                      this.$refs.tourGuide.completeTour()
                      this.$router.push({
                        name: 'orders',
                        query: {
                          aoiId: this.currentAOI?.uuid,
                          service: this.services.find(service => service.name == this.$route.meta.service).id,
                          source: this.source,
                        },
                      })
                    },
                  },
                ],
        },
      ]
    },
    handleLayerUpdate(val, changeDisplayCallback) {
      let bound = this.$refs.map.map.getBounds()
      let bbox = [bound._sw.lng, bound._sw.lat, bound._ne.lng, bound._ne.lat]

      if ((!this.minGeometry || !booleanWithin(turf.bboxPolygon(bbox), this.minGeometry)) && val) {
        this.getDataOfBound(this.zoomLevel, bound)
      }
      changeDisplayCallback()
    },
    handleRangeChange() {
      this.$refs.fieldControl.$refs.fieldControl.getChartData(this.currentField, true)
    },
    loadMoreDate() {
      let fromDate = new Date(new Date(this.dates[0]).getTime() - this.timeInterval)
      let formattedFromDate = fromDate.toISOString().slice(0, 10)
      this.dates = [formattedFromDate, this.dates[1]]
    },
    startDraw() {
      this.isCreating = true
      this.drawing = true
    },
    async updateLegend() {
      await this.getIndex()
      if (!this.currentField) {
        if (this.zoomLevel >= 11) {
          this.isSmall = false
          this.$refs.map.removeCluster()

          await this.getImage(this.newColorBbox)
        }
      }
    },
    filterData() {
      if (!this.currentField) {
        this.currentUid = undefined
        this.minGeometry = undefined
        this.getDataOfBound(this.zoomLevel, this.$refs.map.map.getBounds())
      }
    },
    onClickField(features, point) {
      if (features[0].properties.uuid) this.selectField(features[0].properties.uuid)
    },
    fetchData() {
      this.isSmall = false
      this.drawing = false
      this.$refs.fieldControl.getAOI()
      this.filterData()
    },
    async openSaveDialog() {
      try {
        let geometry = this.$refs.map.getAllDrawFeature()
        this.$refs.createAOI.openDialog(geometry)
      } catch (e) {
      } finally {
      }
    },

    async getInfoAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addToMap(AOI) {
      try {
        this.mapLoading = true
        const res = await this.getInfoAOI(AOI)
        let layer = this.layers.find(layer => (layer.id = res.uuid))
        if (!layer) this.$refs.map.addGeoJsonToMap(res.name, res.geojson, 'yellow', res.uuid, 'line', true)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.mapLoading = false
      }
    },
    removeAOI() {
      this.$refs.map.removeAllLayer()
    },
    changeMapView() {
      this.sync = !this.sync
      this.$refs.map.mapSync = this.sync
    },
    getWithinLayer(currentPoint, color, pointData) {
      if (!booleanIntersects(point(currentPoint), this.currentField.geojson)) {
        this.$refs.Popup.close()
        this.$refs.map.removePopup()
      } else {
        let html = `<div style='width: fit-content; text-align: center;'>
                        <div style='float: left; width: 20px; height: 20px; background-color: ${colorFormat
                          .covertToHex(color)
                          .toUpperCase()}'></div>
                        <span style='float: left; color: black; margin-left: 10px'>${pointData.name}</span>
                        </div>`
        this.$refs.map.displayPopup(currentPoint, html, false)
      }
    },
    async getDataOfBound(zoom, bound) {
      if (this.currentField) return
      let bbox = [bound._sw.lng, bound._sw.lat, bound._ne.lng, bound._ne.lat]
      this.newColorBbox = bbox
      if (zoom >= 11) {
        this.$refs.map.removeCluster()
        if (this.isSmall) await this.getAoisTile()
        this.isSmall = false
        if (
          this.isTile &&
          (this.type.code !== this.cacheImages.index ||
            !this.minGeometry ||
            !booleanWithin(turf.bboxPolygon(bbox), this.minGeometry) ||
            this.months.length == 0)
        ) {
          await this.getImage(bbox)
        }
      } else if (this.isSmall === false) {
        this.currentUid = undefined
        this.minGeometry = undefined
        this.currentMonth = undefined
        this.months = []
        this.$refs.map.removeAllLayer()
        this.isSmall = true
        await this.getFieldSmallZoom()
      }
    },
    async getAoisTile() {
      try {
        let aoisLayer = this.layers.find(layer => layer.dataId == 'crop_aoi_line')
        if (aoisLayer) {
          this.$refs.map.removeLayer(aoisLayer.id)
          this.$refs.map.removeSource('source_crop_aoi_line')
        }

        const res = await getAoisTile({
          projectId: this.$route.params.id,
          payload: {
            search: this.queryData.search,
            filters: this.queryData.filters,
            service_name: this.$route.meta.service,
          },
        })
        this.$refs.map.addVectorTiles(
          {
            display: true,
            data: { type: 'vector-tiles' },
            tiles: [res.data.tile_url],
            bounds: res.data.bbox,
            layerName: 'default',
            name: 'crop_aoi_line',
            id: 'crop_aoi_line',
            paint: { 'line-color': '#3592FD', 'line-width': 2 },
          },
          true,
          false,
          'crop_aoi_line',
        )
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    changeDisplayNature() {
      if (this.isNature) {
        this.isFalseColor = false
        this.isIndexColor = false
      }
      if (this.currentMonth) {
        let currentImageLayer = this.layers.find(layer => layer.dataId === this.currentMonth.id + '-nature')
        if (currentImageLayer) {
          this.$refs.map.changeVisibility(currentImageLayer.id, this.isNature ? 'visible' : 'none')
        }
      }
    },
    changeDisplayIndex() {
      if (this.currentField) {
        let currentImageLayer = this.layers.find(layer => layer.dataId === this.currentMonth.id)
        this.$refs.map.changeVisibility(currentImageLayer.id, 'visible')
        return
      }
      if (this.isIndexColor) {
        this.isNature = false
        this.isFalseColor = false
      }
      if (this.currentMonth) {
        let currentImageLayer = this.layers.filter(layer => layer.dataId == this.currentMonth.id)
        if (currentImageLayer) {
          currentImageLayer.forEach(image => {
            this.$refs.map.changeVisibility(image.id, this.isIndexColor ? 'visible' : 'none')
          })
        }
      }
    },
    changeSource() {
      this.$refs.fieldControl.$refs.fieldControl.getChartData(this.currentField)
    },
    changeDisplayLeftIndex() {
      let imageLayers = this.layers.filter(layer => layer.dataId)
      imageLayers.forEach(layer => {
        this.$refs.map.changeVisibleLeftLayer(layer.id, 'none')
      })
      if (this.currentMonth) {
        let currentImageLayer = this.layers.find(layer => layer.dataId === this.compare.month.id)
        if (currentImageLayer) {
          this.$refs.map.changeVisibleLeftLayer(currentImageLayer.id, this.isLeftIndexColor ? 'visible' : 'none')
          if (this.isLeftIndexColor) {
            this.isLeftNature = false
            this.isLeftFalseColor = false
          }
        }
      }
    },
    changeDisplayFalseColor() {
      if (this.isFalseColor) {
        this.isNature = false
        this.isIndexColor = false
      }
      if (this.currentMonth) {
        let currentImageLayer = this.layers.find(layer => layer.dataId === this.currentMonth.id + '-falseColor')
        if (currentImageLayer) {
          this.$refs.map.changeVisibility(currentImageLayer.id, this.isFalseColor ? 'visible' : 'none')
        }
      }
    },
    changeDisplayLeftNature() {
      let currentImageLayer = this.layers.find(layer => layer.dataId === this.compare.month.id + '-nature')
      if (currentImageLayer) {
        this.$refs.map.changeVisibleLeftLayer(currentImageLayer.id, this.isLeftNature ? 'visible' : 'none')
        if (this.isLeftNature) {
          this.isLeftFalseColor = false
          this.isLeftIndexColor = false
        }
      }
    },
    changeDisplayLeftFalseColor() {
      let currentImageLayer = this.layers.find(layer => layer.dataId === this.compare.month.id + '-falseColor')
      if (currentImageLayer) {
        this.$refs.map.changeVisibleLeftLayer(currentImageLayer.id, this.isLeftFalseColor ? 'visible' : 'none')
        if (this.isLeftFalseColor) {
          this.isLeftNature = false
          this.isLeftIndexColor = false
        }
      }
    },

    async getImage(bbox, isLoadMore = false) {
      if (this.months.length == 0) this.loading = true
      this.chartLoading = true
      try {
        if (
          !this.minGeometry ||
          !booleanWithin(turf.bboxPolygon(bbox), this.minGeometry) ||
          this.dates[0] !== this.cacheImages.dateTime[0] ||
          this.dates[1] !== this.cacheImages.dateTime[1]
        ) {
          let data = {
            payload: {
              geometry: `POLYGON((${bbox[0]} ${bbox[1]},${bbox[2]} ${bbox[1]},${bbox[2]} ${bbox[3]},${bbox[0]} ${bbox[3]},${bbox[0]} ${bbox[1]}))`,
              contains: 1,
              source: 'Sentinel2 L2A',
              from_date: this.dates[0] ? this.dates[0] : ConvertDate.getBeforeDate(),
              to_date: isLoadMore
                ? new Date(new Date(this.dates[0]).getTime() + this.timeInterval)
                : this.dates[1]
                ? this.dates[1]
                : ConvertDate.getCurrentDate(),
              min_geom: 1,
              unique: 1,
              direction: 'asc',
              with: 'tile_url',
              service_name: this.$route.meta.service,
            },
          }
          const res = await getDataOfBound(data)
          this.cacheImages = { dateTime: this.dates, data: res.data.data }
          this.minGeometry = res.data.min_geometry
            ? {
                type: 'Feature',
                properties: {},
                geometry: res.data.min_geometry,
              }
            : undefined
        }
        if (!isLoadMore) {
          let imageLayers = this.layers.filter(val => val.dataId && val.id !== 'crop_aoi_line')
          imageLayers.forEach(image => {
            this.$refs.map.removeLayer(image.id)
          })
          this.months = []
        }
        let images = []
        let currentMonths = JSON.parse(JSON.stringify(this.months))
        this.months = []

        this.cacheImages.data.forEach((item, index) => {
          this.months.push({
            id: item.product_id,
            date: ConvertDate.dateFormatted(item.content_date),
            customDate: ConvertDate.customDate(item.content_date),
            cloud_cover: null,
            url: item.tile_url,
          })

          images.push({
            id: item.product_id,
            data: item,
            layerType: 'Raster_Image',
            name: item.date,
            tiles: [
              `${config.upload_url}${item.tile_url}&expression=${encodeURIComponent(this.type.expression)}${
                this.type.color_map ? `&color_map=${encodeURIComponent(this.type.color_map)}` : ''
              }`,
            ],
            bounds: turf.bbox(item.geometry),
            display: index === 0 && this.isIndexColor,
            meta: { MIN_ZOOM: 11 },
          })
          images.push({
            id: item.product_id,
            data: item,
            layerType: 'Raster_Image',
            name: item.date + '_cloud',
            tiles: [`${config.upload_url}${item.tile_url.replace('aws', 'sen2-cloud')}`],
            bounds: turf.bbox(item.geometry),
            display: index === 0 && this.isIndexColor,
            meta: { MIN_ZOOM: 11 },
          })
          images.push({
            id: item.product_id + '-nature',
            data: item,
            layerType: 'Raster_Image',
            name: item.date,
            tiles: [`${config.upload_url}${item.tile_url}`],
            bounds: turf.bbox(item.geometry),
            display: index === 0 && this.isNature,
            meta: { MIN_ZOOM: 11 },
          })

          images.push({
            id: item.product_id + '-falseColor',
            data: item,
            layerType: 'Raster_Image',
            name: item.date,
            tiles: [`${config.upload_url}${item.tile_url}&expression=B08;B04;B03`],
            bounds: turf.bbox(item.geometry),
            display: index === 0 && this.isFalseColor,
            meta: { MIN_ZOOM: 11 },
          })
        })
        this.months = [...this.months, ...currentMonths]
        if (!this.currentMonth) this.currentMonth = this.months[0]
        this.cacheImages.index = this.type.code
        let field = this.layers.find(image => image.id == 'crop_aoi_line')
        let addImage = field
          ? image => this.$refs.map.addImageToMap(image, 'crop_aoi_line')
          : image => this.$refs.map.addImageToMap(image)

        images.forEach(addImage)
      } catch (e) {
        console.log(e)
      } finally {
        this.chartLoading = false
        this.loading = false
      }
    },
    async getFieldSmallZoom() {
      try {
        this.loading = true
        const res = await getCluster({
          projectId: this.$route.params.id,
          payload: {
            search: this.queryData.search,
            filters: this.queryData.filters,
            service_name: this.$route.meta.service,
          },
        })
        await sleep(200)
        this.$refs.map.displayCluster(res.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeDate(status) {
      if (status) this.dates = [`01-01-${new Date().getFullYear() - 1}`, ConvertDate.getCurrentDate()]
      else this.dates = [ConvertDate.getBeforeDate(), ConvertDate.getCurrentDate()]
    },
    openAddForm(type) {
      this.addType = type
    },
    async changeIndex(index) {
      this.compareStatistics = index
      this.type = index
      this.type.compareData = this.type.data ? JSON.parse(JSON.stringify(this.type.data)) : []
      this.indexMenu = false
      this.legendMenu = false
      this.currentUid = undefined
      await sleep(100)
      if (!this.currentField) {
        await this.getDataOfBound(this.zoomLevel, this.$refs.map.map.getBounds())
      }

      await sleep(200)
      if (this.sync) this.selectCompareMonth(this.compare.month)
      if (this.currentMonth) this.selectMonth(this.currentMonth)
    },
    async getIndex() {
      try {
        const res = await getIndices({ payload: { service_name: this.$route.meta.service, image_source:this.currentField ?  this.source : 'Sentinel2 L2A' } })
        this.$store.commit('legend/SET_LEGEND', res.data)
        this.mapLegend = res.data
        let newIndex = res.data.find(index => index.code == this.type.code)
        if (newIndex) this.type = newIndex
        else this.type = res.data[0] ? res.data[0] : {}
        this.type.compareData = this.type.data ? JSON.parse(JSON.stringify(this.type.data)) : []
      } catch (e) {
      } finally {
        this.loaded = true
        this.changeLegend()
      }
    },
    changeLegend() {
      let val
      if (this.currentMonth && this.currentField) {
        val = this.mapLegend.find(val => val.code === this.type.code)
        if (val.data) {
          val.data.forEach(index => {
            if (this.currentMonth.statistics) {
              let tmpIndex = this.currentMonth.statistics.find(el => el.min_value === index.min_value)
              if (tmpIndex) index['area'] = AreaTransform.transformUnit(tmpIndex.area)
            }
          })
        }
        if (this.type.data) {
          let tmpData = JSON.parse(JSON.stringify(this.type.data))
          this.type.data = []

          tmpData.forEach(item => {
            if (this.currentMonth.statistics) {
              let tmpIndex = this.currentMonth.statistics.find(el => el.min_value === item.min_value)
              if (tmpIndex) item.area = AreaTransform.transformUnit(tmpIndex.area)
            }
          })
          this.type.data = tmpData
        }
        if (this.type.compareData && this.sync) {
          let tmpData = JSON.parse(JSON.stringify(this.type.compareData))
          this.type.compareData = []
          tmpData.forEach(item => {
            if (this.compare.month.statistics) {
              let tmpIndex = this.compare.month.statistics.find(el => el.min_value === item.min_value)
              if (tmpIndex) item.area = AreaTransform.transformUnit(tmpIndex.area)
            }
          })
          this.type.compareData = tmpData
        }
      } else {
        this.mapLegend.forEach(val => {
          if (val.data) {
            val.data.forEach(index => {
              index.area ? delete index.area : ''
            })
          }
        })
        if (this.type.data)
          this.type.data.forEach(val => {
            delete val.area
          })
        if (this.type.compareData)
          this.type.compareData.forEach(val => {
            delete val.area
          })
      }
    },
    async destroyChart() {
      this.$refs.map.removeAllLayer()
      this.isSmall = this.zoomLevel > 11 ? true : false
      this.currentMonth = undefined
      this.compare.month = undefined
      this.months = []
      this.currentField = undefined
      this.stopPlay()
      if (this.type.data)
        this.type.data.forEach(val => {
          delete val.area
        })
      this.currentUid = undefined
      this.myChart ? this.myChart.destroy() : ''
      this.myChart = undefined
      if(this.source !== 'Sentinel2 L2A') this.getIndex()
    },
    async selectField(uuid) {
      try {
        this.loading = true
        await this.$refs.fieldControl.selectField(this.listAOI.find(val => val.uuid === uuid))
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    removeAllMap() {
      this.$refs.map.removeAllLayer()
      this.$refs.map.removeCluster()
    },
    getCurrentDate() {
      let currentDate = new Date()
      let month = currentDate.getMonth() + 1 < 10 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1
      let year = currentDate.getFullYear()

      return year + '-' + month + '-' + '01'
    },
    getBeforeDate() {
      let beforeDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3)

      let beforeMonth = beforeDate.getMonth() + 1 < 10 ? '0' + (beforeDate.getMonth() + 1) : beforeDate.getMonth() + 1
      let beforeYear = beforeDate.getFullYear()
      return beforeYear + '-' + beforeMonth + '-' + '01'
    },
    displayTimeLine(data) {
      data.forEach(d => (d.customDate = ConvertDate.customDate(d.date)))
      this.months = data
      this.currentMonth =
        this.currentMonth && this.months.some(month => month.date === this.currentMonth.date)
          ? this.months.find(month => month.date === this.currentMonth.date)
          : data[data.length - 1]
      this.changeLegend()
      this.selectMonth(this.currentMonth)
    },
    async updateChartData() {
      try {
        if (this.currentField) {
          this.mapLoading = true
          this.chartLoading = true
        }

        this.loading = true
        const res = await getImages({
          id: this.currentField.uuid,
          projectId: this.$route.params.id,
          payload: {
            source: this.source,
            per_page: 'all',
            from_date: this.dates[0],
            to_date: this.dates[1],
            index: this.type.code,
            service_name: this.$route.meta.service,
            sort: 'acquired_date',
          },
        })
        this.chartLoading = false

        if (!res.data.length) this.$store.commit('message/SHOW_ERROR', 'No data available')
        this.images = res.data
        this.displayChartData(res.data)
        this.mapLoading = false
      } catch (e) {
      } finally {
        this.loading = false
        this.chartLoading = false
      }
    },
    displayChartData(data) {
      this.dataImage = data
      this.$refs.map.removeAllLayer()
      this.$refs.map.removeCluster()
      this.$refs.map.submitZoom(this.currentField.bbox)
      data.forEach(image => {
        image.geometry = this.currentField.geojson
      })

      this.$refs.map.addGeoJsonToMap(
        utils.getUUID(),
        {
          type: 'FeatureCollection',
          name: this.currentField.uuid,
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: this.currentField.geojson,
            },
          ],
        },
        '#3592FD',
        'aoi_' + this.currentField.uuid,
        'line',
        true,
      )

      let images = []
      data.forEach((item, index) => {
        images.push({
          id: item.id,
          data: item,
          layerType: 'Raster_Image',
          name: item.date,
          tiles: [item.tile_url],
          bounds: item.bbox,
          display: index === data.length - 1,
          meta: { MIN_ZOOM: 7 },
        })
        if (item.cloud_tile_url) {
          images.push({
            id: item.id,
            data: item,
            layerType: 'Raster_Image',
            name: item.date + '_cloud',
            tiles: [item.cloud_tile_url],
            bounds: item.bbox,
            display: index === data.length - 1,
            meta: { MIN_ZOOM: 7 },
          })
        }
        if (item.true_color_tile_url) {
          images.push({
            id: item.id + '-nature',
            data: item,
            layerType: 'Raster_Image',
            name: item.date + '_nature',
            tiles: [item.true_color_tile_url],
            bounds: item.bbox,
            display: index === data.length - 1 && this.isNature,
            meta: { MIN_ZOOM: 7 },
          })
        }
        if (item.false_color_tile_url) {
          images.push({
            id: item.id + '-falseColor',
            data: item,
            layerType: 'Raster_Image',
            name: item.date + '_falseColor',
            tiles: [item.false_color_tile_url],
            bounds: item.bbox,
            display: index === data.length - 1 && this.isFalseColor,
            meta: { MIN_ZOOM: 7 },
          })
        }
      })
      for (let i = 0; i < images.length; i++) {
        this.$refs.map.addImageToMap(images[i], undefined, false)
      }
      this.$refs.map.isColorPicker = true
      if (!data[0] || !data[0].statistics) {
        this.months = []
        this.isNoData = true
        this.updateChart(data)
        return
      }

      this.isNoData = false
      if (this.myChart) this.updateChart(data)
      else this.createChart(data)
    },
    updateChart(data) {
      this.displayTimeLine(data)
      this.myChart.data.labels = data.map(val => val.date)
      let datasets = []
      let lineData = {
        type: 'line',
        label: 'Average',
        data: data.map(val => val.average),
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        pointHoverRadius: 4,
        backgroundColor: 'rgba(20,60,224,0.35)',
        borderColor: 'rgb(4,31,225)',
        stack: 'Average',
        yAxisID: 'y',
      }
      if (!this.selectFirstAoi) {
        let minTempData = {
          type: 'line',
          label: 'Temp min',
          data: data.map(val => val.weather.tempmin),
          fill: false,
          tension: 0.6,
          pointRadius: 2.5,
          pointHoverRadius: 4,
          backgroundColor: 'rgba(3,169,244,0.38)',
          borderColor: 'rgb(68 234 255)',
          borderWidth: 2.5,
          yAxisID: 'y2',
        }
        let maxTempData = {
          type: 'line',
          label: 'Temp max',
          data: data.map(val => val.weather.tempmax),
          fill: false,
          tension: 0.6,
          pointRadius: 2.5,
          pointHoverRadius: 4,
          backgroundColor: 'rgba(224,64,251,0.38)',
          borderColor: '#E040FB',
          borderWidth: 2.5,
          yAxisID: 'y2',
        }
        datasets.unshift(minTempData)
        datasets.unshift(maxTempData)
      }

      data.forEach(val => {
        if (val.statistics)
          val.statistics.forEach(index => {
            if (!datasets.some(el => el.label === index.label)) {
              datasets.push({
                label: index.label,
                data: [this.convertUnit(index.area)],
                type: 'bar',
                barPercentage: 0.8,
                backgroundColor: [index.color],
                borderWidth: 1,
                stack: val.date,
                yAxisID: 'y1',
              })
            } else {
              datasets.find(data => data.label === index.label).data.push(this.convertUnit(index.area))
            }
          })
      })
      datasets.unshift(lineData)

      this.myChart.data.datasets = datasets
      this.myChart.update()
      this.myChart.resize()
    },
    createChart(data) {
      this.transformData(data)
      this.displayChart()
    },
    transformData(data) {
      this.displayTimeLine(data)
      this.labels = data.map(val => val.date)
      this.chartData = []
      if (!this.selectFirstAoi) {
        let tempMinData = {
          type: 'line',
          label: 'Temp min (°C)',
          data: data.map(val => val.weather.tempmin),
          fill: false,
          tension: 0.6,
          pointRadius: 2.5,
          pointHoverRadius: 4,
          backgroundColor: 'rgba(3,169,244,0.38)',
          borderColor: 'rgb(68 234 255)',
          borderWidth: 2.5,
          yAxisID: 'y2',
        }
        let tempMaxData = {
          type: 'line',
          label: 'Temp max (°C)',
          data: data.map(val => val.weather.tempmax),
          fill: false,
          tension: 0.6,
          pointRadius: 2.5,
          pointHoverRadius: 4,
          backgroundColor: 'rgba(224,64,251,0.38)',
          borderColor: '#E040FB',
          borderWidth: 2.5,
          yAxisID: 'y2',
        }
        this.chartData.unshift(tempMaxData)
        this.chartData.unshift(tempMinData)
      }
      let lineData = {
        type: 'line',
        label: 'Average',
        data: data.map(val => val.average),
        fill: false,
        tension: 0.6,
        pointRadius: 2.5,
        pointHoverRadius: 4,
        backgroundColor: '#116DF2',
        borderColor: '#116DF2',
        stack: 'Average',
        borderWidth: 2.5,
        yAxisID: 'y',
      }

      data.forEach(val => {
        val.statistics.forEach(index => {
          if (!this.chartData.some(el => el.label === index.label)) {
            this.chartData.push({
              label: index.label,
              data: [this.convertUnit(index.area)],
              type: 'bar',
              barPercentage: 0.8,
              backgroundColor: [index.color],
              borderWidth: 1,
              stack: val.date,
              yAxisID: 'y1',
            })
          } else {
            this.chartData.find(data => data.label === index.label).data.push(this.convertUnit(index.area))
          }
        })
      })
      this.chartData.unshift(lineData)
    },
    convertUnit(area) {
      if (this.areaUnit === 'Square kilometer') return Math.round((area / 1000000) * 100) / 100
      else if (this.areaUnit === 'Hectare') return Math.round((area / 10000) * 100) / 100
      else return Math.round(area * 100) / 100
    },
    displayChart() {
      let ctx = document.getElementById('myChart')
      let data = {
        labels: this.labels,
        datasets: this.chartData,
      }

      this.myChart = new Chart(ctx, {
        data: data,
        options: {
          responsive: true, // Enable responsiveness
          maintainAspectRatio: false,
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentIndex = this.months.find(month => month.date === data.labels[e.index])
            if (currentIndex) this.selectMonth(currentIndex)
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
              labels: {
                color: '#FFFFFF',
                filter: function(item, chart) {
                  return chart.datasets[item.datasetIndex].type !== 'bar'
                },
              },
            },
          },
          pointBackgroundColor: '#fff',
          scales: {
            x: {
              stacked: true,
              grid: {
                color: '#2F3241',
                display: true,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9,
                },
              },
            },
            y1: {
              title: {
                color: 'white',
                text: `Area (${this.areaUnit})`,
                display: true,
              },
              stacked: true,
              grid: {
                color: '#2F3241',
                display: true,
              },
              ticks: {
                color: 'white',
                font: {
                  size: 9.5,
                },
              },
            },
            y2: {
              type: 'linear',
              position: 'right',
              offset: true,
              offsetAmount: 50,
              // min: -60,
              max: 60,
              title: {
                color: 'rgb(68 234 255)',
                text: '- Temp ( °C ) -',
                display: true,
              },
              stacked: false,
              grid: {
                color: '#85858c',
                display: false,
              },
              ticks: {
                color: 'rgb(68 234 255)',
                font: {
                  size: 9.5,
                },
              },
            },
            y: {
              position: 'right',
              title: {
                color: '#116DF2',
                text: '- Index Average -',
                display: true,
              },
              stacked: true,
              grid: {
                color: '#2F3241',
                display: false,
              },
              ticks: {
                color: '#116DF2',
                font: {
                  size: 9.5,
                },
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    selectCompareMonth(month) {
      let imageLayers = this.layers.filter(layer => layer.dataId && layer.id !== 'crop_aoi_line')
      imageLayers.forEach(layer => {
        this.$refs.map.changeVisibleLeftLayer(layer.id, 'none')
      })
      this.compare.month = { ...month }
      if (this.currentField) {
        let currentImageLayers = this.layers.filter(layer => layer.dataId === this.compare.month.id)
        currentImageLayers.forEach(layer => {
          this.$refs.map.changeVisibleLeftLayer(layer.id, 'visible')
        })
      }
      if (this.isLeftNature) this.changeDisplayLeftNature()
      if (this.isLeftFalseColor) this.changeDisplayLeftFalseColor()
      if (this.isLeftIndexColor) this.changeDisplayLeftIndex()
      this.$nextTick(() => {
        this.changeLegend()
      })
    },
    selectMonth(month) {
      let imageLayers = this.layers.filter(layer => layer.dataId && layer.id !== 'crop_aoi_line')
      imageLayers.forEach(layer => {
        this.$refs.map.changeVisibility(layer.id, 'none')
      })
      this.currentMonth = { ...month }
      if (this.currentField) {
        let currentImageLayers = this.layers.filter(layer => layer.dataId == this.currentMonth.id)
        currentImageLayers.forEach(layer => {
          this.$refs.map.changeVisibility(layer.id, 'visible')
        })
      }
      if (this.isNature) this.changeDisplayNature()
      if (this.isFalseColor) this.changeDisplayFalseColor()
      if (this.isIndexColor) this.changeDisplayIndex()

      this.$nextTick(() => {
        this.changeLegend()
      })
    },
    openDrawTool() {
      this.drawing = true
      this.$nextTick(() => {
        this.$refs.map.changeMode('polygon')
        this.$refs.map.reSize()
      })
    },
    async download(type) {
      switch (type) {
        case 'contours':
          let dataStr =
            'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.currentField.featureCollection))
          let downloadAnchorNode = document.createElement('a')
          downloadAnchorNode.setAttribute('href', dataStr)
          downloadAnchorNode.setAttribute('download', this.currentField.name + '.geojson')
          document.body.appendChild(downloadAnchorNode) / downloadAnchorNode.click()
          downloadAnchorNode.remove()
          break
        case 'tif':
          window.open(this.currentMonth.download_url, '_blank')
          break
        case 'qml':
          let res = await api.get(`${config.backend}/download-style?index=${this.type.code}`)
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = `${this.type.code}.qml`
          a.click()
          a.remove()
          break
        case 'csv':
          let string = 'Date, '
          this.type.data.forEach((val, ind) => {
            string = string + val.name
            if (ind < this.type.data.length - 1) string = string + ', '
            else string = string + ' \n'
          })
          this.months.forEach(month => {
            string = string + month.date + ', '
            this.type.data.forEach((val, index) => {
              let value = month.statistics.find(item => item.min_value === val.min_value)
              string = string + value.area
              if (index < this.type.data.length - 1) string = string + ', '
              else string = string + ' \n'
            })
          })
          let strData = 'data:text/json;charset=utf-8,' + encodeURIComponent(string)
          let downloadNode = document.createElement('a')
          downloadNode.setAttribute('href', strData)
          downloadNode.setAttribute('download', this.currentField.name + '_' + this.type.name + '.csv')
          document.body.appendChild(downloadNode) // required for firefox
          downloadNode.click()
          downloadNode.remove()
          break
        default:
          return
      }
    },
    startPlay() {
      let index = this.months.findIndex(val => val.id === this.currentMonth.id)
      this.autoPlay = setTimeout(() => {
        if (index + 1 < this.months.length) {
          this.selectMonth(this.months[index + 1])
          this.startPlay()
        } else {
          this.stopPlay()
        }
      }, 1000 * 3)
    },
    stopPlay() {
      if (this.autoPlay) clearTimeout(this.autoPlay)
      this.autoPlay = undefined
    },
  },
}
</script>

<style scoped>
::v-deep .select-input .v-input__control {
  box-shadow: 1px 2px 2px rgba(128, 124, 133, 0.2), inset 0 0 4px rgba(137, 63, 242, 0.33);
}

::v-deep .small-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: currentColor;
  transform: scale(0.8);
  transition: none;
}

::v-deep .ant-calendar-picker-input {
  display: flex;
  flex-direction: column;
}
::v-deep .ant-calendar-range-picker-input {
  width: 100%;
}
.tool-color {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}
.compare-tool-color {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}
.legend {
  padding: 2px;
  border-radius: 5px !important;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
}
.slide-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.detail-legend {
  position: absolute;
  bottom: 40px;
  left: 10px;
  padding: 5px;
  overflow-x: hidden;
  font-size: 12px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #1e1e48;
  border-radius: 5px;
}
.legend-item {
  height: 50px;
}
.selected-btn {
  background-color: linear-gradient(
    70deg,
    rgba(54, 54, 110, 0.3) 0%,
    rgba(44, 44, 89, 0.38) 30%,
    rgba(45, 45, 98, 0.27) 40%
  );
}
.first-btn {
  border-radius: 4px 0 0 4px !important;
}

.middle-btn {
  border-radius: 0 !important;
}

.last-btn {
  border-radius: 0 4px 4px 0 !important;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px;
}

.custom-table th,
.custom-table td {
  text-align: left;
}

.color-box {
  width: 20px;
  height: 20px;
}
.item-date {
  cursor: pointer;
  position: relative;
  padding: 0 10px !important;
  margin: 0 2px !important;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  border-radius: 4px;
  border: 1px solid transparent !important;
  &.selected {
    color: var(--v-accent-base);
  }
  &:hover {
    border: 1px solid var(--v-primary-base);
  }
}
::v-deep {
  .v-btn:not(.v-btn--round).v-size--x-small {
    min-width: 22px !important;
  }
  .v-btn:not(.v-btn--round).v-size--x-small {
    height: 20px !important;
  }
  .v-slide-group__prev {
    display: flex;
  }
  .v-slide-group__next {
    display: flex;
  }
  .v-slide-group {
    scroll-behavior: smooth;
  }
  .v-card__text {
    padding: 2px 6px 6px !important;
  }
  .v-card .v-card__title {
    padding: 10px 0;
  }
}
</style>
