<template>
  <v-dialog v-model="dialog" persistent width="600">
   
    <v-layout
      @mouseup="event => stopDrag(event)"
      @mousemove="event => drag(event)"
      style=" font-size: 12px; background: var(--v-bgListItem-base); border-radius: 8px "
      class="dialog-container"
    >
    <h3>Edit Legend</h3>
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div ref="bar" class="bar-container">
        <v-tooltip top v-for="(item, i) in updateForm.data" :key="i">
          <template v-slot:activator="{ on, attrs }">
            <div
              :style="{
                width: getBarWidth(i) + '%',
                backgroundColor: item.color,
              }"
              :ref="`barItem${i}`"
              class="bar-item"
              v-bind="attrs"
              v-on="on"
            >
              <div
                v-if="i > 0 && i < updateForm.data.length - 1"
                @mousedown="event => startDrag(i, event)"
                class="edge left-edge"
              ></div>
              <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{
                updateForm.data[i + 1]
                  ? `${Math.round(updateForm.data[i].min_value * 100) / 100} - ${Math.round(
                      updateForm.data[i + 1].min_value * 100,
                    ) / 100}`
                  : `> ${Math.round(updateForm.data[i].min_value * 100) / 100}`
              }}</span>
            </div>
          </template>
          <span>{{
            updateForm.data[i + 1]
              ? `${Math.round(updateForm.data[i].min_value * 100) / 100} - ${Math.round(
                  updateForm.data[i + 1].min_value * 100,
                ) / 100}`
              : `> ${Math.round(updateForm.data[i].min_value * 100) / 100}`
          }}</span>
        </v-tooltip>
      </div>
      <v-layout class="list-container">
        <div class="list-item" v-for="(item, i) in updateForm.data" :key="i" align-center>
          <input style="width: 25px; height: 25px;" class="mr-2" type="color" v-model="item.color" />
          <div style="width: 200px; display: flex; justify-content: center; align-items: center;" class="d-flex">
            <v-text-field
              prepend-inner-icon="ri-hourglass-fill"
              type="number"
              :min="getMin(i)"
              step="0.01"
              :max="getMax(i)"
              v-model.number="item.min_value"
              style="width: 50px!important"
              :error-messages="
                (item.min_value < getMin(i) || item.min_value > getMax(i)) &&
                i !== 0 && i !== updateForm.data.length - 1
                  ? 'wrong range value'
                  : ''
              "
            />

            <div v-if="updateForm.data[i + 1]">-</div>
            <v-text-field
              v-if="updateForm.data[i + 1]"
              prepend-inner-icon="ri-hourglass-fill"
              type="number"
              :min="getMin(i + 1)"
              step="0.01"
              placeholder="Max value"
              :max="getMax(i + 1)"
              v-model.number="updateForm.data[i + 1].min_value"
              style="width: 50px!important"
              :error-messages="
                (item.min_value < getMin(i) || item.min_value > getMax(i)) &&
                i !== 0 && i !== updateForm.data.length - 1
                  ? 'wrong range value'
                  : ''
              "
            />
          </div>

          <v-text-field
            v-model="item.name"
            flat
            solo
            label="Name"
            hide-details
            style="font-size: 14px; width: 150px"
          ></v-text-field>
          <v-btn @click="deleteRange(item)" height="40" min-height="0" min-width="0" text width="40"
            ><v-icon>mdi-minus-box-outline</v-icon></v-btn
          >
          <v-btn @click="addLegendRange(i + 1)" height="40" min-height="0" min-width="0" text width="40"
            ><v-icon>mdi-plus-box-outline </v-icon></v-btn
          >
        </div>
      </v-layout>
      <div class="d-flex">
        <v-btn @click="undo"><v-icon> mdi-undo-variant</v-icon></v-btn>

        <div class="ml-auto">
          <v-btn @click="cancelEditLegend" style="color: red;" class="ml-auto" variant="text">Cancel</v-btn>
          <v-btn style="color: green;" @click="applyEditLegend" variant="text">
            Submit
          </v-btn>
        </div>
      </div>
    </v-layout>
  </v-dialog>
</template>
<script>
import { min } from 'moment'
import { updateCustomizeIndices } from '@/api/monitoring-api'
export default {
  components: {},
  data() {
    return {
      dialog: false,
      updateForm: {},
      dragging: false,
      startX: 0,
      indexDrag: null,
      history: [],
      historyIndex: 0,
      loading: false,
    }
  },
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  watch: {
    'updateForm.data'(val) {
      val.forEach(item => {
        item.min_value = Number(item.min_value.toFixed(2))
      })
    },
  },
  methods: {
    startDrag(index, event) {
      event.preventDefault()
      this.indexDrag = index
      if (index == this.updateForm.data.length - 1) return
      this.dragging = true
      this.startX = event.clientX
    },
    drag(event) {
      if (!this.dragging) return
      let dx = parseFloat(
        ((event.clientX - this.startX) /
          (this.$refs.bar.offsetWidth - this.$refs[`barItem${this.updateForm.data.length - 1}`][0].offsetWidth)) *
          (this.updateForm.data[this.updateForm.data.length - 1].min_value - this.updateForm.data[0].min_value),
      )
      if (
        this.updateForm.data[this.indexDrag].min_value + dx > this.updateForm.data[this.indexDrag + 1].min_value ||
        this.updateForm.data[this.indexDrag].min_value + dx < this.updateForm.data[this.indexDrag - 1].min_value
      )
        return
      this.updateForm.data[this.indexDrag].min_value += dx

      this.startX = event.clientX
    },
    stopDrag(event) {
      this.dragging = false
      this.updateForm.data = this.updateForm.data.map(item => {
        return {
          ...item,
          min_value: Math.round(item.min_value * 100) / 100,
        }
      })
    },

    getBarWidth(index) {
      if (index === this.updateForm.data.length - 1) {
        return 10
      }
      return (
        ((this.updateForm.data[index + 1].min_value - this.updateForm.data[index].min_value) /
          (this.updateForm.data[this.updateForm.data.length - 1].min_value - this.updateForm.data[0].min_value)) *
        100
      )
    },
    getMin(index) {
      
      if (index === 0) return null
      return this.updateForm.data[index - 1].min_value
    },
    getMax(index) {
      if (index === this.updateForm.data.length - 1) return null

      return this.updateForm.data[index + 1].min_value
    },
    openDialog() {
      this.dialog = true
      if (this.type && Array.isArray(this.type.data)) {
        this.updateForm = JSON.parse(JSON.stringify(this.type))
        this.updateForm.data.sort((a, b) => a.min_value - b.min_value)
      } else {
        console.error('this.type.data is not an array or does not exist')
      }
    },

    addLegendRange(i) {
      this.history.push(JSON.parse(JSON.stringify(this.updateForm.data)))
      this.historyIndex++
      if (i == this.updateForm.data.length) {
        i = i - 1
      }
      const index = i

      let lastItem = this.updateForm.data[index]
      let newItem = {
        color: '#FFFFFF',
        value: '',
        name: '',
        area: '',
        min_value: lastItem.min_value,
      }

      this.updateForm.data.splice(index, 0, newItem)
      this.updateForm.data[index + 1].min_value = this.updateForm.data[index + 1].min_value + 0.1
      for (let i = index + 2; i < this.updateForm.data.length; i++) {
        if (this.updateForm.data[i].min_value <= this.updateForm.data[i - 1].min_value) {
          this.updateForm.data[i].min_value = this.updateForm.data[i - 1].min_value + 0.1
        } else {
          break
        }
      }
      this.indexDrag = index + 1
    },
    cancelEditLegend() {
      this.dialog = false
      this.updateForm = JSON.parse(JSON.stringify(this.type))
      this.updateForm.data.sort((a, b) => a.min_value - b.min_value)
    },
    deleteRange(item) {
      this.history.push(JSON.parse(JSON.stringify(this.updateForm.data)))
      this.historyIndex++
      this.updateForm.data = this.updateForm.data.filter(val => val !== item)
    },
    undo() {
      if (this.historyIndex > 0) {
        this.historyIndex--
        this.updateForm.data = this.history[this.historyIndex]

        this.history.pop()
      }
    },
    async applyEditLegend() {
      this.loading = true
      try {
        if (this.compareFields(this.updateForm.data, [...this.type.data].reverse())) {
          this.loading = false
          this.$store.commit('message/SHOW_SUCCESS', 'No change in legend')
          this.dialog = false
          return
        }
        this.updateForm.data.forEach(item => {
          item.value = this.updateForm.data[this.updateForm.data.indexOf(item) + 1]
            ? `${item.min_value} to ${this.updateForm.data[this.updateForm.data.indexOf(item) + 1].min_value}`
            : `${item.min_value} and above`
        })
        this.updateForm.data = this.updateForm.data.map(item => ({
          min_value: item.min_value,
          color: item.color,
          name: item.name,
          value: item.value,
        }))
        let param = {
          name: this.updateForm.name,
          data: [...this.updateForm.data].reverse(),
        }
        if (!this.formatRange(param.data, 'min_value')) {
          
          this.$store.commit('message/SHOW_ERROR', 'Range value is not correct')
          this.loading = false
          return
        }
        await updateCustomizeIndices(param)
        this.loading = false
        this.$emit('updateLegend')
        this.$emit('updateChartData')
       
        this.$store.commit('message/SHOW_SUCCESS', 'Update legend successfully')
        this.dialog = false
      } catch (error) {
        this.$store.commit('message/SHOW_ERROR', error.message)
        this.loading = false
      }
    },
    compareFields(data1, data2) {
      return data1.every((item, index) => {
        return (
          item.color === data2[index].color &&
          item.name === data2[index].name &&
          item.min_value === data2[index].min_value
        )
      })
    },
    formatRange(array, field) {
      return array.every((currentValue, index) => {
        return index === 0 || currentValue[field] <= array[index - 1][field]
      })
    },
  },
}
</script>

<style scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.list-container {
  display: flex;
  flex-direction: column;

  padding: 10px;
  height: 400px;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.list-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.bar-container {
  display: flex;
  height: 20px;
  margin: 10px;
}

.bar-item {
  height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: pointer;
  align-items: center;
}
.edge {
  position: absolute;
  width: 6px;
  height: 40px;
  top: -10px;
  z-index: 1;
  cursor: grab;
}

.left-edge {
  left: -3px;
}
.left-edge:hover {
  border: 1px solid black;
  background-color: rgb(54, 54, 54);
  border-radius: 10px;
}

.right-edge {
  right: 0;
}
</style>
