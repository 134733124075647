<template>
  <v-list class="pa-1" style="margin-left:33px;border-radius:10px" >
    <div v-for="(query, index) in item.filters" class="content">
      <v-row class="ml-1">
        <v-col
          cols="2"
          style=" display: flex;
              align-items: center;"
        >
          <div  v-if="index == 0">
            Where
          </div>
          <v-select
            v-if="index == 1"
            dense
            hide-details
            v-model="item.condition"
            :items="conditions"
            outlined
            return-object
            style="width:50px"
            @change="() => $emit('filter')"
          />
          <div class="ml-3" v-if="index > 1">
            {{ item.condition }}
          </div>
        </v-col>
        <v-col cols="10" >
          <FilterItem ref="filter" :filter="query" @filter="() => $emit('filter')" :index="index" @delete="index => deleteQuery(index)" />
        </v-col>
      </v-row>
    </div>
    <v-btn class="ma-1 " @click="addNewFilter()" plain>
      <v-icon small>mdi-plus</v-icon>
      <span class="ml-1 text-none">New Filter</span>
    </v-btn>
  </v-list>
</template>

<script>
import FilterItem from './FilterItem.vue'
export default {
  components: { FilterItem },
  props: {},
  watch: {},
  data() {
    return {
      conditions: ['and', 'or'],
    }
  },

  props: {
    item: {
      type: Object,
      default: [],
    },
    index: {
      type: Number,
      default: undefined,
    },
  },
  computed: {},
  methods: {
    addNewFilter() {
      this.item.filters.push({ column: undefined, operator: undefined, value: undefined })
    },
    deleteQuery(index) {
      this.item.filters.splice(index, 1)
      this.$emit('updateQueries',this.index,this.item)
    },
  },
}
</script>
<style scoped>
.filter-container {
  width: 100%;
}
</style>
