<template>
  <v-layout :disabled="disable" class="overflow-y-hidden overflow-x-hidden fill-height" column>
    <div style="flex: none; width: 100%; height: 50px; border-bottom: 1px solid #5e5669">
      <div class="d-flex align-center fill-height pr-1">
        <div>
          <v-text-field
            class="search-field"
            v-model="search"
            @input="debounceInput"
            append-icon="mdi-magnify"
            dense
            hide-details
            outlined
          >
            <template v-slot:label>
              <span class="custom-label">Search&nbsp;</span>
            </template>
          </v-text-field>
        </div>
        <v-spacer />
        <CustomMenu
          :items="sorts"
          :icon="'mdi-sort'"
          :currentItem.sync="sort"
          :tip="'Sort'"
          @sort-change="item => changeSort(item.code)"
        />
        <FilterComponent
          ref="filter"
          :filters="filters"
          @getAOI="getAOI()"
          @update:filter="newFilter => (filters = newFilter)"
        />
      </div>
    </div>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="!inGroup" class="list-groups d-flex flex-wrap">
      <div
        v-for="(group, ind) in groupsAOI"
        :key="ind"
        class="folder custom-card-bg-1 d-flex flex-column align-start"
        @click="$emit('openGroup', group)"
      >
        <v-icon size="22" class="mr-1">mdi-folder</v-icon>
        <TooltipBase :styles="{ fontSize: '14px', width: '100px' }" :title="group.name" />
        <span class="group-detail">{{ group.count | formatItem }}</span>
        <div class="group-actions">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon small>
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(menu, index) in menusGroup" :key="index" @click="menu.action(group)">
                <v-icon size="18" class="mr-3" v-if="menu.icon">{{ menu.icon }}</v-icon>
                <v-list-item-title> {{ menu.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
    <v-card class="d-flex custom-card-bg-0 pa-2" style="position: sticky; top: 0; z-index: 1">
      <span>AOI List</span>
      <v-spacer></v-spacer>
      <span>{{ (inGroup ? currentGroup.count : nullGroupCount) | formatItem }}</span>
    </v-card>
    <v-layout class="overflow-y-auto fill-height" column style="flex: 1">
      <AddingAoiOption v-if="isNoAois" @startDraw="() => $emit('startDraw')"></AddingAoiOption>
      <div>
        <div class="pb-12">
          <v-list-item
            v-for="(item, ind) in listAOIs"
            :key="ind"
            class="mt-1 pl-1 pr-0 custom-card-bg-1"
            @click="clickToItem(item)"
            @contextmenu="handleContextMenuItem(item.uuid, $event)"
          >
            <BorderArrow v-if="item.color" />
            <div class="d-flex align-center flex-grow-1">
              <div class="thumbnail d-flex align-center mr-2">
                <v-img
                  :lazy-src="item.thumbnail_url"
                  :src="item.thumbnail_url"
                  contain
                  max-height="100%"
                  max-width="100%"
                  style="border-radius: 4px"
                >
                  <template v-slot:placeholder>
                    <v-row align="center" class="fill-height ma-0" justify="center">
                      <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
              <v-layout class="fill-height" column>
                <TooltipBase :title="item.name" :styles="{ width: '126px' }" />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle class="subtitle-2 text-truncate" v-bind="attrs" v-on="on">
                      <div style="font-size: 10px" v-html="transformArea(item.area)"></div>
                    </v-list-item-subtitle>
                  </template>
                  <span v-html="' Area: ' + transformArea(item.area)"></span>
                </v-tooltip>
              </v-layout>
            </div>

            <v-list-item-action>
              <div class="d-flex justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon x-small v-bind="attrs" v-on="on" @click.native.stop="changeDisplayAOI(item)">
                      <v-icon :color="item.color" size="16">{{
                        isIndexMonitoring ? 'mdi-crosshairs-gps' : 'mdi-eye-outline'
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ isIndexMonitoring ? 'Fly to AOI' : 'View' }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="currentUser.permissions.includes('order.submit') && canOrder">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon x-small v-bind="attrs" v-on="on" @click.stop="() => $emit('makeOrder', item)">
                      <v-icon size="16">mdi-cart-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Submit order</span>
                </v-tooltip>
                <CustomMenu
                  :sizeIcon="18"
                  :ref="`actionmenu${item.uuid}`"
                  :items="actions"
                  :icon="'mdi-dots-vertical'"
                  :tip="'More'"
                  @sort-change="menu => selectAction(menu, item)"
                />
              </div>
            </v-list-item-action>
          </v-list-item>
          <infinite-loading
            v-if="!(!inGroup && listAOIs.length == 0) && isGetFirstPageAOI == false"
            @infinite="loadMoreAOI"
            ref="infiniteLoading"
          >
            <span slot="no-more"></span>
            <span slot="no-results"></span>
          </infinite-loading>
        </div>
      </div>
    </v-layout>

    <RenameDialog ref="renameDialog" @submit="updateGroup" />
    <ListOrder ref="listOrder" />
    <UpdateAOIDialog ref="updateAOIDialog" />
    <DeleteDialog ref="deleteDialog" :titleDialog="'Delete AOI'" :submit-delete="submitDelete" />
    <DeleteDialog ref="deleteGroupDialog" :titleDialog="'Delete group'" :submit-delete="deleteGroup" />
    <CreateOrder ref="createOrder" />
  </v-layout>
</template>

<script>
import { mapState } from '@/store/ults'
import DeleteDialog from '@/components/DeleteDialog.vue'
import { deleteAOI, getDetailAOI, updateGroupAOI, deleteGroupAOI } from '@/api/aoi-api'
import UpdateAOIDialog from '@/views/aoi/UpdateDialog.vue'
import BorderArrow from '@/components/BorderArrow.vue'
import ListOrder from '@/views/aoi/order/ListOrder.vue'
import AreaTransform from '@/utils/convertArea'
import { debounce } from 'lodash'
import CreateOrder from '@/views/createOrder/CreateOrder.vue'
import AddingAoiOption from '@/components/AddingAoiOption/index.vue'
import TooltipBase from '@/components/TooltipBase.vue'
import CustomMenu from '@/components/CustomMenu.vue'
import FilterComponent from '@/components/Filter/Index.vue'
import { checkGrammar } from '@/utils/stringHandle'
import RenameDialog from './RenameDialog.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'List-AOI',
  components: {
    CreateOrder,
    ListOrder,
    BorderArrow,
    UpdateAOIDialog,
    DeleteDialog,
    AddingAoiOption,
    TooltipBase,
    CustomMenu,
    FilterComponent,
    RenameDialog,
    InfiniteLoading,
  },
  props: {
    disable: { type: Boolean, default: false },
    currentGroup: { type: Object, default: () => ({}) },
    inGroup: { type: Boolean, default: false },
    canOrder: { type: Boolean, default: true },
    selectFirstAoi: { type: Boolean, default: false },
    isIndexMonitoring: { type: Boolean, default: false },
  },
  data() {
    return {
      menusGroup: [
        { name: 'Rename', icon: 'mdi-rename-box', action: group => this.$refs.renameDialog.open(group) },
        { name: 'Delete', icon: 'icon-trash', action: group => this.$refs.deleteGroupDialog.openDialog(group) },
      ],
      panel: 0,
      loading: false,
      isGetFirstPageAOI: false,
      currentAOI: {},
      search: '',
      sort: undefined,
      sorts: [
        {
          name: 'Newest',
          code: '-created_at',
          group: 'date',
        },
        {
          name: 'Oldest',
          code: 'created_at',
          group: 'date',
        },
        {
          name: 'Field Name: A - Z',
          code: 'name',
          group: 'name',
        },
        {
          name: 'Field Name: Z - A',
          code: '-name',
          group: 'name',
        },
        {
          name: 'Field area: low to high',
          code: 'area',
          group: 'area',
        },
        {
          name: 'Field area: high to low',
          code: '-area',
          group: 'area',
        },
      ],
      filters: {
        condition: 'and',
        filters: [],
      },
      actionsList: [
        {
          name: 'Show Detail',
          icon: 'mdi-map-search',
          available: this.isIndexMonitoring,
        },
        {
          name: 'Zoom To',
          icon: 'mdi-crosshairs-gps',
          available: true,
        },
        {
          name: 'Submit New Order',
          group: 'order',
          code: 'order.submit',
          icon: 'mdi-cart-outline',
          available: this.canOrder,
        },
        {
          name: 'Show Order List',
          group: 'order',
          icon: 'mdi-order-alphabetical-ascending',
          available: this.canOrder,
        },
        {
          name: 'Submit Tasking Order',
          group: 'tasking',
          code: 'tasking_order.submit',
          icon: 'mdi-image-plus-outline',
          available: this.canOrder,
        },
        {
          name: 'Change Name',
          group: 'crud',
          code: 'aoi.update',
          icon: 'mdi-rename-box',
          available: true,
        },
        {
          name: 'Change Group',
          group: 'crud',
          code: 'aoi.update',
          icon: 'mdi-group',
          available: true,
        },
        {
          name: 'Download AOI',
          group: 'crud',
          icon: 'mdi-download',
          available: true,
        },
        {
          name: 'Delete AOI',
          code: 'aoi.delete',
          group: 'delete',
          icon: 'mdi-delete',
          available: true,
        },
      ],
    }
  },
  computed: {
    ...mapState('AOI', ['listAOI', 'AOIs', 'nullGroupCount', 'nullGroupAOIs']),
    ...mapState('auth', ['currentUser']),
    groupsAOI() {
      return this.$store.getters['AOI/groupsAOI']
    },
    actions() {
      return this.actionsList.filter(
        action => (!action.code || this.currentUser.permissions.includes(action.code)) && action.available,
      )
    },
    listAOIsGroup() {
      return this.currentGroup.items
    },
    listAOIsWithoutGroup() {
      return this.nullGroupAOIs
    },
    listAOIs() {
      return this.inGroup ? this.currentGroup.items : this.listAOIsWithoutGroup
    },
    isNoAois() {
      return this.listAOIs.length == 0 && (Object.keys(this.filters) == 0 || this.filters.filters.length == 0)
    },
  },
  watch: {
    currentGroup() {
      this.$nextTick(() => {
        if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.stateChanger.reset()
      })
    },
  },
  async mounted() {
    this.getAOI()
  },
  methods: {
    async loadMoreAOI($state) {
      const res = await this.$store.dispatch('AOI/getMoreAOI', {
        projectId: this.$route.params.id,
        payload: {
          service_name: this.$route.meta?.service,
          search: this.search,
          sort: this.sort,
          group: this.inGroup ? this.currentGroup.name : '',
          filters: this.filters.filters.length > 0 ? JSON.stringify(this.filters) : undefined,
        },
      })
      if (res.length > 0) {
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    async updateGroup({ oldGroup, newGroup }) {
      try {
        const payload = {
          projectId: this.$route.params.id,
          name: oldGroup.name,
          params: {
            new_name: newGroup.name,
          },
        }
        await updateGroupAOI(payload)
        this.$refs.renameDialog.close()
        await this.getAOI()
      } catch (e) {
        console.warn('Error when update group', e)
      } finally {
        this.$refs.renameDialog.loading = false
      }
    },
    async deleteGroup(group) {
      try {
        await deleteGroupAOI({ projectId: this.$route.params.id, name: group.name })
        // await this.getAOI()
      } catch (e) {
        console.warn('Error when delete group', e)
      }
    },
    clickToItem(item) {
      if (this.isIndexMonitoring) {
        this.$emit('selectField', item)
      } else {
        this.changeDisplayAOI(item)
      }
    },
    /// ---------------------------------

    selectAction(item, aoi) {
      switch (item.name) {
        case 'Show Detail':
          this.clickToItem(aoi)
          break
        case 'Zoom To':
          this.changeDisplayAOI(aoi)
          break
        case 'Submit New Order':
          this.$emit('makeOrder', aoi)
          break
        case 'Show Order List':
          this.$refs.listOrder.openDialog(aoi)
          break
        case 'Submit Tasking Order':
          this.addToTasking(aoi)
          break
        case 'Download AOI':
          this.downloadAOI(aoi)
          break
        case 'Delete AOI':
          this.$refs.deleteDialog.openDialog(aoi)
          break
        case 'Change Name':
          this.updateInfo(aoi, 'name')
          break
        case 'Change Group':
          this.updateInfo(aoi, 'group')
          break
      }
    },
    debounceInput: debounce(function () {
      this.getAOI()
    }, 500),
    changeSort(sort) {
      if (this.sort === sort) this.sort = undefined
      else this.sort = sort
      this.getAOI()
    },
    async getAOI() {
      try {
        this.loading = true
        this.isGetFirstPageAOI = true
        this.$store.dispatch('AOI/getAllGroup', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta?.service },
        })
        await this.$store.dispatch('AOI/getListAOI', {
          isLazy: this.selectFirstAoi ? false : true,
          projectId: this.$route.params.id,
          payload: {
            service_name: this.$route.meta?.service,
            search: this.search,
            sort: this.sort,
            group: '',
            filters: this.filters.filters.length > 0 ? JSON.stringify(this.filters) : undefined,
          },
        })
        if (!this.currentGroup) this.$emit('backToHome')
        if (this.selectFirstAoi && this.AOIs.length > 0) {
          this.$emit('selectField', this.AOIs[0])
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.isGetFirstPageAOI = false
        this.loading = false
      }
    },
    handleContextMenuItem(uuid, event) {
      event.preventDefault()
      const actionMenuRefs = Object.keys(this.$refs).filter(refName => refName.startsWith('actionmenu'))
      for (const refName of actionMenuRefs) {
        if (this.$refs[refName][0]) this.$refs[refName][0].closeMenu()
      }
      let actionMenu = 'actionmenu' + uuid
      this.$refs[actionMenu][0].openMenu()
    },
    async addToTasking(AOI) {
      let data = await this.getDetailAOI(AOI)
      await this.$refs.createOrder.openDialog({
        geojson: data,
        dataType: 'aoi',
        name: AOI.name,
        id: AOI.uuid,
      })
    },
    async downloadAOI(AOI) {
      let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(await this.getDetailAOI(AOI)))
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', AOI.name + '.geojson')
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
    updateInfo(AOI, type) {
      this.$refs.updateAOIDialog.openDialog(AOI, type)
    },
    async getDetailAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    changeDisplayAOI(item) {
      if (!item.display) {
        this.currentAOI.display = false
        this.currentAOI.color = undefined
        this.currentAOI = item
        this.$emit('addAOIToMap', item)
        this.$emit('zoomTo', item.bbox)
        this.currentAOI.color = '#16B1FF'
      } else {
        this.$emit('removeAOI')
        this.currentAOI = {}
        item.display = false
        item.color = undefined
      }
      let tmpAOIs = [...this.listAOI]
      this.listAOI = undefined
      this.listAOI = tmpAOIs
    },
    transformArea(area) {
      if (!area) return ''
      return AreaTransform.transformUnit(area)
    },
    async submitDelete(item) {
      try {
        this.loading = true
        await deleteAOI({ projectId: this.$route.params.id, id: item.uuid })
        // await this.getAOI()
        if (this.currentAOI.uuid === item.uuid) this.changeDisplayAOI(item)
      } catch (e) {
        console.warn('Error when delete AOI', e)
      } finally {
        this.loading = false
      }
    },
  },
  filters: {
    formatItem(value) {
      return checkGrammar(value, 'item')
    },
  },
}
</script>

<style scoped lang="scss">
$thumbnailSize: 36px;

.list-groups {
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
  gap: 8px;

  .folder {
    position: relative;
    width: calc(50% - 5px);
    height: 70px;
    border-radius: 8px;
    padding: 8px 12px;

    &:hover {
      cursor: pointer;
      background-color: #ffffff13 !important;
    }

    .group-detail {
      font-size: 12px;
    }

    .group-actions {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.thumbnail {
  width: $thumbnailSize;
  height: $thumbnailSize;
  min-width: $thumbnailSize;
}

::v-deep {
  &.v-text-field--outlined.v-input--dense .v-label:not(.v-label--active) {
    font-size: 14px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 36px !important;
    padding-right: 8px !important;
  }

  &.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
    margin-top: 6px !important;
  }

  &.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 38px;
  }

  &.v-expansion-panel .v-expansion-panel-header {
    padding: 0 16px !important;
  }

  &.v-expansion-panel .v-expansion-panel-content__wrap {
    padding: 0 4px !important;
  }

  &.v-expansion-panel-header {
    font-size: 0.9rem;
  }

  &.v-list-item__action {
    margin-left: 0 !important;
  }

  &.v-list-item,
  .v-list-item::before {
    border-radius: 6px;
  }
}
</style>
