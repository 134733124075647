<template>
  <v-layout class="fill-height" column>
    <div class="px-2" style="flex: none; width: 100%; height: 50px; border-bottom: 1px solid #5e5669">
      <v-layout align-center class="fill-height">
        <H4>{{ currentField || selectFirstAoi ? 'AOI information' : 'AOIs' }}</H4>
        <v-spacer />

        <v-tooltip v-if="currentField && !selectFirstAoi" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="backToListField" v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-left-circle</v-icon>
            </v-btn>
          </template>
          <span>Back to list field</span>
        </v-tooltip>
      </v-layout>
    </div>
    <v-card :disabled="drawing" class="custom-card-bg-0 px-2" max-height="280" width="100%">
      <v-layout class="fill-height" column>
        <div ref="AOI" v-if="selectFirstAoi" class="px-1" style="height: 60px; flex: none;">
          <v-layout align-center class="fill-height">
            <v-autocomplete
              v-model="currentField"
              :items="AOIs"
              dense
              hide-details
              item-text="name"
              label="Field"
              outlined
              placeholder="Field"
              return-object
              @change="selectField($event)"
            ></v-autocomplete>
          </v-layout>
        </div>
        <div
          v-if="$route.name == 'thermal-index' || $route.name == 'biodiversity' || $route.name == 'soil-moisture'"
          class="px-1"
          style="height: 60px; flex: none;"
          ref="frequency"
        >
          <v-layout align-center class="fill-height">
            <v-autocomplete
              v-model="frequency"
              :items="frequencies"
              dense
              clearable
              hide-details
              label="Frequency"
              outlined
              placeholder="Frequency"
              @change="getChartData(currentField)"
            ></v-autocomplete>
          </v-layout>
        </div>
        <v-divider />
        <v-layout v-if="currentField" class="pa-2" column justify-center style="flex: 1; height: 50px;">
          <v-layout>
            <span class="text-truncate" style="font-size: 16px; align-items: center">{{ currentField.name }}</span>
            <v-spacer />
            <v-tooltip color="#000000" left>
              <template v-slot:activator="{ on, attrs }" v-on="on">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.native.stop="zoomToField(currentField)">
                      <v-icon size="22">icon-gps</v-icon>
                    </v-btn>
                  </template>
                  <span>Zoom to</span>
                </v-tooltip>
              </template>
              <span>Fly to</span>
            </v-tooltip>
            <v-menu v-if="currentField && !sync" offset-y top>
              <template #activator="{ on: onMenu }">
                <v-tooltip bottom>
                  <template #activator="{ on: onTooltip }">
                    <v-btn icon small v-on="{ ...onMenu, ...onTooltip }">
                      <v-icon size="19">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download AOI</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <div class="px-2 subtitle-2">
                  Download
                </div>
                <v-divider />
                <v-list-item @click="() => $emit('download', 'tif')">
                  <v-list-item-title class="caption">{{ typeIndex.name }}.tiff</v-list-item-title>
                </v-list-item>
                <v-list-item @click="() => $emit('download', 'csv')">
                  <v-list-item-title class="caption">
                    {{ currentField.name + '_' + typeIndex.name }}.csv
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="() => $emit('download', 'contours')">
                  <v-list-item-title class="caption">{{ currentField.name }}.geojson </v-list-item-title>
                </v-list-item>
                <v-list-item @click="() => $emit('download', 'qml')">
                  <v-list-item-title class="caption">
                    QGIS_style.qml
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
          <v-spacer />
          <v-layout align-center>
            <span
              class="pr-2"
              style="color:#79899A; font-size: 13px"
              v-html="'Area: ' + transformArea(currentField.area)"
            ></span>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-card>
    <v-divider />

    <v-layout
      v-show="(currentField || selectFirstAoi) && group.id === 'dashboard'"
      column
      style="height: calc(100% - 300px)"
    >
      <v-layout column style="overflow-y: auto; max-height: calc(100vh - 250px)">
        <div class="fill-height" style="width: 100%; flex: none">
          <v-layout v-if="currentField" class="fill-height" column>
            <div
              v-if="currentField.information && !selectFirstAoi && $route.name == 'vegetation-indices'"
              style="flex: none; height: 60px; border-bottom: 2px solid black"
            >
              <v-layout align-center class="fill-height px-2">
                Crop rotation
                <v-tooltip color="#000000e4" max-width="500" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    Keep record of a crop rotation history and plan the future sowing operations to maintain soil
                    fertility, thus reducing the risks of plant diseases and pests. You can manage the field’s crop
                    rotation in its editing settings.
                  </span>
                </v-tooltip>
                <v-spacer />
                <a
                  style="text-decoration: underline; font-size: 14px"
                  @click="() => $refs.createSeason.openDialog(currentField)"
                  >Add Season</a
                >
              </v-layout>
            </div>
            <div v-for="(season, i) in seasons" :key="i" class="mt-2" style="width: 100%">
              <v-layout class="pa-2" column>
                <v-layout align-center>
                  <span style="font-size: 14px">Season: {{ season.name }}</span>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="() => $refs.updateSeason.openDialog(currentField, season)"
                      >
                        <v-icon size="20">mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="() => $refs.deleteDialog.openDialog(season)"
                      >
                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-layout>
                <div class="mt-3 pa-2" style="width: 100%; border: 1px solid rgba(98,98,98,0.37); border-radius: 6px">
                  <v-expansion-panels class="custom-expansion-v2">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">
                        <v-layout>
                          <v-layout class="text-truncate pr-1" column style="width: 100px">
                            <span style="color: #595dfc;">Crop name</span>
                            <div class="pt-2 text-truncate" style="max-width: 100px">
                              {{ season.crop_type }}
                            </div>
                          </v-layout>
                          <v-layout class="text-truncate pr-1" column>
                            <span style="color: #595dfc">Sowing date</span>
                            <div class="pt-2">{{ season.sowing_date }}</div>
                          </v-layout>
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="px-0">
                        <v-layout style="font-size: 15px">
                          <v-layout class="text-truncate pr-1" column style="width: 100px">
                            <span style="color: #595dfc">Maturity</span>
                            <div class="pt-2">{{ season.maturity ? season.maturity : 'Default' }}</div>
                          </v-layout>
                          <v-layout class="text-truncate pr-1" column>
                            <span style="color: #595dfc">Harvesting date</span>
                            <div class="pt-2">{{ season.harvesting_date }}</div>
                          </v-layout>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-layout>
            </div>
            <div v-if="currentField.weather" class="pa-2" style="height: 40px; width: 100%">
              <v-layout class="fill-height">
                Current weather
                <v-spacer />
                <v-btn icon x-small @click="showWeather = !showWeather">
                  <v-icon v-if="!showWeather">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <div v-if="currentField.weather" class="pa-2 pl-4" style="font-size: 18px">
              <div class="d-flex " style="align-items:center">
                <span class="mr-4">{{ dateFormat(new Date()) }}</span>
                <span style="font-size: 16px;"
                  >{{ new Date().getHours() }}:00 - {{ new Date().getHours() + 1 }}:00</span
                >
              </div>
              <span style="font-size: 36px">{{ currentField.weather.temp }}°C</span>
            </div>
            <div v-if="showWeather && currentField.weather" style="height: 160px; border-top: 2px solid black">
              <v-layout v-if="showWeather" class="pl-4 py-2" style="height: 80px">
                <v-layout align-start column justify-center style="flex: 1;">
                  <span class="pb-2" style="color: var(--v-button-base)">
                    Wind
                  </span>
                  <span>
                    {{ currentField.weather.wind }} m/s
                    <v-icon
                      :style="{ transform: 'rotate(' + (90 - currentField.weather.wind_degree) + 'deg)' }"
                      class="ml-0"
                      size="16"
                    >
                      mdi-navigation
                    </v-icon>
                  </span>
                </v-layout>
                <v-layout align-start column justify-center style="flex: 1;">
                  <span class="pb-2" style="color: var(--v-button-base)">Humidity</span>
                  <span>
                    {{ currentField.weather.humidity }} %
                    <v-icon class="ml-0" size="16">
                      mdi-water
                    </v-icon>
                  </span>
                </v-layout>
              </v-layout>
              <v-layout v-if="showWeather" align-center class="pl-4" style="height: 80px;">
                <v-layout align-start column justify-center style="flex: 1;">
                  <span style="color: var(--v-button-base)">Precipitation</span>
                  {{ currentField.weather.precipitation }} mm
                </v-layout>
                <v-layout align-start column justify-center style="flex: 1;">
                  <span class="pb-2" style="color: var(--v-button-base)">Clouds</span>
                  <span>
                    {{ currentField.weather.cloud }} %
                    <v-icon class="ml-0" size="16">
                      mdi-cloud
                    </v-icon>
                  </span>
                </v-layout>
              </v-layout>
            </div>
          </v-layout>
        </div>
      </v-layout>
    </v-layout>
    <Create ref="createSeason" @fetchSeason="getSeason(currentField.uuid)" />
    <Update ref="updateSeason" @fetchSeason="getSeason(currentField.uuid)" />
    <DeleteDialog ref="deleteDialog" :submit-delete="submitDelete" />
  </v-layout>
</template>

<script>
import AddingAoiOption from '@/components/AddingAoiOption/index.vue'
import date from '@/utils/convertDate'
import InfiniteLoading from 'vue-infinite-loading'
import MapPreview from '@/components/MapPreview.vue'
import { debounce } from 'lodash'
import AreaTransform from '@/utils/convertArea'
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import SelectIndex from '@/components/SelectIndex'
import DatePicker from '@/components/DatePicker'
import { getOrder } from '@/api/order'
import { deleteAOI, deleteSeason, getDetailAOI, getSeason } from '@/api/aoi-api'
import { getImages } from '@/api/monitoring-api'
import BorderArrow from '@/components/BorderArrow.vue'
import Status from '@/utils/status'
import Map from '@/components/Map.vue'
import Create from '@/views/aoi/monitoring/season/Create.vue'
import Update from '@/views/aoi/monitoring/season/Update.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import UploadAOI from '@/views/aoi/UploadAOI.vue'
import UpdateAOIDialog from '@/views/aoi/UpdateDialog.vue'
import CustomMenu from '@/components/CustomMenu.vue'

export default {
  name: 'FieldControl',
  components: {
    UploadAOI,
    CustomIcon,
    DeleteDialog,
    Update,
    Create,
    Map,
    BorderArrow,
    DatePicker,
    SelectIndex,
    MapPreview,
    InfiniteLoading,
    AddingAoiOption,
    UpdateAOIDialog,
    CustomMenu,
  },
  data() {
    return {
      currentAOI: {},
      seasons: [],
      sources: [],
      layers: [],
      images: [],
      statusUpdate: false,
      menu: false,
      state: undefined,
      page: 1,
      search: '',
      infiniteIndex: 1,
      desc: true,
      sort: undefined,
      frequency: undefined,
      frequencies: [],
      cacheFields: [],
      orders: [],
      sorts: [
        {
          name: 'Newest',
          code: '-created_at',
          group: 'date',
        },
        {
          name: 'Oldest',
          code: 'created_at',
          group: 'date',
        },
        {
          name: 'Field Name: A - Z',
          code: 'name',
          group: 'name',
        },
        {
          name: 'Field Name: Z - A',
          code: '-name',
          group: 'name',
        },
        {
          name: 'Field area: low to high',
          code: 'area',
          group: 'area',
        },
        {
          name: 'Field area: high to low',
          code: '-area',
          group: 'area',
        },
      ],
      actionsList: [
        { name: 'Zoom To', icon: 'mdi-crosshairs-gps' },
        {
          name: 'Change Name',
          group: 'crud',
          code: 'aoi.update',
          icon: 'mdi-rename-box',
        },
        {
          name: 'Change Group',
          group: 'crud',
          code: 'aoi.update',
          icon: 'mdi-group',
        },

        {
          name: 'Download AOI',
          group: 'crud',
          icon: 'mdi-download',
        },
        {
          name: 'Delete AOI',
          code: 'aoi.delete',
          group: 'delete',
          icon: 'mdi-delete',
        },
      ],

      router: 'Fields',
      showDetail: false,
      showWeather: true,
      showMoreSeason: false,
    }
  },
  props: {
    selectFirstAoi: { type: Boolean, default: false },
    isDraw: { type: Boolean, default: false },
    drawing: { type: Boolean, default: false },
    listField: {
      type: Array,
      default: () => [],
    },
    cloudPercent: {
      type: Array,
      default: () => [0, 100],
    },
    loading: { type: Boolean, default: false },
    field: {},
    dates: { type: Array, default: () => [] },
    index: { type: String, default: '' },
    typeIndex: {},
    legendMenu: {},
    mapLegend: { type: Array, default: () => [] },
    customLegend: {},
    sync: {},
  },
  async mounted() {
    await sleep(500)
    this.$nextTick(() => {
      if (this.selectFirstAoi) {
        let guideData = {
          frequencies: this.frequencies,
          aoiRef: this.$refs.AOI,
          frequencyRef: this.$refs.frequency,
        }
        this.$emit('startGuide', guideData)
      }
    })
    // this.getAOI()
  },
  computed: {
    ...mapState('tab', ['tabs']),
    ...mapState('menu', ['group']),
    ...mapState('AOI', ['listAOI', 'groups', 'AOIs']),
    ...mapState('service', ['services', 'firstLoad', 'source']),
    ...mapState('auth', ['currentUser']),

    actions() {
      return this.actionsList.filter(action => !action.code || this.currentUser.permissions.includes(action.code))
    },
    legends: {
      get() {
        return this.customLegend
      },
      set(val) {
        this.$emit('update:customLegend', val)
      },
    },
    fields: {
      get() {
        return this.listField
      },
      set(val) {
        this.$emit('update:listField', val)
      },
    },
    currentField: {
      get() {
        return this.field
      },
      set(val) {
        this.$emit('update:field', val)
      },
    },
    isLoading: {
      get() {
        return this.loading
      },
      set(val) {
        this.$emit('update:loading', val)
      },
    },
  },
  watch: {
    index(newVal, oldVal) {
      if (oldVal) this.getChartData(this.currentField)
    },
  },
  methods: {
    dateFormat(datetime) {
      return date.dateFormatted(datetime)
    },

    updateInfo(AOI, type) {
      this.$refs.updateAOIDialog.openDialog(AOI, type)
    },

    changeSort(sort) {
      if (this.sort === sort) this.sort = undefined
      else this.sort = sort
      this.getAOI()
    },
    async downloadAOI(AOI) {
      this.isLoading = true
      let res = await getDetailAOI({
        projectId: this.$route.params.id,
        id: AOI.uuid,
        payload: { weather: true },
      })
      res.data.featureCollection = {
        type: 'FeatureCollection',
        name: 'field',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: res.data.geojson,
          },
        ],
      }
      this.isLoading = false
      let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(res.data))
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', AOI.name + '.geojson')
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },

    async submitDelete(season) {
      try {
        this.isLoading = true
        await deleteSeason({
          id: this.currentField.uuid,
          seasonId: season.id,
          projectId: this.$route.params.id,
        })
        await this.getSeason(this.currentField.uuid)
      } catch (e) {
      } finally {
        this.isLoading = false
      }
    },
    transformArea(area) {
      const transformedArea = AreaTransform.transformUnit(area)
      const unit = transformedArea.split(' ')[1]
      if (unit === 'm<sup>2</sup>') this.$emit('areaUnit', 'Square meter')
      else if (unit === 'ha') this.$emit('areaUnit', 'Hectare')
      else if (unit === 'km<sup>2</sup>') this.$emit('areaUnit', 'Square kilometer')
      return transformedArea
    },

    async getService(aoi) {
      try {
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: aoi.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.orders = res.data
        this.sources = res.data.map(val => val.image_source)
        this.$store.commit('service/SET_SOURCES', this.sources)
        if (
          this.$route.name == 'biodiversity' ||
          this.$route.name == 'thermal-index' ||
          this.$route.name == 'soil-moisture'
        ) {
          this.frequencies = []
          res.data.map(val => {
            if (!this.frequencies.includes(val.frequency)) {
              this.frequencies.push(val.frequency)
            }
          })
          this.frequency = this.frequencies[0]
        }
      } catch (e) {
        console.warn('Error when get service', e)
      }
    },
    zoomToField(field) {
      this.$emit('zoomToField', field.bbox)
    },
    async backToListField() {
      this.fields = []
      this.page = 1
      this.$emit('destroyChart')
      this.$emit('closeColorPicker')
      this.router = 'Fields'
      this.currentField = undefined
      if (this.sync) this.$emit('changeStatusMap')
      this.$emit('mapSize')
      this.$emit('showLayerControl')
    },
    async selectField(field) {
      // if (field.display) this.changeDisplayAOI(field)
      await this.getService(field)
      this.currentField = field
      await this.getDetailAOI(field.uuid)
      await this.getSeason(field.uuid)
      this.$emit('showLayerControl')
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    async getSeason(fieldId) {
      try {
        this.seasons = []
        let res = await getSeason({
          projectId: this.$route.params.id,
          id: fieldId,
          payload: {
            sort: '-sowing_date',
            per_page: 'all',
          },
        })
        this.seasons = res.data
      } catch (e) {
      } finally {
      }
    },
    async getDetailAOI(fieldId) {
      try {
        let cacheField = this.cacheFields.find(cacheField => cacheField.field.uuid == fieldId)
        if (!cacheField) {
          let res = await getDetailAOI({
            projectId: this.$route.params.id,
            id: fieldId,
            payload: { weather: true },
          })

          res.data.layerId = 'aoi_' + res.data.id
          res.data.featureCollection = {
            type: 'FeatureCollection',
            name: 'field',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: res.data.geojson,
              },
            ],
          }

          res.data.bbox = this.currentField.bbox
          this.currentField = { ...res.data }
          this.$emit('removeAll')
          this.router = 'Field'
          await this.getChartData(res.data)
          await sleep(200)
          this.zoomToField(res.data)
        } else {
          this.currentField = { ...cacheField.field }
          this.$emit('removeAll')
          this.router = 'Field'
          await this.getChartData(cacheField.field)
          await sleep(200)
          this.zoomToField(cacheField.field)
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    getMinMaxDate(fieldName, localFieldName) {
      let minDate = null
      let maxDate = null
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/
      this.orders.forEach(order => {
        if (order[fieldName] === this[localFieldName] && order.lifetime == false) {
          if (dateRegex.test(order.from_date) && dateRegex.test(order.to_date)) {
            const fromDate = new Date(order.from_date)
            const toDate = new Date(order.to_date)

            if (!minDate || fromDate < minDate) {
              minDate = fromDate
            }
            if (!maxDate || toDate > maxDate) {
              maxDate = toDate
            }
          }
        }
      })
      return [
        minDate ? minDate.toISOString().substring(0, 10) : date.getBeforeDate(),
        maxDate ? maxDate.toISOString().substring(0, 10) : date.getCurrentDate(),
      ]
    },
    async getChartData(field, isFilter = false) {
      if (!this.frequency && !isFilter) {
        let newDate = await this.getMinMaxDate('image_source', 'source')
        if (JSON.stringify(this.dates) !== JSON.stringify(newDate)) {
          this.$emit('update:dates', newDate)
          return
        }
      }
      if (!isFilter) {
        if (this.frequency && this.selectFirstAoi) {
          let newDate = await this.getMinMaxDate('frequency', 'frequency')
          if (JSON.stringify(this.dates) !== JSON.stringify(newDate)) {
            this.$emit('update:dates', newDate)
            return
          }
        }
      }
      let cacheField = this.cacheFields.find(
        cacheField =>
          cacheField.field.uuid === field?.uuid &&
          cacheField.index === this.index &&
          cacheField.dateTime[0] === this.dates[0] &&
          cacheField.dateTime[1] === this.dates[1] &&
          cacheField.source === this.source &&
          JSON.stringify(cacheField.cloud) === JSON.stringify(this.cloudPercent),
      )
      await sleep(200)
      if (!cacheField) {
        try {
          this.isLoading = true
          const res = await getImages({
            id: field.uuid,
            projectId: this.$route.params.id,
            payload: {
              source: this.source,
              per_page: 'all',
              from_date: this.dates[0],
              frequency: this.frequency,
              to_date: this.dates[1],
              index: this.index,
              cloud: this.selectFirstAoi ? undefined : this.cloudPercent,
              service_name: this.$route.meta.service,
              sort: 'acquired_date',
            },
          })
          if (!res.data.length) this.$store.commit('message/SHOW_ERROR', 'No data available')
          this.images = res.data
          this.cacheFields.push({
            field: field,
            images: res.data,
            index: this.index,
            source: this.source,
            cloud: this.cloudPercent,
            dateTime: [this.dates[0], this.dates[1]],
          })
          this.$emit('displayChartData', res.data)
        } catch (e) {
        } finally {
          this.isLoading = false
        }
      } else {
        this.$emit('displayChartData', cacheField.images)
      }
    },
    changeVisibility(month) {
      // this.layers.forEach(layer => {
      //   this.$refs.mapPreview.changeVisibility(layer.id, 'none')
      // })
      // let layer = this.layers.find(layer => layer.dataId === month.id)
      // this.$refs.mapPreview.changeVisibility(layer.id, 'visible')
    },
  },
}
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 4px !important;
}
.thumbnail {
  width: 50px;
  height: 50px;
  background-color: #f5f5f5;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
