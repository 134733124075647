<template>
  <v-layout class="fill-height" column>
    <div class="px-2" style="flex: none; width: 100%; height: 50px; border-bottom: 1px solid #5e5669">
      <v-layout align-center class="fill-height">
        <H4>{{ currentField || selectFirstAoi ? 'AOI information' : 'AOIs' }}</H4>
        <v-spacer />
        <v-menu
          v-if="!currentField && isDraw && !selectFirstAoi && currentUser.permissions.includes('aoi.create')"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list dense min-width="160">
            <v-list-item dense @click="() => ($refs.uploadAOI.uploadDialog = true)">
              <v-list-item-icon>
                <v-icon small>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Upload AOI
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="() => $emit('openDrawTool')">
              <v-list-item-icon>
                <v-icon small>mdi-vector-square-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Draw AOI
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense @click="() => $emit('openDetect')">
              <v-list-item-icon>
                <v-icon small>mdi-auto-fix</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Auto detect
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip v-if="currentField && !selectFirstAoi" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="backToListField" v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-left-circle</v-icon>
            </v-btn>
          </template>
          <span>Back to list field</span>
        </v-tooltip>
      </v-layout>
    </div>
    <v-card :disabled="drawing" class="custom-card-bg-0 px-2" max-height="280" width="100%">
      <v-layout class="fill-height" column>
        <div v-if="selectFirstAoi" class="px-1" style="height: 60px; flex: none;">
          <v-layout align-center class="fill-height">
            <v-autocomplete
              v-model="currentField"
              :items="AOIs"
              dense
              hide-details
              item-text="name"
              label="Field"
              outlined
              placeholder="Field"
              return-object
              @change="selectField($event)"
            ></v-autocomplete>
          </v-layout>
        </div>

        <div v-if="currentField || selectFirstAoi" class="px-1" style="height: 60px; flex: none;">
          <v-layout align-center class="fill-height">
            <v-autocomplete
              v-model="source"
              :items="sources"
              dense
              hide-details
              label="Source"
              outlined
              placeholder="Source"
              @change="getChartData(currentField)"
            ></v-autocomplete>
          </v-layout>
        </div>
        <div v-else style="flex: none; width: 100%; height: 60px; border-bottom: 1px solid #5e5669">
          <v-layout align-center class="fill-height pr-1">
            <div style="width: 340px">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                dense
                hide-details
                label="Search"
                rounded
                solo
                @input="debounceInput"
              ></v-text-field>
            </div>
            <v-spacer />
            <CustomMenu
              :items="sorts"
              :icon="'mdi-sort'"
              :currentItem.sync="sort"
              :tip="'Sort'"
              @sort-change="item => changeSort(item.code)"
            />
            <FilterComponent
              ref="filter"
              :filters="filters"
              @getAOI="getAOI()"
              @update:filter="newFilter => (filters = newFilter)"
            />
          </v-layout>
        </div>
        <div
          v-if="$route.name == 'thermal-index' || $route.name == 'biodiversity' || $route.name == 'soil-moisture'"
          class="px-1"
          style="height: 60px; flex: none;"
        >
          <v-layout align-center class="fill-height">
            <v-autocomplete
              v-model="frequency"
              :items="frequencies"
              dense
              clearable
              hide-details
              label="Frequency"
              outlined
              placeholder="Frequency"
              @change="getChartData(currentField)"
            ></v-autocomplete>
          </v-layout>
        </div>
        <v-divider />
        <v-layout v-if="currentField" class="pa-2" column justify-center style="flex: 1; height: 50px;">
          <v-layout>
            <span class="text-truncate" style="font-size: 16px; align-items: center">{{ currentField.name }}</span>
            <v-spacer />
            <v-tooltip color="#000000" left>
              <template v-slot:activator="{ on, attrs }" v-on="on">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.native.stop="zoomToField(currentField)">
                      <v-icon size="22">icon-gps</v-icon>
                    </v-btn>
                  </template>
                  <span>Zoom to field</span>
                </v-tooltip>
              </template>
              <span>Fly to</span>
            </v-tooltip>
            <v-menu v-if="currentField && !sync" offset-y top>
              <template #activator="{ on: onMenu }">
                <v-tooltip bottom>
                  <template #activator="{ on: onTooltip }">
                    <v-btn icon small v-on="{ ...onMenu, ...onTooltip }">
                      <v-icon size="19">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download AOI</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <div class="px-2 subtitle-2">
                  Download
                </div>
                <v-divider />
                <v-list-item @click="() => $emit('download', 'tif')">
                  <v-list-item-title class="caption">{{ typeIndex.name }}.tiff</v-list-item-title>
                </v-list-item>
                <v-list-item @click="() => $emit('download', 'csv')">
                  <v-list-item-title class="caption">
                    {{ currentField.name + '_' + typeIndex.name }}.csv
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="() => $emit('download', 'contours')">
                  <v-list-item-title class="caption">{{ currentField.name }}.geojson </v-list-item-title>
                </v-list-item>
                <v-list-item @click="() => $emit('download', 'qml')">
                  <v-list-item-title class="caption">
                    QGIS_style.qml
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-layout>
          <v-spacer />
          <v-layout align-center>
            <span
              class="pr-2"
              style="color:#79899A; font-size: 13px"
              v-html="'Area: ' + transformArea(currentField.area)"
            ></span>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-card>
    <v-divider />
    <v-layout v-if="!currentField && !selectFirstAoi" class="pb-2" column style="height: calc(100% - 180px)">
      <v-card :disabled="drawing" class="custom-card-bg-0 px-2" height="100%" style="overflow-y: auto;">
        <div v-if="listAOI.length == 0" style="height: 100px" class="mt-5">
          <AddingAoiOption
            :isBoundary="false"
            @fetchAOI="getAOI()"
            @startDraw="() => $emit('openDrawTool')"
          ></AddingAoiOption>
        </div>
        <div v-for="item in listAOI" style="width: 100%; border-radius: 2px">
          <v-list-item
            v-if="!item.items || !item.items.length"
            class="my-1 py-1 custom-card-bg-1"
            @click="selectField(item)"
            @contextmenu="handleContextMenuItem(item.uuid, $event)"
          >
            <div style="width: 50px; flex: none">
              <v-layout align-center style="width: 45px; height: 45px">
                <v-img
                  :lazy-src="item.thumbnail_url"
                  :src="item.thumbnail_url"
                  contain
                  max-height="100%"
                  max-width="100%"
                  style="border-radius: 4px"
                >
                  <template v-slot:placeholder>
                    <v-row align="center" class="fill-height ma-0" justify="center">
                      <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-layout>
            </div>
            <BorderArrow v-if="item.display" />
            <!--            <v-list-item-icon class="mr-3">-->
            <!--              <v-btn icon small title="View" @click.native.stop="changeDisplayAOI(item)">-->
            <!--                <CustomIcon :color="item.color" icon="eye" size="22" />-->
            <!--              </v-btn>-->
            <!--            </v-list-item-icon>-->
            <v-layout align-start class="text-truncate" column>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" style="width: 100%; font-size: 14px" v-bind="attrs" v-on="on">
                    {{ item.name }}
                  </div>
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
              <v-list-item-subtitle class="subtitle-2 text-truncate">
                <div style="font-size: 10px" v-html="' Area: ' + transformArea(item.area)"></div>
              </v-list-item-subtitle>
            </v-layout>
            <v-list-item-action>
              <v-layout justify-end>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click.native.stop="zoomToField(item)">
                      <v-icon size="22">icon-gps</v-icon>
                    </v-btn>
                  </template>
                  <span>Zoom to field</span>
                </v-tooltip>
                <CustomMenu
                  :ref="`actionmenu${item.uuid}`"
                  :items="actions"
                  :icon="'mdi-dots-vertical'"
                  @sort-change="menu => selectAction(menu, item)"
                />
              </v-layout>
            </v-list-item-action>
          </v-list-item>
          <v-expansion-panels v-else class="custom-expansion" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="custom-card-bg-1 text-truncate mt-1">
                <template v-slot:default="{ open }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="pr-4" style="flex: none" v-bind="attrs" v-on="on">
                        <CustomIcon icon="folder" />
                      </div>
                      <div
                        class="text-truncate"
                        style="flex: none; width: 160px; font-size: 14px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.name }}
                      </div>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                  <v-spacer />
                  <div class="px-2" style="flex: none; font-size: 12px">{{ item.items.length }} items</div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background: transparent !important;">
                <v-list-item
                  v-for="child in item.items"
                  :key="child.name"
                  class="pr-0 pl-1 my-1 position-relative custom-card-bg-1"
                  @click="selectField(child)"
                  @contextmenu="handleContextMenuItem(child.uuid, $event)"
                >
                  <div style="width: 50px; flex: none">
                    <v-layout align-center style="width: 45px; height: 45px">
                      <v-img
                        :lazy-src="child.thumbnail_url"
                        :src="child.thumbnail_url"
                        contain
                        max-height="100%"
                        max-width="100%"
                        style="border-radius: 4px"
                      >
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-layout>
                  </div>
                  <BorderArrow v-if="child.color" />
                  <v-layout align-center class="py-1 px-2" style="height: 62px">
                    <!--                    <v-list-item-icon class="mr-3">-->
                    <!--                      <v-btn icon small title="View" @click.native.stop="changeDisplayAOI(child)">-->
                    <!--                        <CustomIcon :color="child.color" icon="eye" size="22" />-->
                    <!--                      </v-btn>-->
                    <!--                    </v-list-item-icon>-->
                    <v-layout class="fill-height" column justify-center>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="text-truncate" style="width: 100%; font-size: 14px" v-bind="attrs" v-on="on">
                            {{ child.name }}
                          </div>
                        </template>
                        <span>{{ child.name }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div class="subtitle-2 text-truncate" v-bind="attrs" v-on="on">
                            <div style="font-size: 11px" v-html="' Area: ' + transformArea(child.area)"></div>
                          </div>
                        </template>
                        <span v-html="'Area: ' + transformArea(child.area)"></span>
                      </v-tooltip>
                    </v-layout>
                    <v-list-item-action>
                      <v-layout justify-end>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click.native.stop="zoomToField(child)">
                              <v-icon size="22">icon-gps</v-icon>
                            </v-btn>
                          </template>
                          <span>Zoom to field</span>
                        </v-tooltip>
                        <CustomMenu
                          :ref="`actionmenu${child.uuid}`"
                          :items="actions"
                          :icon="'mdi-dots-vertical'"
                          @sort-change="menu => selectAction(menu, child)"
                        />
                      </v-layout>
                    </v-list-item-action>
                  </v-layout>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </v-layout>
    <v-layout
      v-show="(currentField || selectFirstAoi) && group.id === 'dashboard'"
      column
      style="height: calc(100% - 300px)"
    >
      <v-layout column style="overflow-y: auto; max-height: calc(100vh - 250px)">
        <div class="fill-height" style="width: 100%; flex: none">
          <v-layout v-if="currentField" class="fill-height" column>
            <div
              v-if="currentField.information && !selectFirstAoi && $route.name == 'vegetation-indices'"
              style="flex: none; height: 60px; border-bottom: 2px solid black"
            >
              <v-layout align-center class="fill-height px-2">
                Crop rotation
                <v-tooltip color="#000000e4" max-width="500" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    Keep record of a crop rotation history and plan the future sowing operations to maintain soil
                    fertility, thus reducing the risks of plant diseases and pests. You can manage the field’s crop
                    rotation in its editing settings.
                  </span>
                </v-tooltip>
                <v-spacer />
                <a
                  style="text-decoration: underline; font-size: 14px"
                  @click="() => $refs.createSeason.openDialog(currentField)"
                  >Add Season</a
                >
                <!--                v-for="season in currentField.information.seasons"-->
              </v-layout>
            </div>
            <div v-for="(season, i) in seasons" :key="i" class="mt-2" style="width: 100%">
              <v-layout class="pa-2" column>
                <v-layout align-center>
                  <span style="font-size: 14px">Season: {{ season.name }}</span>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="() => $refs.updateSeason.openDialog(currentField, season)"
                      >
                        <v-icon size="20">mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="error"
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="() => $refs.deleteDialog.openDialog(season)"
                      >
                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-layout>
                <div class="mt-3 pa-2" style="width: 100%; border: 1px solid rgba(98,98,98,0.37); border-radius: 6px">
                  <v-expansion-panels class="custom-expansion-v2">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-2">
                        <v-layout>
                          <v-layout class="text-truncate pr-1" column style="width: 120px">
                            <span style="color: #595dfc;">Crop name</span>
                            <div class="pt-2 text-truncate" style="max-width: 100px">
                              {{ season.crop_type }}
                            </div>
                          </v-layout>
                          <v-layout class="text-truncate pr-1" column>
                            <span style="color: #595dfc">Sowing date</span>
                            <div class="pt-2">{{ season.sowing_date }}</div>
                          </v-layout>
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="px-2">
                        <v-layout style="font-size: 15px">
                          <v-layout class="text-truncate pr-1" column style="width: 120px">
                            <span style="color: #595dfc">Maturity</span>
                            <div class="pt-2">{{ season.maturity ? season.maturity : 'Default' }}</div>
                          </v-layout>
                          <v-layout class="text-truncate pr-1" column>
                            <span style="color: #595dfc">Harvesting date</span>
                            <div class="pt-2">{{ season.harvesting_date }}</div>
                          </v-layout>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-layout>
            </div>
            <div v-if="currentField.weather" class="pa-2" style="height: 40px; width: 100%">
              <v-layout class="fill-height">
                Current weather
                <v-spacer />
                <v-btn icon x-small @click="showWeather = !showWeather">
                  <v-icon v-if="!showWeather">mdi-chevron-down</v-icon>
                  <v-icon v-else>mdi-chevron-up</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <div v-if="currentField.weather" class="pa-2 pl-4" style="font-size: 18px">
              <div class="d-flex " style="align-items:center">
                <span class="mr-4">{{ dateFormat(new Date()) }}</span>
                <span style="font-size: 16px;"
                  >{{ new Date().getHours() }}:00 - {{ new Date().getHours() + 1 }}:00</span
                >
              </div>
              <span style="font-size: 36px">{{ currentField.weather.temp }}°C</span>
            </div>
            <div v-if="showWeather && currentField.weather" style="height: 160px; border-top: 2px solid black">
              <v-layout v-if="showWeather" class="pl-4 py-2" style="height: 80px">
                <v-layout align-start column justify-center style="flex: 1;">
                  <span class="pb-2" style="color: var(--v-button-base)">
                    Wind
                  </span>
                  <span>
                    {{ currentField.weather.wind }} m/s
                    <v-icon
                      :style="{ transform: 'rotate(' + (90 - currentField.weather.wind_degree) + 'deg)' }"
                      class="ml-0"
                      size="16"
                    >
                      mdi-navigation
                    </v-icon>
                  </span>
                </v-layout>
                <v-layout align-start column justify-center style="flex: 1;">
                  <span class="pb-2" style="color: var(--v-button-base)">Humidity</span>
                  <span>
                    {{ currentField.weather.humidity }} %
                    <v-icon class="ml-0" size="16">
                      mdi-water
                    </v-icon>
                  </span>
                </v-layout>
              </v-layout>
              <v-layout v-if="showWeather" align-center class="pl-4" style="height: 80px;">
                <v-layout align-start column justify-center style="flex: 1;">
                  <span style="color: var(--v-button-base)">Precipitation</span>
                  {{ currentField.weather.precipitation }} mm
                </v-layout>
                <v-layout align-start column justify-center style="flex: 1;">
                  <span class="pb-2" style="color: var(--v-button-base)">Clouds</span>
                  <span>
                    {{ currentField.weather.cloud }} %
                    <v-icon class="ml-0" size="16">
                      mdi-cloud
                    </v-icon>
                  </span>
                </v-layout>
              </v-layout>
            </div>
            <!-- <div class='pa-2'>-->
            <!--              <h3>Natural Color</h3>-->
            <!--            </div>-->
            <!--            <div class='mt-1 mb-2' style='width: 100%; min-height: 330px'>-->
            <!--              <v-layout v-if='currentField' align-center class='pa-2 position-relative fill-height'>-->
            <!--                <Map-->
            <!--                  ref='mapPreview'-->
            <!--                  :current-layers.sync='layers'-->
            <!--                  :is-copy-right='false'-->
            <!--                  :is-preview='true'-->
            <!--                  :isLoading.sync='loading'-->
            <!--                />-->
            <!--              </v-layout>-->
            <!--            </div> -->
          </v-layout>
        </div>
      </v-layout>
    </v-layout>
    <Create ref="createSeason" @fetchSeason="getSeason(currentField.uuid)" />
    <Update ref="updateSeason" @fetchSeason="getSeason(currentField.uuid)" />
    <DeleteDialog ref="deleteDialog" :submit-delete="submitDelete" />
    <DeleteDialog ref="deleteAOIDialog" :submit-delete="submitDeleteAOI" />
    <UploadAOI ref="uploadAOI" @fetchAOI="getAOI" />
    <UpdateAOIDialog ref="updateAOIDialog" @fetchAOI="getAOI" />
  </v-layout>
</template>

<script>
import AddingAoiOption from '@/components/AddingAoiOption/index.vue'
import date from '@/utils/convertDate'
import InfiniteLoading from 'vue-infinite-loading'
import MapPreview from '@/components/MapPreview.vue'
import { debounce } from 'lodash'
import AreaTransform from '@/utils/convertArea'
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import SelectIndex from '@/components/SelectIndex'
import DatePicker from '@/components/DatePicker'
import { getOrder } from '@/api/order'
import { deleteAOI, deleteSeason, getDetailAOI, getSeason } from '@/api/aoi-api'
import { getImages } from '@/api/monitoring-api'
import BorderArrow from '@/components/BorderArrow.vue'
import Status from '@/utils/status'
import SERVICES from '@/constants/services'
import Map from '@/components/Map.vue'
import Create from '@/views/monitoring/field-control/season/Create.vue'
import Update from '@/views/monitoring/field-control/season/Update.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import UploadAOI from '@/views/aoi/UploadAOI.vue'
import UpdateAOIDialog from '@/views/aoi/UpdateDialog.vue'
import CustomMenu from '@/components/CustomMenu.vue'
import FilterComponent from '@/components/Filter/Index.vue'
import { getCustomFilter } from '@/api/settings'
export default {
  name: 'FieldControl',
  components: {
    UploadAOI,
    CustomIcon,
    DeleteDialog,
    Update,
    Create,
    Map,
    BorderArrow,
    DatePicker,
    SelectIndex,
    MapPreview,
    InfiniteLoading,
    AddingAoiOption,
    UpdateAOIDialog,
    CustomMenu,
    FilterComponent,
  },
  data() {
    return {
      currentAOI: {},
      seasons: [],
      source: undefined,
      sources: [],
      layers: [],
      images: [],
      statusUpdate: false,
      menu: false,
      state: undefined,
      page: 1,
      search: '',
      infiniteIndex: 1,
      desc: true,
      sort: undefined,
      frequency: undefined,
      frequencies: [],
      cacheFields: [],
      orders: [],
      sorts: [
        {
          name: 'Newest',
          code: '-created_at',
          group: 'date',
        },
        {
          name: 'Oldest',
          code: 'created_at',
          group: 'date',
        },
        {
          name: 'Field Name: A - Z',
          code: 'name',
          group: 'name',
        },
        {
          name: 'Field Name: Z - A',
          code: '-name',
          group: 'name',
        },
        {
          name: 'Field area: low to high',
          code: 'area',
          group: 'area',
        },
        {
          name: 'Field area: high to low',
          code: '-area',
          group: 'area',
        },
      ],
      actionsList: [
        { name: 'Zoom To', icon: 'mdi-crosshairs-gps' },
        {
          name: 'Change Name',
          group: 'crud',
          code: 'aoi.update',
          icon: 'mdi-rename-box',
        },
        {
          name: 'Change Group',
          group: 'crud',
          code: 'aoi.update',
          icon: 'mdi-group',
        },

        {
          name: 'Download AOI',
          group: 'crud',
          icon: 'mdi-download',
        },
        {
          name: 'Delete AOI',
          code: 'aoi.delete',
          group: 'delete',
          icon: 'mdi-delete',
        },
      ],
      filters: {
        condition: 'and',
        filters: [],
      },
      router: 'Fields',
      showDetail: false,
      showWeather: true,
      showMoreSeason: false,
    }
  },
  props: {
    selectFirstAoi: { type: Boolean, default: false },
    isDraw: { type: Boolean, default: false },
    drawing: { type: Boolean, default: false },
    listField: {
      type: Array,
      default: () => [],
    },
    sourceIndex: {
      type: String,
      default: 'Sentinel2',
    },
    loading: { type: Boolean, default: false },
    field: {},
    dates: { type: Array, default: () => [] },
    index: { type: String, default: '' },
    typeIndex: {},
    legendMenu: {},
    mapLegend: { type: Array, default: () => [] },
    customLegend: {},
    sync: {},
  },
  async mounted() {
    this.source = this.sourceIndex
    await this.getSaveFilter()
    this.getAOI()
  },
  computed: {
    ...mapState('tab', ['tabs']),
    ...mapState('menu', ['group']),
    ...mapState('AOI', ['listAOI', 'groups','AOIs']),
    ...mapState('service', ['services', 'firstLoad']),
    ...mapState('auth', ['currentUser']),
    actions() {
      return this.actionsList.filter(action => !action.code || this.currentUser.permissions.includes(action.code))
    },
    legends: {
      get() {
        return this.customLegend
      },
      set(val) {
        this.$emit('update:customLegend', val)
      },
    },
    fields: {
      get() {
        return this.listField
      },
      set(val) {
        this.$emit('update:listField', val)
      },
    },
    currentField: {
      get() {
        return this.field
      },
      set(val) {
        this.$emit('update:field', val)
      },
    },
    isLoading: {
      get() {
        return this.loading
      },
      set(val) {
        this.$emit('update:loading', val)
      },
    },
  },
  watch: {
    sort() {
      this.searchField()
    },
    search() {
      this.isLoading = true
      this.$nextTick(() => {
        this.searchField()
      })
      this.searchField()
    },
    index() {
      this.getChartData(this.currentField)
    },
  },
  methods: {
    async getSaveFilter() {
      try {
        let res = await getCustomFilter(this.$route.params.id)
        if (res.data[this.$route.name] && res.data[this.$route.name].filters && res.data[this.$route.name].condition) {
          this.filters = res.data[this.$route.name]
          if (this.filters.filters.length > 0) this.$refs.filter.isFilter = true
        }
      } catch (e) {
        console.log(e)
      }
    },
    dateFormat(datetime) {
      return date.dateFormatted(datetime)
    },
    selectAction(item, aoi) {
      switch (item.name) {
        case 'Zoom To':
          this.zoomToField(aoi)
          break
        case 'Download AOI':
          this.downloadAOI(aoi)
          break
        case 'Delete AOI':
          this.$refs.deleteDialog.openDialog(aoi)
          break
        case 'Change Name':
          this.updateInfo(aoi, 'name')
          break
        case 'Change Group':
          this.updateInfo(aoi, 'group')
          break
      }
    },
    updateInfo(AOI, type) {
      this.$refs.updateAOIDialog.openDialog(AOI, type)
    },
    debounceInput: debounce(function(e) {
      this.getAOI()
    }, 500),
    changeSort(sort) {
      if (this.sort === sort) this.sort = undefined
      else this.sort = sort
      this.getAOI()
    },
    async downloadAOI(AOI) {
      this.isLoading = true
      let res = await getDetailAOI({
        projectId: this.$route.params.id,
        id: AOI.uuid,
        payload: { weather: true },
      })
      res.data.featureCollection = {
        type: 'FeatureCollection',
        name: 'field',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: res.data.geojson,
          },
        ],
      }
      this.isLoading = false
      let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(res.data))
      let downloadAnchorNode = document.createElement('a')
      downloadAnchorNode.setAttribute('href', dataStr)
      downloadAnchorNode.setAttribute('download', AOI.name + '.geojson')
      document.body.appendChild(downloadAnchorNode) // required for firefox
      downloadAnchorNode.click()
      downloadAnchorNode.remove()
    },
    async submitDeleteAOI(item) {
      try {
        this.isLoading = true
        await deleteAOI({ projectId: this.$route.params.id, id: item.uuid })
        await this.getAOI()
        if (this.currentAOI.uuid === item.uuid) this.changeDisplayAOI(item)
        this.$emit('fetchData')
      } catch (e) {
      } finally {
        this.isLoading = false
      }
    },
    async getAOI() {
      try {
        this.isLoading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: {
            service_name: this.$route.meta.service,
            search: this.search,
            sort: this.sort,
            filters: JSON.stringify(this.filters),
          },
        })
        if (this.selectFirstAoi) this.selectField(this.AOIs[0])
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    handleContextMenuItem(uuid, event) {
      event.preventDefault()
      const actionMenuRefs = Object.keys(this.$refs).filter(refName => refName.startsWith('actionmenu'))
      for (const refName of actionMenuRefs) {
        this.$refs[refName][0].closeMenu()
      }
      let actionMenu = 'actionmenu' + uuid
      this.$refs[actionMenu][0].openMenu()
    },
    changeDisplayAOI(item) {
      if (!item.display) {
        this.currentAOI.display = false
        this.currentAOI.color = undefined
        this.currentAOI = item
        this.$emit('addAOIToMap', item)
        this.$emit('zoomToField', item.bbox)
        this.currentAOI.color = '#16B1FF'
        this.currentAOI.display = true
      } else {
        this.$emit('removeAll')
        this.currentAOI = {}
        item.display = false
        item.color = undefined
      }
      let tmpAOIs = [...this.listAOI]
      this.listAOI = undefined
      this.listAOI = tmpAOIs
    },
    async submitDelete(season) {
      try {
        this.isLoading = true
        await deleteSeason({
          id: this.currentField.uuid,
          seasonId: season.id,
          projectId: this.$route.params.id,
        })
        await this.getSeason(this.currentField.uuid)
      } catch (e) {
      } finally {
        this.isLoading = false
      }
    },
    transformArea(area) {
      const transformedArea = AreaTransform.transformUnit(area)
      const unit = transformedArea.split(' ')[1]
      if (unit === 'm<sup>2</sup>') this.$emit('areaUnit', 'Square meter')
      else if (unit === 'ha') this.$emit('areaUnit', 'Hectare')
      else if (unit === 'km<sup>2</sup>') this.$emit('areaUnit', 'Square kilometer')
      return transformedArea
    },
    searchField: debounce(function() {
      this.fields = []
      this.page = 1
      this.infiniteIndex = this.infiniteIndex + 1
    }, 300),
    async getService(aoi) {
      try {
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: aoi.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.orders = res.data
        this.sources = res.data.map(val => val.image_source)
        this.source = this.sources[0]
        if (
          this.$route.name == 'biodiversity' ||
          this.$route.name == 'thermal-index' ||
          this.$route.name == 'soil-moisture'
        ) {
          res.data.map(val => {
            if (!this.frequencies.includes(val.frequency)) {
              this.frequencies.push(val.frequency)
            }
          })
          this.frequency = this.frequencies[0]
        }
      } catch (e) {
      } finally {
      }
    },
    zoomToField(field) {
      this.$emit('zoomToField', field.bbox)
    },
    async backToListField() {
      this.fields = []
      this.page = 1
      this.$emit('destroyChart')
      this.$emit('closeColorPicker')
      this.router = 'Fields'
      this.currentField = undefined
      if (this.sync) this.$emit('changeStatusMap')
      this.$emit('mapSize')
      this.$emit('showLayerControl')
    },
    async selectField(field) {
      if (field.display) this.changeDisplayAOI(field)
      await this.getService(field)
      this.currentField = field
      await this.getDetailAOI(field.uuid)
      await this.getSeason(field.uuid)
      this.$emit('showLayerControl')
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    async getSeason(fieldId) {
      try {
        this.isLoading = true
        this.seasons = []
        let res = await getSeason({
          projectId: this.$route.params.id,
          id: fieldId,
          payload: {
            sort: '-sowing_date',
            per_page: 'all',
          },
        })
        this.seasons = res.data
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      } finally {
      }
    },
    async getDetailAOI(fieldId) {
      try {
        let cacheField = this.cacheFields.find(cacheField => cacheField.field.uuid == fieldId)
        if (!cacheField) {
          this.isLoading = true
          let res = await getDetailAOI({
            projectId: this.$route.params.id,
            id: fieldId,
            payload: { weather: true },
          })

          res.data.layerId = 'aoi_' + res.data.id
          res.data.featureCollection = {
            type: 'FeatureCollection',
            name: 'field',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: res.data.geojson,
              },
            ],
          }

          res.data.bbox = this.currentField.bbox
          this.currentField = { ...res.data }
          this.$emit('removeAll')
          this.router = 'Field'
          await this.getChartData(res.data)
          await sleep(200)
          this.zoomToField(res.data)
        } else {
          this.currentField = { ...cacheField.field }
          this.$emit('removeAll')
          this.router = 'Field'
          await this.getChartData(cacheField.field)
          await sleep(200)
          this.zoomToField(cacheField.field)
        }

        this.isLoading = false
      } catch (e) {
        console.log(e)
        this.isLoading = false
      } finally {
      }
    },
    async getChartData(field, isFilter = false) {
      if (this.frequency && !isFilter) {
        let minDate = null
        let maxDate = null
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/
        this.orders.forEach(order => {
          if (order.frequency === this.frequency) {
            if (dateRegex.test(order.from_date) && dateRegex.test(order.to_date)) {
              const fromDate = new Date(order.from_date)
              const toDate = new Date(order.to_date)

              if (!minDate || fromDate < minDate) {
                minDate = fromDate
              }
              if (!maxDate || toDate > maxDate) {
                maxDate = toDate
              }
            }
          }
        })
        this.$emit('update:dates', [
          minDate ? minDate.toISOString().substring(0, 10) : null,
          maxDate ? maxDate.toISOString().substring(0, 10) : null,
        ])
      }
      await sleep(200)
      let cacheField = this.cacheFields.find(cacheField => cacheField.field.uuid == field?.uuid)
      if (
        !cacheField ||
        cacheField.index !== this.index ||
        cacheField.dateTime[0] !== this.dates[0] ||
        cacheField.dateTime[1] !== this.dates[1]
      ) {
        try {
          this.isLoading = true
          const res = await getImages({
            id: field.uuid,
            projectId: this.$route.params.id,
            payload: {
              source: this.source,
              per_page: 'all',
              from_date: this.dates[0],
              frequency: this.frequency,
              to_date: this.dates[1],
              index: this.index,
              service_name: this.$route.meta.service,
              sort: 'acquired_date',
            },
          })
          if (!res.data.length) this.$store.commit('message/SHOW_ERROR', 'No data available')
          this.images = res.data
          this.cacheFields.push({
            field: field,
            images: res.data,
            index: this.index,
            dateTime: [this.dates[0], this.dates[1]],
          })
          this.$emit('displayChartData', res.data)
        } catch (e) {
        } finally {
          this.isLoading = false
        }
      } else {
        this.$emit('displayChartData', cacheField.images)
      }
    },
    changeVisibility(month) {
      // this.layers.forEach(layer => {
      //   this.$refs.mapPreview.changeVisibility(layer.id, 'none')
      // })
      // let layer = this.layers.find(layer => layer.dataId === month.id)
      // this.$refs.mapPreview.changeVisibility(layer.id, 'visible')
    },
  },
}
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0 4px !important;
}
.thumbnail {
  width: 50px;
  height: 50px;
  background-color: #f5f5f5;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
