<template>
  <v-dialog v-model="dialog" persistent width="1230" @keydown.enter="submit" >
    <v-form ref="formData" v-model="valid" onSubmit="return false;" >
      <v-card :disabled="loading" class="custom-card-bg-gradient" style="overflow-y:hidden">
        <DialogHeader :is-close="true" @close="dialog = false" class="sticky-header">
          <template #title>
            <div class="mr-2">
              Submit Order
            </div>
            <TourGuide ref="tourGuide" v-if="elements" :elements="elements" :isOnScreenDialog="isOnScreenDialog" />
          </template>
        </DialogHeader>
        <v-row class="mb-10"  >
          <v-col cols="7" class="scrollable-content" >
            <div
              style="display:flex; flex-direction:row; justify-content:space-between; align-items:flex-start"
              class="mb-4"
            >
              <div  ref="selectAOI" style="width:100%;flex:1; margin-right: 20px;" v-if="!hasAOI">
                <v-select2
                  v-model="AOI"
                  :items="listAOI"
                  item-text="name"
                  item-value="uuid"
                  label="Select AOI"
                  width="50%"
                  outlined
                  return-object
                  style="width: 100%;margin: 0 10px 0 0;"
                ></v-select2>
              </div>
              <v-menu v-model="menuService" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div ref="service" style="width:50%" >
                    <v-btn
                      dark
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      style="width:100%;height:41px !important;margin-top:2px;border:2px solid #44475a;border-radius:10px;"
                    >
                      <v-layout align-center class="fill-height" style="text-transform: none">
                        <span v-if="!Object.keys(orderService.service).length">Select Service</span>
                        <span v-else> {{ orderService.service.name }}</span>
                        <v-spacer />
                        <v-icon>
                          mdi-menu-down
                        </v-icon>
                      </v-layout>
                    </v-btn>
                  </div>
                </template>
                <v-list>
                  <v-subheader class="px-2">
                    <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      background-color="#252936"
                      dense
                      hide-details
                      label="Search"
                      placeholder="Search"
                      rounded
                      solo
                      @input="searchService"
                    >
                    </v-text-field>
                  </v-subheader>
                  <v-divider />
                  <v-list-group v-for="(item, index) in uniqueAvailableServices" :key="'group' + index">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon class="mr-2">mdi-menu</v-icon>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item v-for="(service, i) in item.services" :key="i" @click="changeService(service)">
                      <v-list-item-title class="pl-3">
                        <v-icon class="mr-2">mdi-earth</v-icon>
                        {{ service.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </v-list>
              </v-menu>
            </div>
            <div v-if="requirements && requirements.length > 0" ref="requirements" class="subtitle-2 ">
              <v-layout align-center class="mb-2">
                Requirements
                <v-divider class="ml-2" />
              </v-layout>
              <v-card v-if="orderService.service.requirement" class="custom-card-bg-2 pa-1">
                <div v-for="requirement in requirements" class="d-flex">
                  <div class="ma-1 d-flex flex-column">
                    <div class="d-flex align-start">
                      <v-icon size="12" class="mr-2" style="margin-top:5px">mdi-asterisk-circle-outline</v-icon>
                      <span>{{ requirement }}.</span>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-if="Object.keys(orderService.service).length" class="" style="width: 100%;">
              <div ref="form" class="subtitle-2 mb-6 mt-4">
                <v-layout align-center class="mb-2">
                  Order Information
                  <v-divider class="ml-2" />
                </v-layout>
                <div class="btn-container ml-2">
                  <v-row no-gutters>
                    <v-col cols="3" class="d-flex align-center ">
                      <span class="mr-4">Date range: </span>
                    </v-col>
                    <v-col cols="9">
                      <div style="width: 300px">
                        <DateRangerPicker
                          v-model="orderService.dates"
                          :pick="orderService.frequency"
                          dense
                          label="Select time"
                          left
                          outlined
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="btn-container ml-2">
                  <v-row no-gutters>
                    <v-col cols="3" class="d-flex align-center ">
                      <span>Image source: </span>
                    </v-col>
                    <v-col cols="9">
                      <v-btn-toggle
                        v-model="orderService.image_source"
                        dense
                        mandatory
                        style="border: 1px solid #35374b; "
                      >
                        <template v-for="(button, index) in orderService.service.image_sources">
                          <div
                            class="d-flex"
                            :style="
                              orderService.image_source == button
                                ? 'background-color: linear-gradient(70deg, rgba(54, 54, 110, 0.3) 0%, rgba(44, 44, 89, 0.38) 30%, rgba(45, 45, 98, 0.27) 40%)'
                                : 'background-color:transparent'
                            "
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :value="button"
                                  style="text-transform: capitalize; font-size: 11px; border-radius: 4px"
                                  width="150"
                                  :style="{
                                    textTransform: 'capitalize',
                                    fontSize: '11px',
                                    borderRadius:
                                      orderService.service.image_sources.length === 1
                                        ? '4px'
                                        : index === 0
                                        ? '4px 0 0 4px'
                                        : index === orderService.service.image_sources.length - 1
                                        ? '0 4px 4px 0'
                                        : '0',
                                    width: '150px',
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ button }}
                                </v-btn>
                              </template>
                              <span>{{ sourceDescription[button] }}</span>
                            </v-tooltip>
                            <div
                              v-if="index < orderService.service.image_sources.length - 1"
                              style="width: 1px; height: 100%; background-color:#35374b"
                            ></div>
                          </div>
                        </template>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </div>
                <div class="btn-container ml-2">
                  <v-row no-gutters>
                    <v-col cols="3" class="d-flex align-center ">
                      <span>Frequency: </span>
                    </v-col>
                    <v-col cols="9">
                      <v-btn-toggle
                        v-model="orderService.frequency"
                        dense
                        mandatory
                        style="border: 1px solid #35374b; "
                      >
                        <template v-for="(button, index) in frequency">
                          <div
                            class="d-flex"
                            :style="
                              orderService.frequency == button
                                ? 'background-color: linear-gradient(70deg, rgba(54, 54, 110, 0.3) 0%, rgba(44, 44, 89, 0.38) 30%, rgba(45, 45, 98, 0.27) 40%)'
                                : 'background-color:transparent'
                            "
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :value="button"
                                  style="text-transform: capitalize; font-size: 11px; border-radius: 4px"
                                  width="150"
                                  :style="{
                                    textTransform: 'capitalize',
                                    fontSize: '11px',
                                    borderRadius:
                                      frequency.length === 1
                                        ? '4px'
                                        : index === 0
                                        ? '4px 0 0 4px'
                                        : index === frequency.length - 1
                                        ? '0 4px 4px 0'
                                        : '0',
                                    width: '150px',
                                  }"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ button }}
                                </v-btn>
                              </template>
                              <span>{{ frequencyDescription[button] }}</span>
                            </v-tooltip>
                            <div
                              v-if="index < frequency.length - 1"
                              style="width: 1px; height: 100%; background-color:#35374b"
                            ></div>
                          </div>
                        </template>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div ref="description" style="width: 100%" class="subtitle-2 mb-4 mt-4">
                <div class="subtitle-2 mb-2">
                  <v-layout align-center>
                    Description
                    <v-divider class="ml-2" />
                  </v-layout>
                </div>
                <div v-if="orderService.service" class="ml-2 " style="text-align: justify;">
                  {{ orderService.service.description }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="5" style="height:50vh">
            <div class="px-1" style="position: relative;width: 100%; height:60% ">
              <div style=" height: 100%; border-radius: 8px; z-index: 10">
                <MapPreview :id="'AOI'" :geojson="AOIGeojson" />
              </div>
            </div>
            <div class="subtitle-2 mb-4 mt-4">
              <v-layout align-center>
                AREA OF INTEREST
                <v-divider class="ml-2" />
              </v-layout>
            </div>
            <div class="detail-aoi" v-if="detailCurrentAOI" style="height:40%; overflow-y: auto">
              <v-row>
                <v-col cols="4">
                  <div>Name:</div>
                </v-col>
                <v-col cols="8">
                  <div>{{ detailCurrentAOI.name }}</div></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div>Area:</div>
                </v-col>
                <v-col cols="8">
                  <div v-html="(detailCurrentAOI.area / 1000000).toFixed(3) + ' km<sup>2</sup>'"></div
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <div>Group:</div>
                </v-col>
                <v-col cols="8">
                  <div v-if="detailCurrentAOI.group">{{ detailCurrentAOI.group }}</div>
                  <div v-else>No group</div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-card-actions class="sticky-footer">
          <v-spacer />
          <v-btn color="primary" rounded type="submit" :loading="loading" @click="submit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import { getSourceProperties, makeOrder } from '@/api/order'
import { getAvailableServices } from '@/api/services'
import { getDetailAOI } from '@/api/aoi-api'
import VSelect2 from '@/components/Select2/Select2.vue'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import TourGuide from '@/components/GuideTour/index.vue'
import MapPreview from '@/components/MapPreview.vue'
export default {
  name: 'MakeOrder',
  components: {
    TourGuide,
    DateRangerPicker,
    DialogHeader,
    VSelect2,
    MapPreview,
  },
  data() {
    return {
      headers: [
        { text: 'Service', value: 'service_name', sortable: false },
        { text: 'Frequency', value: 'frequency', sortable: false },
        { text: 'Source', value: 'image_source', sortable: false },
        { text: 'Time', value: 'time', sortable: false },
        { text: 'Action', value: 'action', sortable: false },
      ],
      hasAOI: false,
      data: [],
      orderService: {
        service: {},
        frequency: undefined,
        image_source: undefined,
        dates: [],
        steps: undefined,
        other_info: undefined,
      },
      defaultService: undefined,
      properties: {},
      frequency: [],
      menuService: false,
      keyword: undefined,
      dialog: false,
      valid: false,
      AOI: {},
      allService: [],
      availableServices: [],
      availableAOI: [],
      rules: {
        required: value => !!value || 'Item is required',
      },
      loading: false,
      firstLoad: true,
      isFromService: false,
      elements: undefined,
      AOIGeojson: undefined,
      detailCurrentAOI: undefined,
      sourceDescription: {
        Sentinel2: 'Sentinel-2 satellite images are updated every 5 days.',
        Sentinel1: 'Sentinel-1 satellite images are updated every 12 days.',
        'Landsat 8': 'Landsat 8 satellite images are updated every 16 days.',
      },
      frequencyDescription: {
        daily: 'Search image everyday and return if downloadable.',
        once: 'Download image and process only once when order.',
        monthly: 'Returns the most recent least cloudy image every month.',
        quarterly: 'Returns the most recent least cloudy image every three months.',
        annual: 'Returns the most recent least cloudy image every year.',
      },
    }
  },
  props: {
    isOnScreenDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('AOI', ['listAOI', 'groups']),
    ...mapState('service', ['services']),
    uniqueAvailableServices() {
      return this.availableServices.map(group => {
        return {
          name: group.name,
          services: group.services.filter((item, index, self) => index === self.findIndex(t => t.id === item.id)),
        }
      })
    },
    requirements() {
      let reqArray = []
      reqArray = this.orderService?.service?.requirement?.split('.')
      if (reqArray) {
        reqArray.pop()
        reqArray = reqArray.map(item => item.trim())
      }
      return reqArray
    },
  },
  watch: {
    orderService: {
      handler(newValue) {
        if (this.elements && this.$refs.tourGuide) this.$refs.tourGuide.continue()
      },
      deep: true,
    },
    AOI: {
      async handler(val) {
        if (val.uuid) this.AOIGeojson = await this.getDetailAOI(val)
        else this.AOIGeojson = undefined
        this.orderService = {
          service: {},
          frequency: undefined,
          image_source: undefined,
          dates: [],
          steps: undefined,
          other_info: undefined,
        }
        if (!this.hasAOI && !this.firstLoad) this.initForm()
      },
      deep: true,
    },
    dialog(val) {
      if (!val) {
        this.orderService = {
          service: {},
          frequency: undefined,
          image_source: undefined,
          dates: [],
        }
        this.data = []
      }
    },
    menuService(val) {
      if (!val) {
        this.keyword = undefined
        this.searchService()
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  methods: {
    async getDetailAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        this.detailCurrentAOI = res.data
        return res.data.geojson
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async openDialog(AOI = null, service = null) {
      this.elements = undefined
      this.availableServices = []
      this.dialog = true
      if (service) {
        this.isFromService = true
        this.defaultService = service
      }
      if (AOI) {
        this.hasAOI = true
        this.AOI = AOI
      } else {
        this.hasAOI = false
      }
      await this.$store.dispatch('AOI/getListAOI', {
        projectId: this.$route.params.id,
        payload: {},
      })
      this.initForm()
      this.$nextTick(() => {
        this.elements = []
        if (!this.hasAOI) {
          this.elements.push({
            ref: this.$refs.selectAOI,
            id: 'AOI',
            title: 'Getting Started',
            text:
              this.listAOI.length > 0
                ? 'Select a AOI to submit order'
                : "You don't have any AOIs yet. Please create your first AOI to submit an order.",
            position: 'left',
            buttons:
              this.listAOI.length > 0
                ? []
                : [
                    {
                      text: 'Create AOI',
                      action: () => {
                        this.$refs.tourGuide.completeTour()
                        this.$router.push({ name: 'aoi-management' })
                      },
                    },
                  ],
          })
        }
        if (this.listAOI.length > 0 && !this.isFromService)
          this.elements.push({
            ref: this.$refs.service,
            id: 'service',
            title: 'Getting Started',
            text: 'Select a service you would like to order.',
            position: 'left',
          })
      })
    },
    async continueTour() {
      await sleep(1000)
      if (this.$refs.tourGuide) this.$refs.tourGuide.continue()
    },
    changeService(service) {
      this.orderService.service = { ...service }
      this.orderService.frequency = undefined
      this.orderService.image_source = undefined
      this.frequency = []
      this.menuService = false
      if (this.orderService.service?.image_sources?.length == 1) {
        this.orderService.image_source = this.orderService.service.image_sources[0]
        this.getDataService()
      }
    },
    searchService() {
      if (!this.keyword) this.availableServices = [...this.allService]
      else {
        this.availableServices = this.allService.filter(
          val =>
            val.services.some(service => service.name.toLowerCase().includes(this.keyword.toLowerCase())) ||
            val.name.toLowerCase().includes(this.keyword.toLowerCase()),
        )
      }
    },
    addToList() {
      this.$refs.formData.validate()
      if (this.valid) this.data.push({ ...this.orderService })
    },
    getDataService() {
      this.getProperties()
    },
    async getProperties() {
      try {
        this.loading = true
        const res = await getSourceProperties({
          projectId: this.$route.params.id,
          aoiId: this.AOI.uuid,
          serviceId: this.orderService.service.id,
          source: this.orderService.image_source,
        })
        this.orderService.steps = res.data.steps
        this.orderService.other_info = res.data.other_info
        this.frequency = res.data.frequency
        // if (this.frequency.length == 1)
        this.orderService.frequency = this.frequency[0]
      } catch (e) {
      } finally {
        this.loading = false
        // this.elements.push(
        //   {
        //     ref: this.$refs.description,
        //     id: 'description',
        //     title: 'Getting Started',
        //     text: 'What this service can do ?',
        //     position: 'left',
        //   },
        //   {
        //     ref: this.$refs.form,
        //     id: 'form',
        //     title: 'Getting Started',
        //     text: 'Submit order form',
        //     position: 'left',
        //   },
        //   {
        //     ref: this.$refs.requirements,
        //     id: 'requirements',
        //     title: 'Getting Started',
        //     text: 'Some requirements for order',
        //     position: 'right',
        //   },
        // )
      }
    },
    editOrder(order) {
      this.orderService = { ...order }
      this.removeOrder(order)
    },
    removeOrder(order) {
      let ind = this.data.findIndex(val => val.toString() === order.toString())
      if (ind >= 0) this.data.splice(ind, 1)
    },
    async initForm() {
      this.firstLoad = false
      if (!this.listAOI.length) {
        await sleep(500)
        return []
      } else this.getListAOI(this.listAOI)
      await this.getServices()
      if (this.defaultService)
        this.changeService(
          this.allService.flatMap(category => category.services).find(service => service.name == this.defaultService),
        )
    },
    async getServices() {
      try {
        this.loading = true
        if (this.AOI?.uuid) {
          this.availableServices = []
          const res = await getAvailableServices({
            projectId: this.$route.params.id,
            id: this.AOI.uuid,
          })
          res.data.forEach(val => {
            val.checked = false
          })
          res.data.forEach(service => {
            let group = this.availableServices.find(group => group.name === service.group)
            if (!group) {
              this.availableServices.push({
                name: service.group,
                services: [service],
              })
            } else group.services.push(service)
            this.allService = [...this.availableServices]
          })
        }
      } catch (e) {
        console.warn('Error when get services: ', e)
      } finally {
        this.loading = false
      }
    },
    getListAOI(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i].items) this.availableAOI.push(arr[i])
        else this.getListAOI(arr[i].items)
      }
    },
    async submit() {
      try {
        if (!this.$refs.formData.validate()) return
        this.loading = true
        let data = {
          projectId: this.$route.params.id,
          id: this.AOI.uuid,
          params: {
            service_id: this.orderService.service.id,
            from_date: this.orderService.dates[0],
            to_date: this.orderService.dates[1],
            image_source: this.orderService.image_source,
            frequency: this.orderService.frequency,
            steps: this.orderService.steps,
            other_info: this.orderService.other_info,
          },
        }
        await makeOrder(data)
        this.$emit('refresh')
        this.dialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
::v-deep .row {
  margin: 0 !important;
}
::v-deep .v-dialog {
  overflow-y: hidden;
}
.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
::v-deep.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
}
.requirement {
  border-radius: 10px;
  padding: 12px;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.scrollable-content {
  max-height: 50vh;
  overflow-y: auto;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding-bottom: 8px;
}
</style>
