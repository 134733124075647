<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    :left="$attrs['left']"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :disabled="disabled"
        :label="label"
        :outlined="$attrs['outlined']"
        :placeholder="label"
        :rules="rules"
        append-icon="mdi-calendar-month"
        dense
        hide-details
        prepend-inner-icon=""
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" :max="max" :min="min" type="month" @change="dateMenu = false"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: { type: String, default: undefined },
    min: { type: String, default: undefined },
    max: { type: String, default: undefined },
    label: { type: String, default: undefined },
    rules: { type: Array, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      this.dateModel = this.value
    },
  },
  computed: {
    date: {
      get() {
        return this.dateModel
      },
      set(val) {
        this.dateModel = val
        this.$emit('input', this.dateModel)
      },
    },
  },
  data: () => ({
    dateMenu: false,
    dateModel: undefined,
  }),
  created() {
    this.dateModel = this.value
  },
  mounted() {},
}
</script>
<style></style>
