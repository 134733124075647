<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <DialogHeader :is-close="true" title="Rename group" @close="dialog = false" />
      <v-form ref="form" v-model="valid" @submit.prevent="submitData">
        <v-card-text>
          <v-text-field
            v-model="newGroup.name"
            label="New name"
            outlined
            dense
            :rules="[rules.nameRequired]"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded @click="close" class="text-none">Cancel</v-btn>
          <v-btn text rounded type="submit" @click="submitData" :loading="loading" :disabled="!valid || oldGroup.name === newGroup.name" class="text-none">Submit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { cloneDeep } from 'lodash'
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  components: {
    DialogHeader
  },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      oldGroup: {},
      newGroup: {},
    }
  },
  methods: {
    open(group) {
      this.oldGroup = group
      this.newGroup = cloneDeep(group)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.name = ''
      this.loading = false
    },
    async submitData() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      this.$emit('submit', { oldGroup: this.oldGroup, newGroup: this.newGroup})
    },
  }
}
</script>
