var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1024"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"custom-card-bg-gradient",attrs:{"loading":_vm.loading}},[_c('DialogHeader',{attrs:{"is-close":true,"title":'List Order'},on:{"close":function($event){_vm.dialog = false}}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":5,"item-key":"uuid","show-expand":"","single-expand":""},on:{"item-expanded":_vm.getStatus},scopedSlots:_vm._u([{key:"item.from_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.from_date))+" ")]}},{key:"item.to_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.to_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'failed')?_c('v-tooltip',{attrs:{"color":"#000000","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":_vm.getStatusColor(item.status),"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":_vm.getStatusColor(item.status),"label":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.status === 'cancel' || item.status === 'completed',"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.cancelOrder(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("icon-close_square")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancel")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":"7"}},[_c('v-data-table',{attrs:{"headers":_vm.detailHeaders,"items":_vm.orderData,"items-per-page":5,"page":_vm.page,"hide-default-footer":"","item-key":"uuid"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v("mdi-memory")]),_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'failed')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getStatusColor(item.status),"small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)}),_c('div',{staticClass:"text-end py-2 pl-4"},[_c('v-layout',{attrs:{"align-center":""}},[_c('div',{staticStyle:{"max-width":"100px"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('span',{staticClass:"caption"},[_vm._v("Page:")]),_c('v-text-field',{staticClass:"ml-1",attrs:{"max":_vm.pageCount,"dense":"","flat":"","hide-details":"","label":"page","min":"1","placeholder":"page","solo":"","type":"number"},on:{"change":_vm.changePage},model:{value:(_vm.pageSelected),callback:function ($$v) {_vm.pageSelected=$$v},expression:"pageSelected"}})],1)],1),_c('v-spacer'),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"5"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=_vm._n($$v)},expression:"page"}})],1)],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }