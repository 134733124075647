<template>
  <v-slide-group v-model="currentItem" show-arrows ref="slideGroup">
    <v-slide-item v-for="(item, index) in items" :key="index" :value="item">
      <div
        class="slide-item"
        style="user-select: none;"
        draggable="false"
        :id="index == 0 ? 'itemFirst' : index == items.length - 1 ? 'itemLast' : ''"
      >
        <slot name="content" :item="item" :index="index" />
      </div>
    </v-slide-item>
  </v-slide-group>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currentItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isMouseDown: false,
      slideGroup: null,
      handleMouseMove: null,
      handleMouseUp: null,
      handleMouseDown: null,
      handleWheel: null,
    }
  },
  mounted() {
    this.initHandleSlideGroup()
  },
  beforeDestroy() {
    this.destroyHandleSlideGroup()
  },
  methods: {
    initHandleSlideGroup() {
      this.slideGroup = this.$refs.slideGroup

      this.handleWheel = event => {
        event.preventDefault()

        const firstItem = document.getElementById('itemFirst')
        const lastItem = document.getElementById('itemLast')

        const firstItemLeft = firstItem.getBoundingClientRect().left
        const lastItemRight = lastItem.getBoundingClientRect().right
        const slideGroupLeft = this.slideGroup.$el.getBoundingClientRect().left
        const slideGroupRight = this.slideGroup.$el.getBoundingClientRect().right

        const scrollOffsetUnit = 100
        if (event.deltaY > 0) {
          if (lastItemRight > slideGroupRight) {
            this.slideGroup.scrollOffset += scrollOffsetUnit
          }
        } else {
          if (firstItemLeft < slideGroupLeft) {
            this.slideGroup.scrollOffset -= scrollOffsetUnit
          }
        }
      }

      this.handleMouseDown = event => {
        this.isMouseDown = true
        this.startX = event.clientX
        this.scrollOffset = this.slideGroup.scrollOffset
      }

      this.handleMouseMove = event => {
        if (this.isMouseDown) {
          this.slideGroup.scrollOffset = this.scrollOffset - event.clientX + this.startX
        }
      }

      this.handleMouseUp = () => {
        this.isMouseDown = false
      }

      this.slideGroup.$el.addEventListener('wheel', this.handleWheel)
      this.slideGroup.$el.addEventListener('mousedown', this.handleMouseDown)
      document.addEventListener('mousemove', this.handleMouseMove)
      document.addEventListener('mouseup', this.handleMouseUp)
    },

    destroyHandleSlideGroup() {
      if (this.slideGroup) {
        this.slideGroup.$el.removeEventListener('wheel', this.handleWheel)
        this.slideGroup.$el.removeEventListener('mousedown', this.handleMouseDown)
        document.removeEventListener('mousemove', this.handleMouseMove)
        document.removeEventListener('mouseup', this.handleMouseUp)
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-slide-group__prev {
  display: flex;
}
::v-deep .v-slide-group__next {
  display: flex;
}
</style>
