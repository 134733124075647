<template>
  <div class="component-container">
    <div style="width: 100%;" @click="$refs.optionDialog.openDialog()">
      <div class="new-aoi" v-if="!btn" style="display: flex; cursor: pointer;">
        <div style="flex: 1;margin: 10px;">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            fit=""
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
          >
            <path
              d="M43.1578 52.0884L22.2801 55.4288L12.8357 3.05568L32.8509 0.553784L43.1578 52.0884Z"
              stroke="#3592fd"
            ></path>
            <path
              d="M32.0832 28.5832H28.5832V32.0832H27.4165V28.5832H23.9165V27.4165H27.4165V23.9165H28.5832V27.4165H32.0832V28.5832Z"
              fill="#3592fd"
            ></path>
          </svg>
        </div>
        <div style="flex: 3;" class="description">
          <span style=" color: #3592fd;">Add new AOI</span>
          <div style="font-size: 13px;">Browse satellite images, indices, weather data and much more</div>
        </div>
      </div>
      <v-btn
        v-else
        color="primary"
        width="100%"
      >
        <v-icon size="22">mdi-shape-polygon-plus</v-icon>
        <span>Create AOI</span>
      </v-btn>
    </div>
    <AoiCreateOptionDialog
      ref="optionDialog"
      :isBoundary="isBoundary"
      @fetchAOI="() => $emit('fetchAOI')"
      @startDraw="startDraw()"
    ></AoiCreateOptionDialog>
  </div>
</template>

<script>
import AoiCreateOptionDialog from './AoiCreateOptionDialog.vue'

export default {
  components: { AoiCreateOptionDialog },
  props: {
    isBoundary: {
      type: Boolean,
      default: true,
    },
    btn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    startDraw() {
      this.$emit('startDraw')
      this.$refs.optionDialog.dialog = false
    },
  },
}
</script>

<style>
.new-aoi {
  width: 100%;
  height: 100px;
  position: relative;
  background: #3592fd33;
  border: 2px dashed #3592fd;
  border-radius: 10px;
}
.new-aoi:hover {
  background: #3592fd66;
  border-color: #3592fd;
}
.description {
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}
</style>
