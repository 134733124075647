<template>
  <v-dialog v-model="dialog" persistent width="550">
    <v-card class="custom-card-bg-gradient" @keydown.enter="updateData">
      <DialogHeader :is-close="true" :title="'Create Season'" @close="dialog = false" />
      <v-card-text>
        <SeasonInfo ref="info" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :loading="loading" color="primary" rounded @click="updateData">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import SeasonInfo from '@/views/monitoring/field-control/season/SeasonInfo.vue'
import { createSeason } from '@/api/aoi-api'

export default {
  components: { SeasonInfo, DialogHeader },
  data() {
    return {
      loading: false,
      field: undefined,
      dialog: false,
    }
  },
  methods: {
    openDialog(field) {
      this.field = field
      this.dialog = true
    },
    async updateData() {
      try {
        if (!this.$refs.info.checkValidate()) return
        this.loading = true
        await createSeason({
          id: this.field.uuid,
          params: this.$refs.info.data,
          projectId: this.$route.params.id,
        })
        this.$emit('fetchSeason')
        this.dialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
