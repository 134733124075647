<template>
  <v-menu offset-y :close-on-content-click="false">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip, attrs }">
          <v-badge :value="isFilter" bottom color="success" dot overlap>
            <v-btn icon x-small v-bind="attrs" v-on="{ ...onMenu, ...onTooltip }">
              <v-icon :size="sizeIcon" :color="isFilter ? 'accent' : ''">mdi-filter-outline</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Filter</span>
      </v-tooltip>
    </template>
    <div class="filter-container">
      <div class="title">
        <h4>Advanced filters</h4>
        <v-spacer> </v-spacer>
        <v-btn class="text-none" :disabled="isClear" @click="clearAll()" small>Clear all</v-btn>
        <v-btn class="text-none ml-2" :loading="loading" :disabled="!isChange" @click="saveFilter()" small
          >Save to this view</v-btn
        >
      </div>
      <div class="content">
        <div v-for="(query, index) in queriesList.filters" :key="index">
          <v-list-item style="height:fit-content" class="mb-2">
            <v-row>
              <v-col
                cols="2"
                style=" display: flex;
                  align-items: center;"
              >
                <div class="ml-3" v-if="index == 0">
                  Where
                </div>
                <v-select
                  v-if="index == 1"
                  dense
                  style="width:50px"
                  hide-details
                  v-model="queriesList.condition"
                  :items="conditions"
                  outlined
                  return-object
                  @change="filter"
                />
                <div class="ml-3" v-if="index > 1">
                  {{ queriesList.condition }}
                </div>
              </v-col>
              <v-col cols="10">
                <FilterGroup
                  style="padding-right:15px!important"
                  ref="group"
                  v-if="query.filters"
                  :item.sync="query"
                  :index="index"
                  @filter="filter()"
                  @updateQueries="(index, group) => updateQueries(index, group)"
                />
                <FilterItem
                  ref="filter"
                  v-else
                  :filter="query"
                  :index="index"
                  @filter="filter()"
                  :isLastItem="queriesList.filters.length == 1"
                  @delete="index => deleteQuery(index)"
                />
              </v-col>
            </v-row>
          </v-list-item>
        </div>
      </div>
      <div class="pa-3" style="display:flex;flex-direction: row;">
        <v-btn class="ma-1 text-none" @click="addNewFilter()" plain>
          <v-icon small>mdi-plus</v-icon>
          <span class="ml-1">New Filter</span>
        </v-btn>
        <v-btn class="ma-1 text-none" @click="addNewGroup()" plain>
          <v-icon small>mdi-plus</v-icon>
          <span class="ml-1">New Group</span>
        </v-btn>
        <v-spacer></v-spacer>
      </div>
    </div>
  </v-menu>
</template>

<script>
import FilterGroup from './FilterGroup.vue'
import FilterItem from './FilterItem.vue'
import { updateCustomFilter, getCustomFilter } from '@/api/settings'
import { mapState } from '@/store/ults'
import isEqual from 'lodash/isEqual'

export default {
  components: { FilterGroup, FilterItem },
  props: {},
  data() {
    return {
      conditions: ['and', 'or'],
      loading: false,
      saveFilters: undefined,
      queriesList: { condition: undefined, filters: [] },
    }
  },
  props: {
    filters: {
      type: Object,
      default: () => ({
        condition: 'and',
        filters: [{ column: undefined, operator: undefined, value: undefined }],
      }),
    },
    sizeIcon: {
      type: Number,
      default: 22,
    },
  },
  watch: {
    queriesList() {
      if (this.queriesList.filters.length == 0) {
        this.queriesList.filters.push({ column: undefined, operator: undefined, value: undefined })
      }
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('AOI', ['queryData']),
    isClear() {
      return !this.isFilter
    },
    isFilter() {
      return !(this.queryData ? (this.queryData.filters ? false : true) : false)
    },
    isChange() {
      if (this.filters.filters.length == 0 && JSON.stringify(this.saveFilters?.filters) == '[{}]') return false
      return !isEqual(this.filters, this.saveFilters)
    },
  },
  mounted() {
    this.getSaveFilter()
  },
  methods: {
    getAOI(validFilters) {
      this.$emit('update:filter', JSON.parse(JSON.stringify(validFilters)))
      this.$emit('getAOI')
    },
    async filter() {
      let validFilters = {
        condition: this.queriesList.condition,
        filters: this.filterValidFilters(this.queriesList.filters),
      }
      if (!isEqual(this.filters, validFilters)) this.getAOI(validFilters)
    },
    async getSaveFilter() {
      try {
        const res = await getCustomFilter(this.$route.params.id)
        if (
          typeof res.data === 'object' &&
          res.data[this.$route.name] &&
          res.data[this.$route.name].filters &&
          res.data[this.$route.name].condition
        ) {
          this.$emit('update:filter', JSON.parse(JSON.stringify(res.data[this.$route.name])))

          this.saveFilters = JSON.parse(JSON.stringify(res.data[this.$route.name]))
          this.queriesList = res.data[this.$route.name]
        } else {
          this.saveFilters = { condition: 'and', filters: [{}] }
          this.queriesList = { condition: 'and', filters: [{}] }
        }
      } catch (e) {
        console.log(e)
      }
      this.filter()
    },
    addNewFilter() {
      this.queriesList.filters.push({ column: undefined, operator: undefined, value: undefined })
    },
    addNewGroup() {
      this.queriesList.filters.push({
        condition: 'and',
        filters: [{ column: undefined, operator: undefined, value: undefined }],
      })
    },
    deleteQuery(index) {
      this.queriesList.filters.splice(index, 1)
      this.filter()
    },

    filterValidFilters(filters) {
      let result = []
      function hasRequiredFields(filter) {
        return (
          filter.column !== undefined &&
          filter.operator !== undefined &&
          filter.value !== undefined &&
          JSON.stringify(filter.value) !== '[]' &&
          filter.value !== '' &&
          filter.value !== null
        )
      }
      for (const filter of filters) {
        if (hasRequiredFields(filter)) {
          result.push(filter)
        } else if (filter.filters) {
          const nestedValidFilters = this.filterValidFilters(filter.filters)
          if (nestedValidFilters.length > 0) {
            result.push({
              ...filter,
              filters: nestedValidFilters,
            })
          }
        }
      }
      return result
    },
    updateQueries(index, group) {
      if (this.queriesList.filters.length == 1) {
        this.queriesList.condition = 'or'
      }
      if (group.filters.length == 0) this.queriesList.filters.splice(index, 1)
      if (this.queriesList.filters.length == 0)
        this.queriesList.filters.push({ column: undefined, operator: undefined, value: undefined })
      this.filter()
    },
    clearAll() {
      this.queriesList.filters = [{ column: undefined, operator: undefined, value: undefined }]
      this.filter()
    },
    async saveFilter() {
      try {
        this.loading = true
        let filters = {
          condition: this.queriesList.condition,
          filters: this.filterValidFilters(this.queriesList.filters),
        }

        let res = await getCustomFilter(this.$route.params.id)
        let custom_filters = res.data && typeof res.data === 'object' && !Array.isArray(res.data) ? res.data : {}
        custom_filters[this.$route.name] = filters
        let params = {
          custom_filters: custom_filters,
        }
        await updateCustomFilter(this.$route.params.id, params)
      } catch (e) {
        console.log(e)
      } finally {
        this.getSaveFilter()
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.content {
  width: 900px;
  max-height: 50vh;
  overflow-y: auto;
  min-height: 120px;
  padding-right: 4px;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 10px;
}
.query-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.filter-container {
  background: var(--v-bgListItem-base);
}
</style>
