<template>
  <v-dialog v-model="dialog" width="1024">
    <v-card :loading="loading" class="custom-card-bg-gradient">
      <DialogHeader :is-close="true" :title="'List Order'" @close="dialog = false" />
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="5"
          item-key="uuid"
          show-expand
          single-expand
          @item-expanded="getStatus"
        >
          <template #[`item.from_date`]="{item}">
            {{ transformDate(item.from_date) }}
          </template>
          <template #[`item.to_date`]="{item}">
            {{ transformDate(item.to_date) }}
          </template>
          <template #[`item.status`]="{item}">
            <v-tooltip v-if="item.status === 'failed'" color="#000000" left>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  :color="getStatusColor(item.status)"
                  class="text-capitalize"
                  label
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <span>{{ item.error }}</span>
            </v-tooltip>
            <v-chip v-else :color="getStatusColor(item.status)" class="text-capitalize" label small>
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.action`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="item.status === 'cancel' || item.status === 'completed'"
                  color="error"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelOrder(item)"
                >
                  <v-icon>icon-close_square</v-icon>
                </v-btn>
              </template>
              <span>Cancel</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="px-0" colspan="7">
              <v-data-table
                :headers="detailHeaders"
                :items="orderData"
                :items-per-page="5"
                :page.sync="page"
                hide-default-footer
                item-key="uuid"
                @page-count="pageCount = $event"
              >
                <template #[`item.date`]="{item}">
                  <v-icon :color="getStatusColor(item.status)" class="mr-2">mdi-memory</v-icon>
                  {{ item.date }}
                </template>
                <template #[`item.status`]="{item}">
                  <v-tooltip v-if="item.status === 'failed'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip :color="getStatusColor(item.status)" small v-bind="attrs" v-on="on">
                        {{ item.status }}
                      </v-chip>
                    </template>
                    <span>{{ item.error }}</span>
                  </v-tooltip>
                  <v-chip v-else :color="getStatusColor(item.status)" small>
                    {{ item.status }}
                  </v-chip>
                </template>
              </v-data-table>
              <div class="text-end py-2 pl-4">
                <v-layout align-center>
                  <div style="max-width: 100px">
                    <v-layout align-center>
                      <span class="caption">Page:</span>
                      <v-text-field
                        v-model="pageSelected"
                        :max="pageCount"
                        class="ml-1"
                        dense
                        flat
                        hide-details
                        label="page"
                        min="1"
                        placeholder="page"
                        solo
                        type="number"
                        @change="changePage"
                      />
                    </v-layout>
                  </div>
                  <v-spacer />
                  <v-pagination v-model.number="page" :length="pageCount" total-visible="5"></v-pagination>
                </v-layout>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { getOrder, getOrderStatus, updateOrder } from '@/api/order'
import Status from '@/utils/status'
import convertDate from '@/utils/convertDate'
import { mapState } from '@/store/ults'

export default {
  name: 'ListOrder',
  components: { DialogHeader },
  data() {
    return {
      dialog: false,
      AOI: {},
      loading: false,
      orderData: [],
      page: 1,
      pageSelected: 1,
      pageCount: 0,
      orderChannel: undefined,
      detailHeaders: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Status',
          align: 'end',
          sortable: false,
          value: 'status',
        },
      ],
      headers: [
        {
          text: 'Services',
          align: 'start',
          sortable: true,
          value: 'service_name',
        },
        {
          text: 'From date',
          align: 'start',
          sortable: true,
          value: 'from_date',
        },
        {
          text: 'To date',
          align: 'start',
          sortable: true,
          value: 'to_date',
        },
        {
          text: 'Frequency',
          align: 'start',
          sortable: false,
          value: 'frequency',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      data: [],
    }
  },
  watch: {
    page(val) {
      this.pageSelected = val
    },
  },
  computed: {
    ...mapState('socket', ['echoChannel']),
  },
  mounted() {
    this.initListen()
  },
  methods: {
    openDialog(AOI) {
      this.AOI = AOI
      this.getOrder()
    },
    changePage(page) {
      if (page < 1) {
        this.page = 1
        this.pageSelected = 1
      } else if (page > this.pageCount) {
        this.page = this.pageCount
        this.pageSelected = this.pageCount
      } else this.page = Number(page)
    },
    async getStatus(rowData) {
      try {
        this.loading = true
        this.orderData = []
        const res = await getOrderStatus({
          projectId: this.$route.params.id,
          id: this.AOI.uuid,
          orderId: rowData.item.uuid,
          payload: { per_page: 'all' },
        })
        this.orderData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    transformDate(date) {
      return convertDate.dateFormatted(date)
    },
    getStatusColor(status) {
      return Status.getColor(status)
    },
    async getOrder() {
      try {
        this.loading = true
        this.data = []
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.AOI.uuid,
          payload: { lifetime: false },
        })
        this.data = res.data
        this.dialog = true
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    initListen() {
      if (this.orderChannel) {
        this.echoChannel.private(this.orderChannel).unsubscribe()
      }
      this.orderChannel = `project.${this.$route.params.id}`
      this.echoChannel.private(`project.${this.$route.params.id}`).listen('.order.changed', e => {
        try {
          if (this.dialog) this.getOrder()
        } catch (e) {
          console.log(e)
        }
      })
    },
    async cancelOrder(service) {
      try {
        this.loading = true
        const res = await updateOrder({
          projectId: this.$route.params.id,
          AOIId: this.AOI.uuid,
          orderId: service.uuid,
        })
        this.data = res.data
        this.dialog = true
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
  },
}
</script>

<style scoped></style>
