<template>
  <v-menu v-model="menu" :position-x="positionX" :position-y="positionY" absolute>
    <v-layout align-center class="px-2 caption" style="background: white; height: 25px;">
      <div v-html="data"></div>
    </v-layout>
  </v-menu>
</template>

<script>
import sleep from '@/utils/sleep'

export default {
  name: 'Popup',
  props: {},
  data: () => ({
    data: undefined,
    menu: false,
    positionX: 0,
    positionY: 0,
  }),
  methods: {
    async open(e, data) {
      this.data = data
      e.preventDefault()
      this.menu = false
      await sleep(10)
      this.positionX = e.clientX
      this.positionY = e.clientY
      await sleep(10)
      this.menu = true
    },
    close() {
      this.menu = false
    },
  },
}
</script>

<style scoped></style>
