<template>
  <v-card v-show="AOIMenu" class="AOI-menu custom-card-bg-gradient" height="100%" width="280" min-width="280">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout v-show="!currentField && !selectFirstAoi" class="fill-height px-2" column>
      <div style="flex: none; width: 100%; height: 50px; border-bottom: 1px solid #5e5669">
        <div class="d-flex align-center fill-height">
          <v-tooltip bottom v-if="inGroup">
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" @click="backToHome">
                <v-icon small>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Back</span>
          </v-tooltip>
          <h4>{{ inGroup ? currentGroup.name : title }}</h4>
          <v-spacer />
          <span v-if="!inGroup" class="mr-2">{{ totalAOIs | formatItem }}</span>
          <IconRegisterAlert
            v-if="!isIndexMonitoring"
            eventRegister="forest_fire_alert"
            :styles="{ height: '22px !important', width: '22px' }"
          />
        </div>
      </div>

      <ListAOI
        ref="listAOI"
        :disable.sync="isCreating"
        :currentGroup.sync="currentGroup"
        :inGroup="inGroup"
        :canOrder="canOrder"
        :isIndexMonitoring="isIndexMonitoring"
        :field.sync="currentField"
        :selectFirstAoi="selectFirstAoi"
        @selectField="selectField"
        @openGroup="openGroup"
        @startDraw="$emit('startDraw')"
        @makeOrder="aoi => $emit('makeOrder', aoi)"
        @addAOIToMap="aoi => $emit('addToMap', aoi)"
        @zoomTo="bbox => zoomTo(bbox)"
        @removeAOI="$emit('removeAOI')"
        @backToHome="backToHome"
      />

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            icon
            large
            color="primary"
            class="btn-create"
            @click="() => $refs.createAOI.openDialog()"
          >
            <v-icon size="22">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Create AOI</span>
      </v-tooltip>
    </v-layout>

    <FieldControl
      v-show="currentField || selectFirstAoi"
      ref="fieldControl"
      :customLegend.sync="customLegend"
      :field.sync="currentField"
      :listField.sync="listField"
      :dates.sync="chooseDates"
      :drawing.sync="drawing"
      :index.sync="index"
      :is-draw="true"
      :legendMenu.sync="legendMenu"
      :loading.sync="isLoading"
      :mapLegend.sync="mapLegend"
      :sync.sync="sync"
      :typeIndex.sync="typeIndex"
      :cloudPercent.sync="cloud"
      :selectFirstAoi="selectFirstAoi"
      @areaUnit="unit => $emit('areaUnit', unit)"
      @removeLayer="layerId => $emit('removeLayer', layerId)"
      @addAOIToMap="aoi => $emit('addAOIToMap', aoi)"
      @changeIndex="$emit('changeIndex')"
      @changeStatusMap="$emit('changeStatusMap')"
      @destroyChart="$emit('destroyChart')"
      @displayChartData="data => $emit('displayChartData', data)"
      @download="type => $emit('download', type)"
      @fetchData="$emit('fetchData')"
      @startGuide="guide => $emit('startGuide', guide)"
      @openAddForm="$emit('openAddForm')"
      @openDrawTool="$emit('openDrawTool')"
      @removeAll="$emit('removeAll')"
      @selectMonth="$emit('selectMonth')"
      @updateDate="$emit('updateDate')"
      @showLayerControl="$emit('showLayerControl')"
      @closeColorPicker="$emit('closeColorPicker')"
      @mapSize="$emit('mapSize')"
      @openDetect="$emit('openDetect')"
      @refreshMap="$emit('refreshMap')"
      @zoomToField="bbox => zoomTo(bbox)"
      @resetDraw="$emit('resetDraw')"
    />

    <AoiCreateOptionDialog ref="createAOI" @startDraw="() => $emit('startDraw')"  />
  </v-card>
</template>
<script>
import IconRegisterAlert from '@/components/notification/icon-register-alert.vue'
import AddingAoiOption from '@/components/AddingAoiOption/index.vue'
import ListAOI from '@/views/aoi/ListAOI.vue'
import AoiCreateOptionDialog from '@/components/AddingAoiOption/AoiCreateOptionDialog.vue'
import FieldControl from './monitoring/FieldControl.vue'
import { checkGrammar } from '@/utils/stringHandle'

export default {
  components: {
    IconRegisterAlert,
    AddingAoiOption,
    ListAOI,
    AoiCreateOptionDialog,
    FieldControl,
  },
  props: {
    title: { type: String, default: 'AOI Management' },
    AOIMenu: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    isCreating: { type: Boolean, default: false },
    canCollapse: { type: Boolean, default: true },
    canOrder: { type: Boolean, default: true },
    isIndexMonitoring: { type: Boolean, default: false },

    // field control
    selectFirstAoi: { type: Boolean, default: false },
    isDraw: { type: Boolean, default: false },
    drawing: { type: Boolean, default: false },
    listField: {
      type: Array,
      default: () => [],
    },

    cloudPercent: {
      type: Array,
      default: () => [0, 100],
    },
    field: {},
    dates: { type: Array, default: () => [] },
    index: { type: String, default: '' },
    typeIndex: {},
    legendMenu: {},
    mapLegend: { type: Array, default: () => [] },
    customLegend: {},
    sync: {},
  },
  computed: {
    totalAOIs() {
      return this.$store.getters['AOI/totalAOICount']
    },
    cloud: {
      get() {
        return this.cloudPercent
      },
      set(val) {
        this.$emit('update:cloudPercent', val)
      },
    },
    isLoading: {
      get() {
        return this.loading
      },
      set(val) {
        this.$emit('update:loading', val)
      },
    },
    currentField: {
      get() {
        return this.field
      },
      set(val) {
        this.$emit('update:field', val)
      },
    },
    chooseDates: {
      get() {
        return this.dates
      },
      set(val) {
        this.$emit('update:dates', val)
      },
    },
    currentGroup: {
      get() {
        return this.$store.state.AOI.currentGroup
      },
      set(val) {
        this.$store.commit('AOI/SET_STATE_PROPERTY', { key: 'currentGroup', value: val })
      },
    },
    inGroup() {
      return Object.keys(this.currentGroup).length > 0
    },
  },
  methods: {
    zoomTo(bbox) {
      if (this.isIndexMonitoring) {
        this.$emit('zoomToField', bbox)
      } else {
        this.$emit('zoomTo', bbox)
      }
    },
    // field control
    getAOI() {
      this.$refs.listAOI.getAOI()
    },
    async selectField(field) {
      if (field.display) this.$refs.listAOI.changeDisplayAOI(field)
      this.currentField = field
      this.$nextTick(() => {
        // if(!this.selectFirstAoi)
        this.$refs.fieldControl.selectField(field)
      })
    },
    // base
    backToHome() {
      this.currentGroup = {}
    },
    openGroup(group) {
      this.currentGroup = group
    },
  },
  filters: {
    formatItem(value) {
      return checkGrammar(value, 'item')
    },
  },
}
</script>
<style scoped>
.btn-create {
  background-color: var(--v-primary-base);
  position: absolute;
  bottom: 8px;
  right: 6px;
  z-index: 1;
}
</style>
