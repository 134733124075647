var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-layout',{staticClass:"dialog-container",staticStyle:{"font-size":"12px","background":"var(--v-bgListItem-base)","border-radius":"8px"},on:{"mouseup":function (event) { return _vm.stopDrag(event); },"mousemove":function (event) { return _vm.drag(event); }}},[_c('h3',[_vm._v("Edit Legend")]),_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{ref:"bar",staticClass:"bar-container"},_vm._l((_vm.updateForm.data),function(item,i){return _c('v-tooltip',{key:i,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({ref:("barItem" + i),refInFor:true,staticClass:"bar-item",style:({
              width: _vm.getBarWidth(i) + '%',
              backgroundColor: item.color,
            })},'div',attrs,false),on),[(i > 0 && i < _vm.updateForm.data.length - 1)?_c('div',{staticClass:"edge left-edge",on:{"mousedown":function (event) { return _vm.startDrag(i, event); }}}):_vm._e(),_c('span',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(_vm._s(_vm.updateForm.data[i + 1] ? ((Math.round(_vm.updateForm.data[i].min_value * 100) / 100) + " - " + (Math.round( _vm.updateForm.data[i + 1].min_value * 100 ) / 100)) : ("> " + (Math.round(_vm.updateForm.data[i].min_value * 100) / 100))))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.updateForm.data[i + 1] ? ((Math.round(_vm.updateForm.data[i].min_value * 100) / 100) + " - " + (Math.round( _vm.updateForm.data[i + 1].min_value * 100 ) / 100)) : ("> " + (Math.round(_vm.updateForm.data[i].min_value * 100) / 100))))])])}),1),_c('v-layout',{staticClass:"list-container"},_vm._l((_vm.updateForm.data),function(item,i){return _c('div',{key:i,staticClass:"list-item",attrs:{"align-center":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.color),expression:"item.color"}],staticClass:"mr-2",staticStyle:{"width":"25px","height":"25px"},attrs:{"type":"color"},domProps:{"value":(item.color)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "color", $event.target.value)}}}),_c('div',{staticClass:"d-flex",staticStyle:{"width":"200px","display":"flex","justify-content":"center","align-items":"center"}},[_c('v-text-field',{staticStyle:{"width":"50px!important"},attrs:{"prepend-inner-icon":"ri-hourglass-fill","type":"number","min":_vm.getMin(i),"step":"0.01","max":_vm.getMax(i),"error-messages":(item.min_value < _vm.getMin(i) || item.min_value > _vm.getMax(i)) &&
              i !== 0 && i !== _vm.updateForm.data.length - 1
                ? 'wrong range value'
                : ''},model:{value:(item.min_value),callback:function ($$v) {_vm.$set(item, "min_value", _vm._n($$v))},expression:"item.min_value"}}),(_vm.updateForm.data[i + 1])?_c('div',[_vm._v("-")]):_vm._e(),(_vm.updateForm.data[i + 1])?_c('v-text-field',{staticStyle:{"width":"50px!important"},attrs:{"prepend-inner-icon":"ri-hourglass-fill","type":"number","min":_vm.getMin(i + 1),"step":"0.01","placeholder":"Max value","max":_vm.getMax(i + 1),"error-messages":(item.min_value < _vm.getMin(i) || item.min_value > _vm.getMax(i)) &&
              i !== 0 && i !== _vm.updateForm.data.length - 1
                ? 'wrong range value'
                : ''},model:{value:(_vm.updateForm.data[i + 1].min_value),callback:function ($$v) {_vm.$set(_vm.updateForm.data[i + 1], "min_value", _vm._n($$v))},expression:"updateForm.data[i + 1].min_value"}}):_vm._e()],1),_c('v-text-field',{staticStyle:{"font-size":"14px","width":"150px"},attrs:{"flat":"","solo":"","label":"Name","hide-details":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-btn',{attrs:{"height":"40","min-height":"0","min-width":"0","text":"","width":"40"},on:{"click":function($event){return _vm.deleteRange(item)}}},[_c('v-icon',[_vm._v("mdi-minus-box-outline")])],1),_c('v-btn',{attrs:{"height":"40","min-height":"0","min-width":"0","text":"","width":"40"},on:{"click":function($event){return _vm.addLegendRange(i + 1)}}},[_c('v-icon',[_vm._v("mdi-plus-box-outline ")])],1)],1)}),0),_c('div',{staticClass:"d-flex"},[_c('v-btn',{on:{"click":_vm.undo}},[_c('v-icon',[_vm._v(" mdi-undo-variant")])],1),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"ml-auto",staticStyle:{"color":"red"},attrs:{"variant":"text"},on:{"click":_vm.cancelEditLegend}},[_vm._v("Cancel")]),_c('v-btn',{staticStyle:{"color":"green"},attrs:{"variant":"text"},on:{"click":_vm.applyEditLegend}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }