import api from '@/api/api'
import config from '@/config'
export async function getSettings() {
    return await api.get(`${config.backend}/system/setting-fields`)
  }
export async function updateCustomFilter(id,params) {
  return await api.put(`${config.backend}/projects/${id}/setting/custom-filters`,params)
}
export async function getCustomFilter(id) {
  return await api.get(`${config.backend}/projects/${id}/setting/custom-filters`)
}