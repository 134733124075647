<template>
  <v-layout class="fill-height">
    <Monitoring  :selectFirstAoi="true"/>
  </v-layout>
</template>
<script>
import Monitoring from '@/views/monitoring/Monitoring.vue'

export default {
  components: { Monitoring },
}
</script>

<style scoped></style>
