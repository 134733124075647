<template>
  <v-dialog v-model="dialog" fullscreen persistent>
    <v-card :disabled="loading" :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Auto detect'" @close="dialog = false" />
      <v-card-text style="height: calc(100% - 75px)">
        <v-layout class="fill-height">
          <div
            class="pa-3"
            style="flex: none; width: 475px; height: 100%; border: 1px solid #2F3241; border-radius: 8px"
          >
            <v-layout class="fill-height" column>
              <v-btn
                :disabled="zoomLevel < 15 || saveLoading"
                :loading="detectLoading"
                color="primary"
                width="100%"
                @click="detectFarm"
              >
                Detect Crop
              </v-btn>
              <div class="mt-1" style="width: 100%; font-size: 12px; color: yellow; text-align: end">
                Zoom level must be >= 15
              </div>
              <v-divider class="mt-2" />
              <v-checkbox
                v-model="selectAll"
                :disabled="!features.length"
                :indeterminate="indeterminate"
                color="success"
                dense
                hide-details
                label="Select all"
                @change="changeSelectAll"
              ></v-checkbox>
              <v-divider class="mt-2" />
              <v-list height="100%" style="overflow-y: auto; background-color: transparent">
                <v-list-item v-for="(item, index) in features" :key="index" class="py-1">
                  <v-layout align-center>
                    <v-checkbox v-model="item.active" color="success" @change="selectItem"></v-checkbox>
                    <div style="width: 62px; height: 62px; border: 1px solid #6c6c6c; border-radius: 8px">
                      <MapPreview :id="'AOI' + index" :geojson="item" />
                    </div>
                    <div class="pl-4 text-truncate" style="width: 80px;">{{ item.properties.name }}</div>
                    <div style="width: 220px">
                      <v-combobox
                        v-model="item.properties.group"
                        :items="groups"
                        class="pl-3"
                        dense
                        hide-details
                        label="Select group"
                        outlined
                        placeholder="Select group"
                      >
                      </v-combobox>
                    </div>
                    <v-spacer />
                    <v-btn icon small @click="highLightLayer(item)">
                      <v-icon size="22">mdi-crosshairs-gps</v-icon>
                    </v-btn>
                  </v-layout>
                  <!--          <MapPreview id="index" geojson=""></MapPreview>-->
                </v-list-item>
              </v-list>
              <v-spacer />
              <v-divider class="my-2" />
              <v-btn :disabled="detectLoading" :loading="saveLoading" color="primary" width="100%" @click="saveData">
                Submit
              </v-btn>
            </v-layout>
          </div>
          <v-layout class="fill-height pl-4">
            <Map
              v-if="formLoaded"
              ref="map"
              @formData="data => (screenData = data)"
              @zoomLevel="zoomLv => (zoomLevel = zoomLv)"
            />
          </v-layout>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import MapPreview from '@/components/MapPreview.vue'
import sleep from '@/utils/sleep'
import { detectFarmBoundary } from '@/api/interactive-api'
import utils from '@/utils/genUUID'
import bbox from '@turf/bbox'
import Map from '@/components/Map.vue'
import { getGroupAOI, saveUploadAOI } from '@/api/aoi-api'

export default {
  components: { Map, MapPreview, DialogHeader },
  data() {
    return {
      loading: false,
      groups: [],
      detectLoading: false,
      saveLoading: false,
      selectAll: false,
      formLoaded: false,
      screenData: undefined,
      features: [],
      dialog: false,
      zoomLevel: 0,
      indeterminate: false,
    }
  },
  props: {},
  computed: {},
  methods: {
    async openDialog() {
      this.dialog = true
      await this.getGroup()
      await sleep(200)
      this.formLoaded = true
    },
    highLightLayer(feature) {
      this.$refs.map.addHighlightLayer(feature.geometry)
    },
    async getGroup() {
      try {
        this.loading = true
        const res = await getGroupAOI({ projectId: this.$route.params.id })
        this.groups = res.data.filter(item => item.group !== '').map(item => item.group);
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    selectItem() {
      this.selectAll = this.features.filter(val => val.active).length === this.features.length
      this.indeterminate =
        !!this.features.length && this.features.filter(val => val.active).length < this.features.length
    },
    changeSelectAll(status) {
      this.features.forEach(val => (val.active = status))
    },
    async detectFarm() {
      try {
        this.detectLoading = true
        this.$refs.map.removeAllLayer()
        this.$refs.map.getMapFormData()
        await sleep(700)
        const res = await detectFarmBoundary(this.screenData)
        this.$refs.map.addGeoJsonToMap('farm', res.data, 'red', utils.getUUID(), 'line', true)
        let bound = bbox(res.data)
        this.$refs.map.submitZoom(bound)
        this.features = res.data.features
        this.features.forEach((feature, ind) => {
          feature.active = false
          feature.properties.name = 'Field' + ind
          feature.properties.group = undefined
        })
      } catch (e) {
        this.features = []
        console.log(e)
      } finally {
        this.detectLoading = false
      }
    },
    async saveData() {
      try {
        let selectedFeatures = [...this.features.filter(val => val.active)]
        this.saveLoading = true
        selectedFeatures.forEach(feature => {
          delete feature.active
          feature.name = feature.properties.name
          feature.group = feature.properties.group
        })
        await saveUploadAOI({
          projectId: this.$route.params.id,
          params: { features: selectedFeatures },
        })
        this.$emit('fetchAOI')
        this.dialog = false
      } catch (e) {
      } finally {
        this.saveLoading = false
      }
    },
  },
}
</script>

<style scoped></style>
