<template>
  <div class="filter-container">
    <v-form ref="form" @submit.prevent>
      <v-row no-gutters class="query-item">
        <v-col cols="3">
          <v-select
            dense
            hide-details
            v-model="filter.column"
            :items="columns"
            outlined
            placeholder="Column"
            item-text="name"
            item-value="code"
            @change="getValues"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            dense
            hide-details
            v-model="filter.operator"
            outlined
            :items="operators"
            item-text="name"
            item-value="code"
            placeholder="Condition"
            @change="() => $emit('filter')"
          />
        </v-col>
        <v-col cols="5">
          <v-autocomplete
            v-if="filter.column === 'group'"
            dense
            hide-details
            multiple
            v-model="filter.value"
            outlined
            clearable
            placeholder="Value"
            :items="values"
            return-object
            small-chips
            @change="() => $emit('filter')"
          >
            <template v-slot:selection="{ item }">
              <div>
                <v-chip small :key="item.value" style="margin-right: 4px">
                  {{ item }}
                </v-chip>
              </div>
            </template>
          </v-autocomplete>
          <v-text-field
            v-else
            dense
            hide-details
            multiple
            v-model="filter.value"
            outlined
            clearable
            placeholder="Value"
            :items="values"
            return-object
            small-chips
            @change="() => $emit('filter')"
          />
        </v-col>
        <v-col cols="1" style="max-width: 0">
          <v-icon
            v-if="!isLastItem"
            class="ml-1"
            @click="
              () => {
                $emit('delete', index)
                $emit('filter')
              }
            "
            >mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapState } from '@/store/ults'

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: () => 0,
    },
    isLastItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      operatorsList: [
        { name: 'Is', code: 'is' },
        { name: 'Is not', code: 'is not' },
        { name: 'In', code: 'in' },
        { name: 'Not in', code: 'not in' },
        { name: 'Contains', code: 'contains' },
        { name: "Doesn't contain", code: "doesn't contain" },
      ],
      values: [],
      columns: [
        { name: 'Group', code: 'group' },
        { name: 'Name', code: 'name' },
      ],
    }
  },
  watch: {
    allGroup(val) {
      if (this.filter.column === 'group') {
        this.values = this.allGroup
      }
    },
  },
  computed: {
    ...mapState('AOI', ['allGroup']),
    operators() {
      if (this.filter.column === 'group') {
        return this.operatorsList.filter(op => !['is', 'is not'].includes(op.code))
      }
      return this.operatorsList.filter(op => !['in', 'not in'].includes(op.code))
    },
  },
  mounted() {
    if (this.filter.column === 'group') {
      this.values = this.allGroup
    }
  },
  methods: {
    getValues() {
      this.filter.value = undefined
      if (this.filter.column === 'group') {
        this.filter.operator = 'in'
        this.values = this.allGroup
      } else {
        this.filter.operator = 'is'
      }
      this.$emit('filter')
    },
  },
}
</script>

<style scoped>
.filter-container {
  width: 100%;
}

.query-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 25px;
}
</style>
