<template>
  <v-dialog v-if="updateAOIDialog" v-model="updateAOIDialog" persistent width="500">
    <v-card :disabled="loading" :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Update Information'" @close="updateAOIDialog = false" />
      <v-card-text>
        <v-layout align-center justify-center>
          <div style="width: 275px; height: 220px">
            <v-img :src="require('@/assets/svg/create.svg')" />
          </div>
        </v-layout>
        <AOIInfo :item.sync="currentItem" @submit="submitUpdate" :type="type" :dialog="updateAOIDialog"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import AOIInfo from '@/views/aoi/AOIInfo.vue'
import { updateAOI } from '@/api/aoi-api'

export default {
  name: 'UpdateAOIDialog',
  components: { AOIInfo, DialogHeader },
  data() {
    return {
      loading: false,
      updateAOIDialog: false,
      currentItem: {},
      type:''
    }
  },
  watch: {
    updateAOIDialog(val) {
      if (!val) this.currentItem = {}
    },
  },
  methods: {
    openDialog(item, type) {
      this.currentItem = { ...item }
      this.type = type
      this.updateAOIDialog = true
    },
    async submitUpdate(item) {
      try {
        this.loading = true
        await updateAOI({ id: item.uuid, params: item, projectId: this.$route.params.id })
        await this.$emit('fetchAOI')
      } catch (e) {
      } finally {
        this.updateAOIDialog = false
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
