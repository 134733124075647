<template>
  <v-layout ref="cpChart" class="fill-height" style="position: relative">
    <v-btn
      class="ml-3"
      icon
      style="position: absolute; top: 10px; right: 8px; z-index: 2"
      title="fullscreen"
      x-small
      @click="fullScreen"
    >
      <v-icon size="18">{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-arrow-expand' }}</v-icon>
    </v-btn>
    <div class="pa-3" style="flex: none; width: 100%; height: 100%; border: 1px solid #7e91ff; border-radius: 8px">
      <canvas ref="cmChart" style="height: 100%; width: 99%; flex: none; max-height: 100%; max-width: 99%"></canvas>
    </div>
  </v-layout>
</template>

<script>
import Chart from 'chart.js/auto'
import { transparentize } from '@/utils/utils'
import ElemFullScreen from '@/utils/ElemFullScreen'
import sleep from '@/utils/sleep'

export default {
  computed: {},
  data() {
    return {
      isFullscreen: false,
      created: false,
      cmChart: undefined,
      labels: [],
      chartData: [],
      scales: {
        x: {
          stacked: true,
          grid: {
            color: '#2F3241',
            display: true,
          },
          ticks: {
            color: 'white',
            font: {
              size: 9,
            },
          },
        },
      },
    }
  },
  mounted() {
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement
    })
  },
  props: {
    legend: { type: Boolean, default: true },
    legendPosition: { type: String, default: 'top' },
  },
  methods: {
    fullScreen() {
      ElemFullScreen.setFullscreenRef(this.$refs.cpChart)
    },
    updateChart(labels, data) {
      this.cmChart.data.labels = labels
      this.chartData = []
      this.transformData(labels, data)
      this.cmChart.data.datasets = this.chartData
      this.cmChart.update()
      this.cmChart.resize()
    },
    createChart(labels, data) {
      this.transformData(labels, data)
      this.displayChart()
      this.created = true
    },
    transformData(labels, data) {
      this.labels = labels
      this.chartData = []
      data.forEach(item => {
        if (item.yAxisID) {
          this.scales[item.yAxisID] = {
            type: 'linear',
            position: item.position ? item.position : 'left',
            offset: true,
            offsetAmount: 50,
            title: {
              color: item.color,
              text: item.legend,
              display: true,
            },
            stacked: item.stacked ? item.stacked : false,
            grid: {
              color: '#2F3241',
              display: !item.grid ? item.grid : true,
            },
            ticks: {
              color: item.color,
              font: {
                size: 9.5,
              },
            },
          }
        } else {
          this.scales['y'] = {
            type: 'linear',
            position: item.position ? item.position : 'left',
            offset: true,
            offsetAmount: 50,
            title: {
              color: item.color,
              text: item.legend,
              display: true,
            },
            stacked: false,
            grid: {
              color: '#2F3241',
              display: !item.grid ? item.grid : true,
            },
            ticks: {
              color: item.color,
              font: {
                size: 9.5,
              },
            },
          }
        }
        if (item.type === 'line') {
          this.chartData.unshift({
            type: 'line',
            label: item.label ? item.label : '',
            data: item.data,
            fill: item.fill ? item.fill : false,
            tension: item.tension ? item.tension : 0,
            pointRadius: item.radius ? item.radius : 0,
            pointHoverRadius: 4,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
          })
        } else if ((item.type = 'bar')) {
          this.chartData.unshift({
            type: 'bar',
            label: item.label ? item.label : '',
            data: item.data,
            fill: item.color,
            backgroundColor: transparentize(item.color, 0.5),
            borderColor: item.color,
            yAxisID: item.yAxisID ? item.yAxisID : 'y',
            stack: item.stack,
          })
        }
      })
    },
    displayChart() {
      let ctx = this.$refs.cmChart
      let data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      this.cmChart = new Chart(ctx, {
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            // let currentIndex = this.months.find(month => month.date === data.labels[e.index])
            // if (currentIndex) this.selectMonth(currentIndex)
          },
          plugins: {
            legend: {
              display: this.legend,
              position: this.legendPosition,
            },
          },
          scales: this.scales,
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
  },
}
</script>

<style scoped></style>
