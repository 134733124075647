<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        class="mr-2 alert-btn"
        color="error"
        x-small
        @click.stop="goToSettingAlert"
        :style="styles"
      >
        <v-icon size="20" class="mr-1">mdi-alert-plus-outline</v-icon>
      </v-btn>
    </template>
    <span>Register Alert</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },
    eventRegister: {
      type: String,
      default: 'forest_fire_alert'
    },
  },
  methods: {
    goToSettingAlert() {
      this.$store.commit('notification/SET_STATE_PROPERTY', { property: 'selectedEvent', value: this.eventRegister === 'forest_fire_alert' ? 0 : 1 })
      this.$store.commit('auth/SET_CURRENT_TAB', 2) // to notification tab
      this.$router.push('/pages/account-settings')
    },
  }
}
</script>
