<template>
  <v-select
    v-model="year"
    :chips="$attrs['chips']"
    :dense="$attrs['dense']"
    :hide-details="$attrs['hide-details']"
    :items="years"
    :label="$attrs['label']"
    :multiple="$attrs['multiple']"
    :outlined="$attrs['outlined']"
    :placeholder="$attrs['placeholder']"
    :rules="rules"
    :small-chips="$attrs['small-chips']"
    :solo="$attrs['solo']"
    :solo-inverted="$attrs['solo-inverted']"
    background-color="#424242"
    item-color="secondary"
  >
    <template v-slot:item="{ item }">
      <v-layout align-center justify-center>
        {{ item }}
      </v-layout>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'YearPicker',
  data() {
    return {
      years: [],
    }
  },
  props: {
    value: { type: Number, default: undefined },
    rules: { type: Array, default: () => [] },
  },
  computed: {
    year: {
      get() {
        return this.value
      },
      set(val) {
        this.value = val
        this.$emit('input', this.value)
      },
    },
  },
  created() {
    let currentYear = new Date().getFullYear()
    this.years = [...Array(currentYear - 2016 + 1).keys()].map(x => x + 2016).reverse()
  },
}
</script>

<style scoped></style>
