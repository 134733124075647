<template>
  <v-form ref="formData" v-model="valid" onSubmit="return false;">
    <v-text-field
      v-model="data.name"
      :rules="[rules.required]"
      dense
      label="Season name"
      outlined
      placeholder="Season name"
    ></v-text-field>
    <v-layout class="pt-2">
      <v-layout class="pr-1" column style="flex: 1">
        <v-text-field
          v-model="data.crop_type"
          :rules="[rules.required]"
          dense
          label="Crop name"
          outlined
          placeholder="Crop name"
        ></v-text-field>
        <DatePicker v-model="data.sowing_date" dense label="Sowing date" outlined placeholder="Sowing date" />
        <v-select
          v-model="data.irrigation_type"
          :items="irrigationTypes"
          dense
          item-text="value"
          item-value="value"
          label="Irrigation type"
          outlined
          placeholder="Irrigation type"
        >
          <template #item="data">
            <v-tooltip color="#000000c4" left max-width="400">
              <template #activator="{ on, attrs }">
                <v-layout v-bind="attrs" wrap v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.value }}</v-list-item-title>
                  </v-list-item-content>
                </v-layout>
              </template>
              <span>{{ `${data.item.info}` }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-layout>
      <v-layout class="pl-1" column style="flex: 1">
        <v-text-field
          v-model="data.maturity"
          :rules="[rules.required]"
          dense
          label="Maturity"
          outlined
          placeholder="Maturity"
        ></v-text-field>
        <DatePicker
          v-model="data.harvesting_date"
          dense
          label="Harvesting date"
          outlined
          placeholder="Harvesting date"
        />
        <v-select
          v-model="data.tillage_type"
          :items="tillageTypes"
          dense
          item-text="value"
          item-value="value"
          label="Tillage type"
          outlined
          placeholder="Tillage type"
        >
          <template #item="data">
            <v-tooltip color="#000000c4" left max-width="400">
              <template #activator="{ on, attrs }">
                <v-layout v-bind="attrs" wrap v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.value }}</v-list-item-title>
                  </v-list-item-content>
                </v-layout>
              </template>
              <span>{{ `${data.item.info}` }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-layout>
    </v-layout>
  </v-form>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'

export default {
  components: { DatePicker },
  data() {
    return {
      valid: false,
      data: {
        name: undefined,
        crop_type: undefined,
        irrigation_type: undefined,
        sowing_date: undefined,
        maturity: undefined,
        harvesting_date: undefined,
        tillage_type: undefined,
      },
      tillageTypes: [
        {
          value: 'Conservation tillage',
          info:
            'In conservation tillage, at least 30% of crop residue remains on the ground surface. It requires nearly twice less machinery passes compared to conventional method and results in reduced water runoff and soil erosion, improved soil health, and fewer production costs.',
        },
        {
          value: 'Intensive tillage',
          info:
            'A conventional method that breaks up the soil, leaving up to 15% of crop residue on top. The purpose is to create a uniform, residue-free seedbed fit for planting and bury weed seed. It involves deep ploughing, discing, ripping, and other techniques alongside a broad set of implements.',
        },
        {
          value: 'Mulch-till',
          info:
            'This method involves several tillage trips to manipulate the soil for planting and leaving the surface covered with a layer of organic matter (crop residue, leaves, grass clippings) that serves as mulch.',
        },
        {
          value: 'No-till',
          info:
            "No-till is a method when crops are seeded into the previous year's crop residue left as mulch. The only soil disturbance is the planting operation done with a special no-till equipment.",
        },
        {
          value: 'Reduced tillage',
          info:
            'This method causes minimum soil disturbance; 15-30% crop residue is left on the ground to protect the topsoil. In practice, it varies from fewer tillage passes to zero tillage, which ultimately prevents soil erosion and supports soil biology.',
        },
        {
          value: 'Ridge-till',
          info:
            'This method is suitable for spring row crops. It requires loosening soil and shaping ridges, or raised beds, a process also known as scalping. Crops are planted on ridge tops, while furrows are left in between to remove excess water.',
        },
        {
          value: 'Rotational tillage',
          info:
            "To improve yields and soil's physical and chemical properties, farmers often alternate tillage systems, e.g. rotate no-till and conventional tillage every season.",
        },
        {
          value: 'strip-till',
          info:
            'Strip-tilling is a midpoint between no-till and conventional tillage. Shallow strips (usually 6-12 inches in width and depth) are tilled for planting row crops, while the soil and crop residue between the strips are left undisturbed.',
        },
        {
          value: 'Zone tillage',
          info:
            'Tilling a narrow strip of soil right below the crop row with further crop planting in raised beds is called zone tillage. The tilled zones are narrower (around 5 inches) compared to the strips in the strip-till method, which aims to create a well-tilled seedbed. Zone tillage is implemented to agitate the soil, and ultimately reduce soil compaction and improve internal soil drainage.',
        },
      ],
      irrigationTypes: [
        {
          value: 'Drip irrigation',
          info:
            "Drip irrigation is the delivery of water and nutrients (fertigation) to the plant's root zone drop by drop. It minimizes evaporation and runoff and is widely considered as the most efficient irrigation method.",
        },
        {
          value: 'Center pivot irrigation',
          info:
            'This method uses sprinklers mounted on a long pipe on wheels relatively high above the ground. The structure slowly moves in a circular pattern to cover the entire field (given that the field itself has a circular shape). The "pivot" point is set in place at the field\'s center and serves as the main water supply conduit.',
        },
        {
          value: 'Hose-end sprinklers',
          info:
            'Opposite to lawn sprinklers that are set in place, a hose-end sprinkler is portable. Basically, different types of sprinklers can be connected to a simple garden hose so the water is sprayed in the direction you want.',
        },
        {
          value: 'Lateral move irrigation',
          info:
            'This method looks very similar to center pivot irrigation, the only difference being that the structure does not move in a circular pattern, but instead slowly "sweeps" the entirety of a rectangular-shaped field.',
        },
        {
          value: 'Lawn sprinklers irrigation',
          info:
            'A traditional lawn sprinkler is fixed in place with most of the components hidden below the ground for aesthetic purposes. Each sprinkler head covers a specific area called "zone". You need several sprinklers to cover the entire field.',
        },
        {
          value: 'Micro-irrigation',
          info:
            'Micro-irrigation allows for delivering relatively small amounts of water to the plant leaves using low pressure piped network.',
        },
        {
          value: 'Sprinklers irrigation',
          info:
            'This type of irrigation imitates rainfall where tiny drops of water are sprinkled at high pressure over the plants. Sprinklers can be set in place or mounted on moving platforms, depending on your needs.',
        },
        {
          value: 'Subirrigation',
          info:
            "This method is useful when you need to artificially raise the water table to the plant's root zone. By installing a system of pumps, canals, weirs, and gates you can apply water below the soil surface when needed. Generally, subirrigation is preferred in areas with naturally occuring high water table, such as river valleys and lowlands.",
        },
        {
          value: 'Surface irrigation',
          info:
            'Surface or flood irrigation (basin, border, furrow, uncontrolled flooding) is applying water to the field using gravity. Water flows freely following the natural land slope and floods the entire field.',
        },
      ],
      rules: {
        required: value => !!value || 'Item is required',
      },
    }
  },
  methods: {
    checkValidate() {
      this.$refs.formData.validate()
      return this.valid
    },
  },
}
</script>

<style scoped></style>
