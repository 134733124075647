const status = {
  getColor(status) {
    switch (status) {
      case 'created':
        return '#1F618D'
      case 'completed':
      case 'success':
      case 'complete':
        return '#196F3D'
      case 'cancel':
        return 'warning'
      case 'pending':
      case 'outed':
        return 'info'
      case 'progress':
      case 'processing':
        return 'accent'
      case 'failed':
        return 'error'
      case 'resubmited':
        return '#8E44AD'
    }
  },
}
export default status
