const STATUS = [
  {
    name: 'Pending Farms',
    value: 1,
  },
  {
    name: 'Verified Farms',
    value: 2,
  },
  {
    name: 'Modified Farms',
    value: 3,
  },
  {
    name: 'Rejected Farms',
    value: 4,
  },
]
export default STATUS
