<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent width="1440">
    <v-card :disabled="loading" :loading="loading" class="custom-card-bg-gradient" width="100%">
      <DialogHeader :is-close="true" :title="'Create AOI by Admin Boundary'" @close="dialog = false" />
      <v-card-text style="height: calc(90vh - 70px); overflow-y: hidden">
        <v-row style="height: 100%; overflow-y: auto">
          <v-col class="pb-0" cols="12" md="4" sm="12">
            <v-layout column fill-height @keyup.enter="saveAOI">
              <v-layout align-center class="img-top" justify-center>
                <div style="width: 275px; height: 220px">
                  <v-img :src="require('@/assets/svg/create.svg')" />
                </div>
              </v-layout>
              <v-divider class="mb-3" />
              <v-form ref="formData" v-model="valid" >
                <v-layout>
                  <v-row style="height: 100%">
                    <v-col class="pb-0" cols="12" md="6" sm="12">
                      <v-text-field
                        v-model="data.name"
                        :rules="[rules.nameRequired]"
                        dense
                        label="Name"
                        outlined
                        placeholder="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="6" sm="12">
                      <v-combobox
                        v-model="data.group"
                        :items="groups"
                        dense
                        hide-details
                        label="Select group"
                        outlined
                        placeholder="Select group"
                        small-chips
                      >
                        <template v-slot:selection="data">
                          <v-chip color="primary" small>
                            <div class="caption-light">{{ data.item }}</div>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-form>
              <v-layout align-center>
                Admin boundary
                <v-divider class="ml-2" />
              </v-layout>
              <AdministratorBoundary :multiple="false" @geometry="addGeojsonToMap" />
              <v-spacer />
              <v-btn :loading="loading" class="mt-2" color="primary" rounded @click="saveAOI">
                Submit
              </v-btn>
            </v-layout>
          </v-col>
          <v-col class="pb-0" cols="12" md="8" sm="12" style="min-height: 100%; position: relative">
            <v-overlay :value="!showMap" absolute>
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <div v-if="showMap" style="width: 100%; height: 100%; border-radius: 8px">
              <MapPreview ref="map" />

              <!--              <CardMapView ref="map" :show-layer-control="false" />-->
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import AdministratorBoundary from '@/components/AdministratorSearch.vue'
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import sleep from '@/utils/sleep'
import { createAOI } from '@/api/aoi-api'
import { mapState } from '@/store/ults'
import MapPreview from '@/components/MiniMap.vue'

export default {
  components: { MapPreview, CardMapView, AdministratorBoundary, DialogHeader },
  data() {
    return {
      loading: false,
      dialog: false,
      showMap: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      valid: false,
      data: {
        name: '',
        group: '',
        geometry: undefined,
      },
    }
  },
  computed: {
    ...mapState('AOI', ['groups', 'currentGroup']),
  },
  watch: {
    dialog(val) {
      this.data = {
        name: '',
        group: '',
        geometry: undefined,
      }
      if (!val) this.showMap = false
      if (Object.keys(this.currentGroup).length > 0) this.data.group = this.currentGroup.name
    },
  },
  methods: {
    async openDialog() {
      this.dialog = true
      await sleep(500)
      this.showMap = true
      this.$nextTick(() => {
        this.$refs.map.reSize()
      })
    },
    async addGeojsonToMap(data) {
      if (this.data.geometry) this.$refs.map.removeLayer('boundary')
      await sleep(200)
      if (!data.data.length) {
        this.data.geometry = undefined
        // this.$emit('resetDraw')
        return
      }
      let features = []
      data.data.forEach(geometry => {
        features.push({
          type: 'Feature',
          properties: {},
          geometry: geometry,
        })
      })

      let featureCollection = {
        type: 'FeatureCollection',
        features: features,
      }
      this.data.geometry = data.data[0]
      this.$refs.map.addPolygonToMap(featureCollection, 'yellow', 'boundary', 'line')
      // this.$emit('drawAOI', featureCollection)
    },
    async saveAOI() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        if (!this.data.geometry) return this.$store.commit('message/SHOW_ERROR', 'Data boundary is required')
        this.loading = true
        let data = { params: this.data, projectId: this.$route.params.id }
        await createAOI(data)
        this.$emit('fetchAOI')
        this.dialog = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
@media (max-height: 735px) {
  .img-top {
    display: none;
  }
}
</style>
