<template>
  <v-menu offset-y v-model="menu">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip , attrs}">
          <v-badge :value="!!currentItem" bottom color="success" dot overlap>
              <v-btn icon x-small v-bind="attrs" v-on="{ ...onMenu, ...onTooltip }">
                <v-icon :size="sizeIcon" :color="currentItem ? 'accent' : ''">{{ icon }}</v-icon>
              </v-btn>
          </v-badge>
        </template>
        <span>{{  tip }}</span>
      </v-tooltip>
    </template>
    <v-list dense min-width="160">
      <div v-for="(group, groupIndex) in groupedSorts" :key="groupIndex">
        <v-list-item
          v-for="(item, index) in group.items"
          :key="index"
          dense
          @click="handleItemChange(item)"
          class="menu-item"
        >
          <v-icon size="18" class="mr-3" v-if="item.icon">{{ item.icon }}</v-icon>
          <v-list-item-title :style="{ color: currentItem === item.code ? 'var(--v-primary-base)' : '' }">
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-icon v-if="currentItem === item.code" class="pl-2 ">
            <v-icon color="success" small>mdi-check-bold</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider v-if="groupIndex < groupedSorts.length - 1" class="my-2"></v-divider>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'SortMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItem: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    sizeIcon: {
      type: Number,
      default: 22,
    },
    tip: {
      type: String,
      default: '',
    },
  },
  
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    groupedSorts() {
      const groups = {}
      this.items.forEach(item => {
        const group = item.group || 'default'
        if (!groups[group]) {
          groups[group] = { items: [] }
        }
        groups[group].items.push(item)
      })
      return Object.values(groups)
    },
  },
  methods: {
    closeMenu(){
      this.menu= false
    },
    openMenu() {
      this.menu = true
    },
    handleItemChange(item) {
      this.$emit('sort-change', item)
    },
  },
}
</script>

<style scoped></style>
