<template>
  <div v-if="saveForm" class="pa-5 elevation-2" style="width: 650px; max-height: 500px; border-radius: 6px; ">
    <v-layout class="fill-height" column>
      <v-layout justify-center>
        <span style="font-size: 16px; text-transform: uppercase; font-weight: bold">AOI info</span>
      </v-layout>
      <v-layout class="fill-height pb-2" column justify-center>
        <v-form ref="form" v-model="valid" class="pt-0" onSubmit="return false" style="width: 100%" @submit="submit">
          <v-layout class="overflow-y-auto" column style="max-height: 390px">
            <span style="font-size: 14px">AOI Name:</span>
            <v-text-field v-model="name" :rules="[rules.required]" dense label="Name" solo solo-inverted></v-text-field>
            <span style="font-size: 14px">Group:</span>
            <v-combobox v-model="group" :items="groups" dense label="Group" solo solo-inverted></v-combobox>
            <v-btn class="mb-4" color="secondary" height="45" width="100%" @click="addNewField">Add Season </v-btn>
            <v-expansion-panels>
              <v-expansion-panel v-for="(item, i) in currentField.information" :key="i">
                <v-expansion-panel-header>
                  <v-layout align-center>
                    {{ item.seasonYear }}
                    <v-spacer />
                    <v-btn
                      class="mr-2"
                      color="red"
                      icon
                      x-small
                      @click.native.stop="currentField.information.splice(i, 1)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-layout>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout align-center class="pb-2">
                    <div class="pr-1" style="flex: 1">
                      <span style="font-size: 14px">Season year:</span>
                      <YearPicker
                        v-model="item.seasonYear"
                        dense
                        hide-details
                        label="Season year"
                        solo-inverted
                        @input="item.sowingDate = item.seasonYear + '-01-01'"
                      />
                    </div>
                    <div class="px-1" style="flex: 1">
                      <span style="font-size: 14px">Crop name:</span>
                      <v-text-field v-model="item.cropName" dense hide-details label="Crop name" solo-inverted>
                      </v-text-field>
                    </div>
                    <div class="pl-1" style="flex: 1">
                      <span style="font-size: 14px">Maturity:</span>
                      <v-text-field
                        v-model="item.maturity"
                        dense
                        hide-details
                        label="Maturity"
                        solo-inverted
                      ></v-text-field>
                    </div>
                  </v-layout>
                  <v-layout align-center class="py-2">
                    <div class="pr-1" style="flex: 1">
                      <span style="font-size: 14px">Sowing date:</span>
                      <DatePicker
                        v-model="item.sowingDate"
                        :min="item.seasonYear + '-01-01'"
                        dense
                        hide-details
                        solo-inverted
                        @input="item.harvestingDate = item.sowingDate + '-01-01'"
                      />
                    </div>
                    <div class="px-1" style="flex: 1">
                      <span style="font-size: 14px">Harvesting date:</span>
                      <DatePicker
                        v-model="item.harvestingDate"
                        :min="item.sowingDate ? item.sowingDate : item.seasonYear + '-01-01'"
                        clearable
                        dense
                        hide-details
                        solo-inverted
                      />
                    </div>
                    <div class="pl-1" style="flex: 1"></div>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
          <v-layout align-end class="mt-3" style="height: 40px">
            <v-btn color="button" width="100" @click="cancelEdit">Cancel</v-btn>
            <v-spacer />
            <v-btn color="secondary" type="submit" width="100">Save</v-btn>
          </v-layout>
        </v-form>
      </v-layout>
      <!--      <div style="width: 100%; height: 50px;">-->
      <!--      </div>-->
    </v-layout>
  </div>
</template>

<script>
import { getGroupAOI } from '@/api/aoi-api'
import sleep from '@/utils/sleep'
import YearPicker from '@/components/YearPicker'
import DatePicker from '@/components/DatePicker'

export default {
  name: 'FieldInfo',
  components: { DatePicker, YearPicker },
  data() {
    return {
      description: undefined,
      valid: false,
      saveForm: false,
      name: undefined,
      group: undefined,
      groups: [],
      currentField: undefined,
      rules: {
        required: value => !!value || 'Name is required',
      },
    }
  },
  methods: {
    addNewField() {
      this.currentField.information.unshift({
        seasonYear: new Date().getFullYear(),
        cropName: undefined,
        maturity: undefined,
        sowingDate: new Date().getFullYear() + '-01-01',
      })
    },
    async openDialog(field) {
      this.currentField = JSON.parse(JSON.stringify(field))
      this.name = this.currentField.name
      await this.getGroups()
      this.group = this.currentField.group
      this.saveForm = true
    },
    async getGroups() {
      try {
        this.loading = true
        const res = await getGroupAOI({ projectId: this.$route.params.id })
        this.groups = res.data.filter(item => item.group !== '').map(item => item.group);
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    cancelEdit() {
      this.saveForm = false
      this.$emit('cancelEdit')
    },
    async submit() {
      await sleep(0)
      this.$refs.form.validate()
      if (!this.valid) return
      this.$emit('saveField', {
        name: this.name,
        group: this.group,
        information:
          this.currentField.information && this.currentField.information.length
            ? this.currentField.information.filter(val => val.cropName)
            : [],
      })
      this.saveForm = false
    },
  },
}
</script>

<style scoped></style>
