<template>
  <v-dialog v-model="uploadDialog" persistent width="500">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Upload AOI'" @close="uploadDialog = false" />
      <v-card-text>
        <div class="d-flex flex-column justify-center">
          <div class="d-flex align-center justify-center" style="height: 200px">
            <div style="width: 250px; height: 220px">
              <v-img :src="require('@/assets/svg/create.svg')" />
            </div>
          </div>
          <div class="" style="width: 100%; height: 175px">
            <DragBox :accept-types="acceptTypes" :files.sync="files" multiple />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!Object.keys(geojson).length"
          :loading="loading"
          color="info"
          rounded
          @click="() => $refs.config.openDialog(geojson)"
        >
          Config
        </v-btn>
        <v-btn color="primary" rounded @click="submitUpload">Send</v-btn>
      </v-card-actions>
    </v-card>
    <AOIConfig
      v-if="uploadDialog"
      ref="config"
      @closeConfig="uploadDialog = false"
      @fetchAOI="() => $emit('fetchAOI')"
    />
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import sleep from '@/utils/sleep'
import { uploadAOI } from '@/api/aoi-api'
import Map from '@/components/Map.vue'
import AOIConfig from '@/views/aoi/AOIConfig.vue'
import DragBox from '@/components/DragBox.vue'

export default {
  name: 'UploadAOI',
  components: { DragBox, AOIConfig, Map, DialogHeader },
  data() {
    return {
      uploadDialog: false,
      loading: false,
      geojson: {},
      config: false,
      rules: {
        fileRequired: value => !!value || 'File is required',
      },
      valid: false,
      files: [],
      acceptTypes: '.zip,.geojson,.kml,.gml,.kmz,.shp,.shx,.prj,.dbf',
    }
  },
  watch: {
    uploadDialog(val) {
      if (val) this.openUploadForm()
      this.file = null
      this.files = []
    },
    files(val) {
      if (!val.length) this.geojson = {}
    },
  },
  methods: {
    async openUploadForm() {
      if (document.getElementById('uploaderAOI')) document.getElementById('uploaderAOI').click()
      else {
        await sleep(100)
        await this.openUploadForm()
      }
    },
    async submitUpload() {
      try {
        this.loading = true
        const formData = new FormData()
        let totalItemOfShapeFile = 0
        let types = []
        for (let i = 0; i < this.files.length; i++) {
          let extension = this.files[i].name.split('.').pop()
          switch (extension.toLowerCase()) {
            case 'zip':
            case 'geojson':
            case 'kml':
            case 'kmz':
            case 'gml':
              formData.append('files', this.files[i])
              formData.append('type', extension.toLowerCase())
              types.push(extension.toLowerCase())
              break
            case 'shp':
            case 'prj':
            case 'shx':
            case 'dbf':
              totalItemOfShapeFile = totalItemOfShapeFile + 1
              formData.append('files', this.files[i])
              if (totalItemOfShapeFile === 1) formData.append('type', 'shp')
              types.push('shp')
              break
          }
        }
        if ([...new Set(types)].length > 1)
          return this.$store.commit('message/SHOW_ERROR', 'The system does not support many file formats')
        const res = await uploadAOI(formData)
        this.geojson = res.data
        this.$refs.config.openDialog(this.geojson)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
